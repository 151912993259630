import { CommunicationMode, OperationMode } from 'amiad-shared-lib';
import * as i0 from "@angular/core";
var ControllerUtilService = /** @class */ (function () {
    function ControllerUtilService() {
    }
    ControllerUtilService.prototype.parseCommunicationMode = function (str) {
        if (!str) {
            return;
        }
        else {
            switch (str.toLowerCase()) {
                case CommunicationMode.Online.toLowerCase():
                    return CommunicationMode.Online;
                case CommunicationMode.Offline.toLowerCase():
                    return CommunicationMode.Offline;
                default:
                    return null;
            }
        }
    };
    ControllerUtilService.prototype.parseOperationMode = function (rtuAddress) {
        if (rtuAddress === 0) {
            return OperationMode.Master;
        }
        else if (rtuAddress > 0) {
            return OperationMode.Slave;
        }
        return null;
    };
    ControllerUtilService.ngInjectableDef = i0.defineInjectable({ factory: function ControllerUtilService_Factory() { return new ControllerUtilService(); }, token: ControllerUtilService, providedIn: "root" });
    return ControllerUtilService;
}());
export { ControllerUtilService };
