import * as i0 from "@angular/core";
var DateUtilService = /** @class */ (function () {
    function DateUtilService() {
    }
    DateUtilService.prototype.parseISOString = function (s) {
        if (!s) {
            return null;
        }
        var b = s.split(/\D+/);
        var year = b[0] ? parseInt(b[0]) : 0;
        var month = b[1] ? parseInt(b[1]) - 1 : 0;
        var day = b[2] ? parseInt(b[2]) : 0;
        var hours = b[3] ? parseInt(b[3]) : 0;
        var minutes = b[4] ? parseInt(b[4]) : 0;
        var seconds = b[5] ? parseInt(b[5]) : 0;
        var ms = b[6] ? parseInt(b[6]) : 0;
        return new Date(year, month, day, hours, minutes, seconds, ms);
    };
    DateUtilService.prototype.getTimeDisplay = function (date) {
        if (this.isDate(date)) {
            var minutes = date.getMinutes();
            var hours = date.getHours();
            return hours + ':' + minutes;
        }
        return '';
    };
    DateUtilService.prototype.getDateDisplay = function (date) {
        if (this.isDate(date)) {
            return (('0' + date.getDate()).slice(-2) + '.'
                + ('0' + (date.getMonth() + 1)).slice(-2) + '.'
                + date.getFullYear().toString().slice(-2));
        }
        return '';
    };
    DateUtilService.prototype.isDate = function (date) {
        return (date) instanceof Date && !isNaN(date.getTime());
    };
    DateUtilService.ngInjectableDef = i0.defineInjectable({ factory: function DateUtilService_Factory() { return new DateUtilService(); }, token: DateUtilService, providedIn: "root" });
    return DateUtilService;
}());
export { DateUtilService };
