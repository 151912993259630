import { Injectable } from "@angular/core";
import { AuthGroup } from "../authentication/authorization.types";
import { AuthService } from "../authentication/auth.service";

@Injectable()
export class AuthorizationService {
  constructor(private authorizationDataService: AuthService) {}
  permissions: Array<AuthGroup>; // Store the actions for which this user has permission
  hasPermission(authGroup: AuthGroup) {
    this.permissions = this.authorizationDataService.getPermissions();
    if (this.permissions && this.permissions.includes(authGroup)) {
      return true;
    }
    return false;
  }
}
