import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ToasterModule } from "angular2-toaster/angular2-toaster";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { FlotDirective } from "./directives/flot/flot.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { EasypiechartDirective } from "./directives/easypiechart/easypiechart.directive";
import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";
import { PageHeadingComponent } from "../layout/page-heading/page-heading.component";
import { DataTablesModule } from "angular-datatables";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MyDisableIfUnauthorizedDirective } from "./directives/disable-if-unauthorized/disable-if-unauthorized.directive";
import { MyHideIfUnauthorizedDirective } from "./directives/hide-if-unauthorized/hide-if-unauthorized.directive";
import { GoogleMapControllersComponent } from "./componenets/google-map-controllers/google-map-controllers.component";
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { environment as env } from "../../environments/environment";
import { AgmOverlays } from "agm-overlays";
import { TableComponent } from "./components/table/table.component";
import { ModalComponent } from "./components/modal/modal.component";
import { TableFiltersComponent } from "./components/table/table-filters/table-filters.component";
import { NgxSelectModule } from "ngx-select-ex";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DateRangePickerModule } from "@uiowa/date-range-picker";

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DataTablesModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxSelectModule,
    ToasterModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: env.googleMapApi.apikey,
    }),
    AgmJsMarkerClustererModule,
    NgMultiSelectDropDownModule,
    DateRangePickerModule,
  ],
  providers: [ColorsService],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    PageHeadingComponent,
    GoogleMapControllersComponent,
    MyDisableIfUnauthorizedDirective,
    MyHideIfUnauthorizedDirective,
    TableComponent,
    ModalComponent,
    TableFiltersComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToasterModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    MyDisableIfUnauthorizedDirective,
    MyHideIfUnauthorizedDirective,
    PageHeadingComponent,
    GoogleMapControllersComponent,
    DataTablesModule,
    NgbModule,
    TableComponent,
    ModalComponent,
    NgMultiSelectDropDownModule,
    NgxSelectModule,
    DateRangePickerModule,
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
