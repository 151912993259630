/* tslint:disable */
type Status5 =
  'runningOrPendingActions' |
  'completed' |
  'failed';
module Status5 {
  export const RUNNING_OR_PENDING_ACTIONS: Status5 = 'runningOrPendingActions';
  export const COMPLETED: Status5 = 'completed';
  export const FAILED: Status5 = 'failed';
  export function values(): Status5[] {
    return [
      RUNNING_OR_PENDING_ACTIONS,
      COMPLETED,
      FAILED
    ];
  }
}

export { Status5 }