var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from "@angular/core";
import { ApiControllerAdapterService } from "./api-controller-adapter.service";
import { ControllerUtilService } from "../../api-services/controllers/controller-util.service";
import { BaseResponse } from "../base-response.model";
var injector = Injector.create({
    providers: [
        {
            provide: ApiControllerAdapterService,
            deps: [ControllerUtilService]
        },
        {
            provide: ControllerUtilService,
            deps: []
        }
    ],
});
var ControllerResponse = /** @class */ (function (_super) {
    __extends(ControllerResponse, _super);
    function ControllerResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            _this.adapter = injector.get(ApiControllerAdapterService);
            _this.controller = _this.adapter.adapt(payload.data);
        }
        return _this;
    }
    ControllerResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.controller ? true : false;
        return isValid;
    };
    return ControllerResponse;
}(BaseResponse));
export { ControllerResponse };
var ControllersResponse = /** @class */ (function (_super) {
    __extends(ControllersResponse, _super);
    function ControllersResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            _this.adapter = injector.get(ApiControllerAdapterService);
            _this.controllers = _this.adapter.adaptMany(payload.data.data);
        }
        return _this;
    }
    ControllersResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.controllers ? true : false;
        return isValid;
    };
    return ControllersResponse;
}(BaseResponse));
export { ControllersResponse };
var IsControllerOwnedResponse = /** @class */ (function (_super) {
    __extends(IsControllerOwnedResponse, _super);
    function IsControllerOwnedResponse(serial, payload) {
        var _this = _super.call(this, payload) || this;
        if (serial) {
            _this.serialNumber = serial;
        }
        if (!_this._errors && payload.data) {
            _this.isOwned = payload.data.isOwned;
            if (payload.data.filterType) {
                _this.filterType = payload.data.filterType;
            }
        }
        return _this;
    }
    IsControllerOwnedResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.serialNumber && (typeof this.isOwned !== 'undefined') ? true : false;
        return isValid;
    };
    return IsControllerOwnedResponse;
}(BaseResponse));
export { IsControllerOwnedResponse };
var AddControllerResponse = /** @class */ (function (_super) {
    __extends(AddControllerResponse, _super);
    function AddControllerResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            _this.controllerId = payload.data.controllerId;
        }
        return _this;
    }
    AddControllerResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.controllerId ? true : false;
        return isValid;
    };
    return AddControllerResponse;
}(BaseResponse));
export { AddControllerResponse };
var UpdateControllerResponse = /** @class */ (function (_super) {
    __extends(UpdateControllerResponse, _super);
    function UpdateControllerResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            _this.controllerId = payload.data.controllerId;
        }
        return _this;
    }
    UpdateControllerResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.controllerId ? true : false;
        return isValid;
    };
    return UpdateControllerResponse;
}(BaseResponse));
export { UpdateControllerResponse };
var ControllerImageResponse = /** @class */ (function (_super) {
    __extends(ControllerImageResponse, _super);
    function ControllerImageResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            _this.imageId = payload.data.imageId;
        }
        return _this;
    }
    ControllerImageResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.imageId ? true : false;
        return isValid;
    };
    return ControllerImageResponse;
}(BaseResponse));
export { ControllerImageResponse };
