/* tslint:disable */
type Result1 =
  'fail';
module Result1 {
  export const FAIL: Result1 = 'fail';
  export function values(): Result1[] {
    return [
      FAIL
    ];
  }
}

export { Result1 }