import { Injectable } from '@angular/core';
import { ListResponse_inner } from '../../../app/api/models';

import { KeyValue } from '@angular/common';
import { Adapter } from '../api-models/controller/iadapter.interface';

@Injectable()
export class StaticContentAdapterService implements Adapter<KeyValue<string,string>> {
  
  constructor() { }
  adapt(item: ListResponse_inner): KeyValue<string,string> {
    if(item){
      return  {
        key: item.key,
        value: item.description
      } as KeyValue<string,string>
    }
    return null;
  }

  adaptMany(items: ListResponse_inner[]): KeyValue<string,string>[] {
    return items.map((item)=> this.adapt(item));
  }
}

