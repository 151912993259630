/* tslint:disable */
type Role1 =
  'user' |
  'admin' |
  'superadmin';
module Role1 {
  export const USER: Role1 = 'user';
  export const ADMIN: Role1 = 'admin';
  export const SUPERADMIN: Role1 = 'superadmin';
  export function values(): Role1[] {
    return [
      USER,
      ADMIN,
      SUPERADMIN
    ];
  }
}

export { Role1 }