import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DateUtilService {

  constructor() { }

  parseISOString(s: string): Date {
    if(!s){
      return null;
    }

    var b : string[] = s.split(/\D+/);
    
    var year: number = b[0] ? parseInt(b[0]) : 0;
    var month : number = b[1] ? parseInt(b[1]) - 1: 0;
    var day : number = b[2] ? parseInt(b[2]): 0;
    var hours : number = b[3] ? parseInt(b[3]): 0;
    var minutes : number = b[4] ? parseInt(b[4]): 0;
    var seconds : number = b[5] ? parseInt(b[5]): 0;
    var ms : number = b[6] ? parseInt(b[6]): 0;
    
    return new Date(year, month, day, hours, minutes, seconds, ms);
  }

  getTimeDisplay(date: Date): string {
    if(this.isDate(date)){
      var minutes = date.getMinutes();
      var hours = date.getHours();
      return hours + ':' + minutes;
    }
    return '';
  }

  getDateDisplay(date: Date): string {
    if(this.isDate(date)){
      return (
        ('0' + date.getDate()).slice(-2) + '.'
        + ('0' + (date.getMonth() + 1)).slice(-2) + '.'
        + date.getFullYear().toString().slice(-2)
      );
    }
    return '';    
  }

  isDate(date: any) {
    return (date) instanceof Date && !isNaN(date.getTime());
  }
}
