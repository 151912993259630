/* tslint:disable */

/**
 * where is the WM install
 */
type WaterMeterRole =
  'CONFIG_WMETER_ROLE_MAIN_LINE' |
  'CONFIG_WMETER_ROLE_FLUSH';
module WaterMeterRole {
  export const CONFIG_WMETER_ROLE_MAIN_LINE: WaterMeterRole = 'CONFIG_WMETER_ROLE_MAIN_LINE';
  export const CONFIG_WMETER_ROLE_FLUSH: WaterMeterRole = 'CONFIG_WMETER_ROLE_FLUSH';
  export function values(): WaterMeterRole[] {
    return [
      CONFIG_WMETER_ROLE_MAIN_LINE,
      CONFIG_WMETER_ROLE_FLUSH
    ];
  }
}

export { WaterMeterRole }