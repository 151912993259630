import { Injectable } from "@angular/core";
import { AuthGroup } from "../../authentication/authorization.types";
import { AuthorizationService } from "../../authentication/authorization.service";

@Injectable()
export class MenuService {
  menuItems: Array<any>;

  constructor(private authorizationService: AuthorizationService) {
    this.menuItems = [];
  }

  addMenu(
    items: Array<{
      text: string;
      heading?: boolean;
      link?: string; // internal route links
      elink?: string; // used only for external links
      target?: string; // anchor target="_blank|_self|_parent|_top|framename"
      icon?: string;
      alert?: string;
      submenu?: Array<any>;
    }>
  ) {
    items.forEach((item) => {
      this.menuItems.push(item);
    });
  }

  getMenu() {
    if (!this.authorizationService.hasPermission(AuthGroup.SuperAdmin)) {
      this.menuItems.forEach((item, i) => {
        if (
          this.menuItems[i].id === "admins" ||
          this.menuItems[i].id === "filterThresholds" ||
          this.menuItems[i].id === "headingBilling" ||
          this.menuItems[i].id === "subscriptions" ||
          this.menuItems[i].id === "activations"
        ) {
          this.menuItems.splice(i, 1);
        }
      });
    }

    if (!this.authorizationService.hasPermission(AuthGroup.SystemAdmin)) {
      this.menuItems.forEach((item, i) => {
        if (
          this.menuItems[i].id === "tenants" ||
          this.menuItems[i].id === "firmwareVersions" ||
          this.menuItems[i].id === "products" ||
          this.menuItems[i].id === "transactions"
        ) {
          this.menuItems.splice(i, 1);
        }
      });
    }
    return this.menuItems;
  }
}
