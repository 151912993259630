/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.shared.component.scss.shim.ngstyle";
import * as i1 from "./header.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/forms";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "./navsearch/navsearch.component.ngfactory";
import * as i7 from "./navsearch/navsearch.component";
import * as i8 from "./header.component";
import * as i9 from "../../core/menu/menu.service";
import * as i10 from "../sidebar/userblock/userblock.service";
import * as i11 from "../../shared/api-services/users/users.service";
import * as i12 from "../../core/settings/settings.service";
import * as i13 from "../../authentication/auth.service";
import * as i14 from "../../core/translator/translator.service";
var styles_HeaderComponent = [i0.styles, i1.styles];
var RenderType_HeaderComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "a", [["class", "nav-link d-none d-md-block d-lg-block d-xl-block lang"], ["id", "Collaps"], ["trigger-resize", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapsedSideabar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "em", [["class", "fas fa-bars"]], null, null, null, null, null))], null, null); }
function View_HeaderComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 6, "div", [["class", "d-flex align-items-center justify-content-center h-100 text-white"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Current Tenant: "])), (_l()(), i2.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 1, "span", [["class", "mx-2"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" | "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTenant.name; _ck(_v, 5, 0, currVal_0); }); }
function View_HeaderComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i2.ɵdid(1, 147456, null, 0, i3.NgSelectOption, [i2.ElementRef, i2.Renderer2, [2, i3.SelectControlValueAccessor]], { value: [0, "value"] }, null), i2.ɵdid(2, 147456, null, 0, i3.ɵangular_packages_forms_forms_s, [i2.ElementRef, i2.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i2.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.code; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.code; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.text; _ck(_v, 3, 0, currVal_2); }); }
export function View_HeaderComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { fsbutton: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 30, "nav", [["class", "navbar topnavbar"], ["role", "navigation"]], null, null, null, null, null)), i2.ɵdid(2, 16384, null, 0, i4.ɵm, [], null, null), (_l()(), i2.ɵeld(3, 0, null, null, 5, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "a", [["class", "navbar-brand"], ["href", "/dashboard"], ["id", "navbar-brand"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "brand-logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "img", [["alt", "App Logo"], ["class", "img-fluid"], ["src", "assets/img/components/topNavbar/headerLogoTopNav.svg"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 1, "div", [["class", "brand-logo-collapsed"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 0, "img", [["alt", "App Logo"], ["class", "img-fluid"], ["src", "assets/img/components/topNavbar/headerLogoTopNavSmall.svg"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 5, "ul", [["class", "navbar-nav mr-auto-custome flex-row"], ["id", "custom-navbar-nav"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 4, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i2.ɵdid(12, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(13, 0, null, null, 1, "a", [["class", "nav-link sidebar-toggle d-md-none lang"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.settings.toggleLayoutSetting("asideToggled");
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 0, "em", [["class", "fas fa-bars"]], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 14, "ul", [["class", "navbar-nav flex-row"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i2.ɵdid(17, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(18, 0, null, null, 8, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(19, 0, null, null, 7, "select", [["class", "form-control lang-dropdown lang"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i2.ɵnov(_v, 20).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = (_co.setLang($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i2.ɵdid(20, 16384, null, 0, i3.SelectControlValueAccessor, [i2.Renderer2, i2.ElementRef], null, null), i2.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectControlValueAccessor]), i2.ɵdid(22, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i2.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i2.ɵdid(24, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i2.ɵdid(26, 278528, null, 0, i5.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵeld(27, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(28, 0, null, null, 1, "a", [["class", "nav-link lang"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(29, 0, null, null, 0, "em", [["class", "icon-logout"]], null, null, null, null, null)), (_l()(), i2.ɵeld(30, 0, null, null, 1, "app-navsearch", [], null, [[null, "onclose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onclose" === en)) {
        var pd_0 = (_co.setNavSearchVisible(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NavsearchComponent_0, i6.RenderType_NavsearchComponent)), i2.ɵdid(31, 638976, null, 0, i7.NavsearchComponent, [i2.ElementRef], { visible: [0, "visible"] }, { onclose: "onclose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isCollapsedText(); _ck(_v, 12, 0, currVal_0); var currVal_1 = (_co.isSystemAdmin && _co.selectedTenant); _ck(_v, 17, 0, currVal_1); var currVal_9 = _co.selectedLanguage; _ck(_v, 22, 0, currVal_9); var currVal_10 = _co.getLangs(); _ck(_v, 26, 0, currVal_10); var currVal_11 = _co.getNavSearchVisible(); _ck(_v, 31, 0, currVal_11); }, function (_ck, _v) { var currVal_2 = i2.ɵnov(_v, 24).ngClassUntouched; var currVal_3 = i2.ɵnov(_v, 24).ngClassTouched; var currVal_4 = i2.ɵnov(_v, 24).ngClassPristine; var currVal_5 = i2.ɵnov(_v, 24).ngClassDirty; var currVal_6 = i2.ɵnov(_v, 24).ngClassValid; var currVal_7 = i2.ɵnov(_v, 24).ngClassInvalid; var currVal_8 = i2.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_HeaderComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i2.ɵdid(1, 114688, null, 0, i8.HeaderComponent, [i9.MenuService, i10.UserblockService, i11.UsersAdminService, i12.SettingsService, i13.AuthService, i14.TranslatorService, i14.TranslatorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i2.ɵccf("app-header", i8.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
