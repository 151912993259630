import { Injectable } from '@angular/core';
import { ControllerAlert } from 'amiad-shared-lib';
import { DateUtilService } from '../../../core/utils/date-util.service';
import { ControllerActiveAlarms } from '../../../../app/api/models';
import { Adapter } from '../controller/iadapter.interface';



@Injectable()
export class ControllerActiveAlertsAdapterService implements Adapter<ControllerAlert> {
    private dateUtil : DateUtilService = new DateUtilService();

    adapt(item: ControllerActiveAlarms): ControllerAlert {
        if (item && item.alarm) {
            var ctrlrAlert: ControllerAlert = {
                controllerId: item.controllerId,
                alertKey: item.alarm.type,
                date: this.dateUtil.parseISOString(item.alarm.startTime),
            };
            return ctrlrAlert;
        }
        return null;
    }

    adaptMany(items: ControllerActiveAlarms[]): ControllerAlert[] {
        return items.map((item) => this.adapt(item));
    }
}

