import { Injectable } from '@angular/core';
import { Adapter } from '../controller/iadapter.interface';
import { Alert } from './alert.interface';








@Injectable()
export class AlertAdapterService implements Adapter<Alert> {
    constructor() { }

    adapt(dto): Alert {
        // TODO: Run this through static content parser instead of this
        let title = dto.alarm.type.substring(9, dto.alarm.type.length)
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase();
            });
        //
        return <Alert>{
            controllerId: dto.controllerId,
            title: title,
            siteName: dto.alarm.siteName,
            timeReceived: new Date() // TODO: replace when not receiving null from server API
        };

    }

    adaptMany(dtoArr: any[]): Alert[] {
        if (dtoArr) {
            return dtoArr.map(x => this.adapt(x));
        }
        return null;
    }
}

