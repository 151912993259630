import { SystemUnit } from "ng-trio-infra";

export class UserClaims {
  id: string;
  tenantId: string;
  email: string;
  fullName: string;
  country: string;
  role: string;
  company: string;
  jobDescription: string;
  systemUnits: SystemUnit;
  languageCode: string;

  // Construct user claims from aadUser
  constructor(userClaims: any) {
    this.id = userClaims.hasOwnProperty("oid") ? userClaims["oid"] : null;
    this.email = userClaims.hasOwnProperty("signInNames.emailAddress")
      ? userClaims["signInNames.emailAddress"]
      : null;
    this.role = userClaims.hasOwnProperty("extension_userRole")
      ? userClaims["extension_userRole"]
      : "";
    this.company = userClaims.hasOwnProperty("extension_company")
      ? userClaims["extension_company"]
      : null;
    this.country = userClaims.hasOwnProperty("extension_country")
      ? userClaims["extension_country"]
      : null;
    this.fullName = userClaims.hasOwnProperty("extension_fullName")
      ? userClaims["extension_fullName"]
      : null;
    this.jobDescription = userClaims.hasOwnProperty("extension_jobDescription")
      ? userClaims["extension_jobDescription"]
      : null;
    this.systemUnits =
      userClaims.hasOwnProperty("extension_units") &&
      userClaims["extension_units"]
        ? SystemUnit.IMPERIAL
        : SystemUnit.METRIC;
    this.tenantId = userClaims.hasOwnProperty("extension_adixTenantId")
      ? userClaims["extension_adixTenantId"]
      : null;
  }
}
