import {
  Component,

  OnInit,

  ViewChild
} from "@angular/core";
import { Subscription } from "rxjs";
import { environment as env } from "../../../environments/environment";
import { Role, UserUpdateInfo } from "../../api/models";
import { AuthService } from "../../authentication/auth.service";
import { MenuService } from "../../core/menu/menu.service";
import { SettingsService } from "../../core/settings/settings.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { Tenant } from "../../shared/api-models/tenants/tenant.interface";
import { UsersAdminService } from "../../shared/api-services/users/users.service";
import { UserblockService } from "../sidebar/userblock/userblock.service";
const screenfull = require("screenfull");

const Swal = require("sweetalert2");
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.shared.component.scss", "./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  // premisson = AuthGroup.SuperAdmin;
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  selectedLanguage: string;
  isNavSearchVisible: boolean;

  Logout: string;
  Yes: string;
  Cancel: string;
  subscription: Subscription;
  elm: any;
  cureLang = localStorage.getItem("lang");
  curUserEmail: string = "";
  @ViewChild("fsbutton") fsbutton; // the fullscreen button
  selectedTheme: string = this.translateService.getCurentLang();

  isSystemAdmin: boolean = false;
  selectedTenant: Tenant = {
    id: env.tenant.id,
    name: env.tenant.name,
  };
  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    private usersService: UsersAdminService,
    public settings: SettingsService,
    private authService: AuthService,
    public translator: TranslatorService,
    private translateService: TranslatorService
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.selectedLanguage = this.getCureLang();
  }
  ngOnInit() {
    if (this.cureLang === "en") {
      this.Logout = "Logout";
      this.Yes = "Yes, Logout!";
      this.Cancel = "Cancel";
    } else {
      this.Logout = "לצאת";
      this.Yes = "כן, לצאת!";
      this.Cancel = "לא";
    }

    this.usersService.currentUser.subscribe((user) => {
      if (user) {
        this.curUserEmail = user.email;
        this.isSystemAdmin = user.role === Role.SYSTEMADMIN;
      }
    });

    this.usersService.getCurrentTenant$.subscribe((tenantId) => {
      if (tenantId) {
        this.selectedTenant = tenantId;
      }
    });

    this.subscription = this.translateService.langChanged.subscribe(
      (lang: string) => {
        this.selectedTheme = lang;
        if (this.selectedTheme === "ltr") {
          this.Logout = "Logout";
          this.Yes = "Yes, Logout!";
          this.Cancel = "Cancel";
        } else {
          this.Logout = "לצאת";
          this.Yes = "כן, לצאת!";
          this.Cancel = "לא";
        }
      }
    );

    this.isNavSearchVisible = false;
    const ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = "none";
    }

    // Switch fullscreen icon indicator
    if (this.fsbutton !== undefined) {
      this.elm = this.fsbutton.nativeElement.firstElementChild;
    }
    const el = this.elm;
    screenfull.on("change", () => {
      if (el) {
        el.className = screenfull.isFullscreen
          ? "fa fa-compress"
          : "fa fa-expand";
      }
    });
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting("offsidebarOpen");
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting("isCollapsed");
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  getCureLang() {
    return this.cureLang;
  }

  setLang(value) {
    this.translator.useLanguage(value);
    var userInfo: UserUpdateInfo = {
      preferredLanguage: value,
      email: this.curUserEmail,
    };
    this.usersService.UpdateUser(userInfo).subscribe();
  }

  logout() {
    Swal.fire({
      title: this.Logout + "?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#EF4444",
      cancelButtonColor: "#8C8C8C",
      cancelButtonText: this.Cancel,
      confirmButtonText: this.Yes,
      customClass: "direction-" + this.translator.getCurentLang(),
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
      }
    });
  }
}
