var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "ng-trio-infra";
import { BehaviorSubject, Subject } from "rxjs";
import { map, take } from "rxjs/operators";
import { UtilService } from "../../../../app/core/utils/util.service";
import { environment as env } from "../../../../environments/environment";
import { UsersService } from "../../../api/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/users.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../core/utils/util.service";
import * as i4 from "ng-trio-infra";
var Swal = require("sweetalert2");
var MockMode = true;
var UsersAdminService = /** @class */ (function () {
    function UsersAdminService(usersService, httpClient, utilService, logger) {
        this.usersService = usersService;
        this.httpClient = httpClient;
        this.utilService = utilService;
        this.logger = logger;
        this.currentUser = new Subject();
        this.currentUserRole = "";
        this.usersChanged = new Subject();
        this.params = {};
        this.adminParams = {};
        this.currentTenant$ = new BehaviorSubject({
            id: env.tenant.id,
            name: env.tenant.name,
        });
        this.getCurrentTenant$ = this.currentTenant$.asObservable();
        this.getCurrentUser().subscribe();
    }
    UsersAdminService.prototype.AddUser = function (userInfo) {
        var _this = this;
        if (!MockMode) {
            return this.httpClient.post("https://" + env.api.baseURL + "/api/users/admin", userInfo, {
                observe: "body",
                responseType: "json",
            });
        }
        else {
            userInfo.isAdded = true;
            var userInfoCopy_1 = __assign({}, userInfo);
            delete userInfo.countries;
            delete userInfo.isAdded;
            return this.usersService.UsersAdminPOST(userInfo).pipe(take(1), map(function (payload) {
                _this.usersChanged.next(userInfoCopy_1);
                var resp = payload;
                return resp;
            }));
        }
    };
    UsersAdminService.prototype.UpdateUser = function (userInfo) {
        var _this = this;
        if (!MockMode) {
        }
        else {
            userInfo.isAdded = false;
            this.adminParams.userEmail = userInfo.email;
            var userInfoCopy_2 = __assign({}, userInfo);
            delete userInfo.email;
            delete userInfo.isAdded;
            delete userInfo.countries;
            this.adminParams.registrationInfo = userInfo;
            return this.usersService.UsersAdminsByUserEmailPUT(this.adminParams).pipe(take(1), map(function (payload) {
                _this.usersChanged.next(userInfoCopy_2);
                var resp = payload;
                return resp;
            }));
        }
    };
    UsersAdminService.prototype.DeleteUser = function (userInfo) {
        var _this = this;
        userInfo.isDeleted = true;
        return this.usersService.UsersByUserEmailDELETE(userInfo.email).pipe(take(1), map(function (payload) {
            _this.usersChanged.next(userInfo);
            var resp = payload;
            return resp;
        }));
    };
    UsersAdminService.prototype.getCurrentUser = function () {
        var _this = this;
        return this.usersService.UsersMeGET().pipe(take(1), map(function (payload) {
            if (payload) {
                var user = payload.data;
                if (user) {
                    _this.currentUserRole = user.role;
                    _this.currentUser.next(user);
                }
                return user;
            }
            return null;
        }));
    };
    UsersAdminService.prototype.getUsers = function (limit, offset, search, jobDescription, country, sortBy) {
        if (!MockMode) {
            // return ClientMockUtil.getAdminClients();
        }
        else {
            if (limit !== 99 || offset !== 99) {
                var pageSize = $("select[name=usersTable_length]").val();
                if (pageSize != limit) {
                    this.params.limit = pageSize.toString();
                }
                else {
                    this.params.limit = limit.toString();
                }
                this.params.offset = offset.toString();
            }
            this.params.search = search;
            this.params.company = jobDescription;
            this.params.country = country;
            this.params.orderBy =
                sortBy === "Job Description" ? "jobDescription" : sortBy.toLowerCase();
            this.params.role = ["admin", "superadmin", "systemadmin"];
            return this.usersService.UsersGET(this.params).map(function (apiPagedUsers) {
                return {
                    offset: apiPagedUsers.data.pagination.offset,
                    count: apiPagedUsers.data.pagination.count,
                    totalCount: apiPagedUsers.data.pagination.totalCount,
                    users: apiPagedUsers.data.data.map(function (apiUser) {
                        return {
                            name: apiUser.fullName,
                            email: apiUser.email,
                            company: apiUser.company,
                            jobDescription: apiUser.jobDescription,
                            countries: apiUser.adminInfo !== null
                                ? apiUser.adminInfo.managedCountries
                                : "No Data",
                            managedCountries: [],
                            role: apiUser.role,
                            preferredLanguage: apiUser.preferredLanguage,
                            country: apiUser.country,
                            units: apiUser.units,
                            shouldReceiveUpdates: apiUser.shouldReceiveUpdates,
                        };
                    }),
                };
            });
        }
    };
    UsersAdminService.prototype.setCurrentTenant = function (tenant) {
        if (!tenant) {
            return;
        }
        this.currentTenant$.next(tenant);
    };
    UsersAdminService.ngInjectableDef = i0.defineInjectable({ factory: function UsersAdminService_Factory() { return new UsersAdminService(i0.inject(i1.UsersService), i0.inject(i2.HttpClient), i0.inject(i3.UtilService), i0.inject(i4.LoggerService)); }, token: UsersAdminService, providedIn: "root" });
    return UsersAdminService;
}());
export { UsersAdminService };
