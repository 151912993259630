import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TenantName } from "../../../app/shared/api-models/tenants/tenant.interface";
import { environment as env } from "../../../environments/environment";

@Injectable()
export class BillingGuardService implements CanActivate {

    constructor(protected router: Router) { }

    canActivate(): boolean {
      const isAllowedToView = this.hasRequiredPermission(env.tenant.name)
      if(isAllowedToView) return true
      this.router.navigate(['/dashboard']);
      return false
    }

    protected hasRequiredPermission(tenant: TenantName): boolean {
        return tenant !== 'Netafim'
    }
}
