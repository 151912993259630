import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { from, Observable } from "rxjs";
import { AuthGroup } from "./authorization.types";
import { LoggerService } from "ng-trio-infra";
import { environment as env } from "../../environments/environment";
import { Role } from "../api/models";
import { UserClaims } from "./user-claims";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // token: string;
  static readonly TAG: string = "[AuthService]";
  accessToken: string;
  constructor(
    private logService: LoggerService,
    private router: Router,
    private msalService: MsalService
  ) {}

  login(/*loginContext: User*/) {
    this.logService.debug(AuthService.TAG, "Creating auth request..");
    this.msalService.loginRedirect(env.aadb2c.scopes);
  }

  getPermissions() {
    const user = this.extractUserClaims();
    const userRole = user.role;
    switch (userRole) {
      case Role.PRODUCTION:
        return [AuthGroup.Production];
      case Role.ADMIN:
        return [AuthGroup.Production, AuthGroup.Admin];
      case Role.SUPERADMIN:
        return [AuthGroup.Production, AuthGroup.Admin, AuthGroup.SuperAdmin];
      case Role.SYSTEMADMIN:
        return [AuthGroup.Production, AuthGroup.Admin, AuthGroup.SuperAdmin, AuthGroup.SystemAdmin];
      default:
        return [];
    }
  }

  logout() {
    this.logService.debug(AuthService.TAG, "Logging out user!");
    this.msalService.logout();
    return false;
  }

  forgotPassword() {
    this.msalService.authority =
      env.aadb2c.baseURL +
      "/" +
      env.aadb2c.tenantName +
      "/" +
      env.aadb2c.resetPasswordPolicy;
    this.logService.debug(
      AuthService.TAG,
      "Starting forgot password flow.",
      this.msalService.authority
    );
    this.msalService.loginRedirect();
  }

  changePassword() {
    this.logService.debug(AuthService.TAG, "Change Password!");
    this.msalService.authority =
      env.aadb2c.baseURL +
      "/" +
      env.aadb2c.tenantName +
      "/" +
      env.aadb2c.changePasswordPolicy;
    this.msalService.loginRedirect(env.aadb2c.scopes);
  }

  getToken(): Observable<string> {
    return from(
      this.msalService
        .acquireTokenSilent([
          "https://" + env.aadb2c.tenantName + "/api/user_impersonation",
        ])
        // return this.msalService.acquireTokenSilent([this.tenantConfig.clientID])
        .then((token) => {
          this.logService.info(
            AuthService.TAG,
            "Token aquired silently: ",
            token
          );
          return token;
        })
        .catch((error) => {
          this.logService.debug(
            AuthService.TAG,
            "Error getting token: " + error
          );
        })
    );
  }

  // extractUserFromIdToken(): UserIdToken {
  //   const aadUser = this.msalService.getUser();
  //   return new UserIdToken(aadUser);
  // }

  extractUserClaims(): UserClaims {
    var aadUser = this.msalService.getUser();
    return aadUser != null && aadUser.hasOwnProperty("idToken")
      ? new UserClaims(aadUser.idToken)
      : null;
  }
}
