import { StaticContentService } from "../../../app/api/services";
import { take, tap, catchError } from "rxjs/operators";
import { LoggerService } from "ng-trio-infra";
import { Observable, of, forkJoin } from "rxjs";
import { StaticContentResponse } from "./static-content.reponse.model";
import { environment as env } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "ng-trio-infra";
import * as i2 from "../../api/services/static-content.service";
var StaticContentProviderService = /** @class */ (function () {
    function StaticContentProviderService(
    // private userService: UserService,
    logger, apiContentService) {
        this.logger = logger;
        this.apiContentService = apiContentService;
        this.content = {
            alarmTypes: null,
            countries: null,
            filterTypes: null,
            filtrationDegrees: null,
            jobDescriptions: null,
            languages: null,
            waterSources: null,
            flushReasons: null,
        };
    }
    Object.defineProperty(StaticContentProviderService.prototype, "isLoaded", {
        get: function () {
            return this.content.alarmTypes &&
                this.content.countries &&
                this.content.filterTypes &&
                //  this.content.filtrationDegrees &&
                this.content.flushReasons &&
                this.content.jobDescriptions &&
                this.content.languages &&
                this.content.waterSources
                ? true
                : false;
        },
        enumerable: true,
        configurable: true
    });
    StaticContentProviderService.prototype.initialLoad = function () {
        var _this = this;
        this.logger.info("StaticContentProviderService", "Loading static content..");
        var loadFilters$ = this.loadFilterTypes();
        var loadCountries$ = this.loadCountries();
        var loadJobs$ = this.loadJobDescriptions();
        var loadLanguages$ = this.loadLanguages();
        var loadAlarms$ = this.loadAlarmTypes();
        //    var loadFiltrationDegrees$ = this.loadFiltrationDegrees();
        var loadWaterSources$ = this.loadWaterSources();
        var loadFlushReasons$ = this.loadFlushReasons();
        return forkJoin(loadFilters$, loadCountries$, loadJobs$, loadLanguages$, loadAlarms$, 
        // loadFiltrationDegrees$,
        loadWaterSources$, loadFlushReasons$).pipe(catchError(function (err) {
            _this.logger.error("StaticContentProviderService", "Error", err);
            return of([]);
        }));
    };
    StaticContentProviderService.prototype.pipeContentObservable = function (type, obs) {
        var _this = this;
        return obs.pipe(take(1), tap(function (payload) {
            var resp = new StaticContentResponse(payload);
            if (resp.isValid()) {
                switch (type) {
                    case "filtertypes":
                        _this.content.filterTypes = resp.content;
                        break;
                    case "countries":
                        _this.content.countries = resp.content;
                        break;
                    case "jobs":
                        _this.content.jobDescriptions = resp.content;
                        break;
                    case "languages":
                        _this.content.languages = resp.content;
                        break;
                    case "flushreasons":
                        _this.content.flushReasons = resp.content;
                        break;
                    case "alarmtypes":
                        _this.content.alarmTypes = resp.content;
                        break;
                    // case 'filterationdegrees':
                    //     this.content.filtrationDegrees = resp.content;
                    //     break;
                    case "watersources":
                        _this.content.waterSources = resp.content;
                        break;
                    default:
                        break;
                }
            }
            else {
                _this.logger.error("StaticContentProviderService", "Error loading " + type, resp.errors);
            }
        }));
    };
    StaticContentProviderService.prototype.loadFilterTypes = function () {
        var contentType = "filtertypes";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    StaticContentProviderService.prototype.loadCountries = function () {
        var contentType = "countries";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    StaticContentProviderService.prototype.loadJobDescriptions = function () {
        var contentType = "jobs";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    StaticContentProviderService.prototype.loadLanguages = function () {
        var contentType = "languages";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    StaticContentProviderService.prototype.loadAlarmTypes = function () {
        var contentType = "alarmtypes";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    // loadFiltrationDegrees() {
    //     var contentType: ContentType = 'filterationdegrees';
    //     var getContent$ = this.apiContentService.StaticcontentByContentKeyGET(contentType);
    //     return this.pipeContentObservable(contentType, getContent$);
    // }
    StaticContentProviderService.prototype.loadWaterSources = function () {
        var contentType = "watersources";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    StaticContentProviderService.prototype.loadFlushReasons = function () {
        var contentType = "flushreasons";
        var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
            contentKey: contentType,
            tenantId: env.tenant.id,
        });
        return this.pipeContentObservable(contentType, getContent$);
    };
    StaticContentProviderService.prototype.isValidContentKey = function (type, key) {
        var contentToCheck = [];
        switch (type) {
            case "filtertypes":
                contentToCheck = this.content.filterTypes;
                break;
            case "countries":
                contentToCheck = this.content.countries;
                break;
            case "jobs":
                contentToCheck = this.content.jobDescriptions;
                break;
            case "languages":
                contentToCheck = this.content.languages;
                break;
            case "flushreasons":
                contentToCheck = this.content.flushReasons;
                break;
            case "alarmtypes":
                contentToCheck = this.content.alarmTypes;
                break;
            // case 'filterationdegrees':
            //     contentToCheck = this.content.filtrationDegrees;
            //     break;
            case "watersources":
                contentToCheck = this.content.waterSources;
                break;
            default:
                break;
        }
        if (!contentToCheck) {
            return false;
        }
        for (var i = 0; i < contentToCheck.length; i++) {
            var contentKey = contentToCheck[i].key;
            if (contentKey === key) {
                return true;
            }
        }
        return false;
    };
    StaticContentProviderService.prototype.getFilterTypes = function () {
        return this.content.filterTypes;
    };
    StaticContentProviderService.prototype.getCountries = function () {
        return this.content.countries;
    };
    StaticContentProviderService.prototype.getJobDescriptions = function () {
        return this.content.jobDescriptions;
    };
    StaticContentProviderService.prototype.getLanguages = function () {
        return this.content.languages;
    };
    StaticContentProviderService.prototype.getAlarmTypes = function () {
        return this.content.alarmTypes;
    };
    // getFiltrationDegrees(): KeyValue<string, string>[] {
    //     return this.content.filtrationDegrees;
    // }
    StaticContentProviderService.prototype.getWaterSources = function () {
        return this.content.waterSources;
    };
    StaticContentProviderService.prototype.getFlushReasons = function () {
        return this.content.flushReasons;
    };
    StaticContentProviderService.ngInjectableDef = i0.defineInjectable({ factory: function StaticContentProviderService_Factory() { return new StaticContentProviderService(i0.inject(i1.LoggerService), i0.inject(i2.StaticContentService)); }, token: StaticContentProviderService, providedIn: "root" });
    return StaticContentProviderService;
}());
export { StaticContentProviderService };
