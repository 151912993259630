/* tslint:disable */

/**
 * Flush sequence - Standard/Reverse order/Rotate*
 */
type CONFIGIDFLUSHSEQUENCE =
  'CONFIG_FLUSH_SEQUENCE_STANDARD' |
  'CONFIG_FLUSH_SEQUENCE_REVERSE' |
  'CONFIG_FLUSH_SEQUENCE_ROTATE';
module CONFIGIDFLUSHSEQUENCE {
  export const CONFIG_FLUSH_SEQUENCE_STANDARD: CONFIGIDFLUSHSEQUENCE = 'CONFIG_FLUSH_SEQUENCE_STANDARD';
  export const CONFIG_FLUSH_SEQUENCE_REVERSE: CONFIGIDFLUSHSEQUENCE = 'CONFIG_FLUSH_SEQUENCE_REVERSE';
  export const CONFIG_FLUSH_SEQUENCE_ROTATE: CONFIGIDFLUSHSEQUENCE = 'CONFIG_FLUSH_SEQUENCE_ROTATE';
  export function values(): CONFIGIDFLUSHSEQUENCE[] {
    return [
      CONFIG_FLUSH_SEQUENCE_STANDARD,
      CONFIG_FLUSH_SEQUENCE_REVERSE,
      CONFIG_FLUSH_SEQUENCE_ROTATE
    ];
  }
}

export { CONFIGIDFLUSHSEQUENCE }