/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { StrictHttpResponse as __StrictHttpResponse } from "../strict-http-response";
import { Observable as __Observable } from "rxjs";
import { map as __map, filter as __filter } from "rxjs/operators";

import { Inline_response_200_3 } from "../models/inline-_response-_200_3";
import { Inline_response_200_4 } from "../models/inline-_response-_200_4";
import { Inline_response_200_5 } from "../models/inline-_response-_200_5";
import { Inline_response_200_6 } from "../models/inline-_response-_200_6";
import { Inline_response_200_7 } from "../models/inline-_response-_200_7";
import { Inline_response_200_8 } from "../models/inline-_response-_200_8";
import { Inline_response_200_10 } from "../models/inline-_response-_200_10";
import { Inline_response_200_11 } from "../models/inline-_response-_200_11";
import { Inline_response_200_12 } from "../models/inline-_response-_200_12";
import { Inline_response_200_13 } from "../models/inline-_response-_200_13";
@Injectable({
  providedIn: "root",
})
class StatusService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * get full status+info of a specific controller
   * @param params The `StatusService.ControllersStatusByControllerIdGETParams` containing the following parameters:
   *
   * - `controller_id`: id of controller
   *
   * - `includeInfo`: if true, status response will contain controllers info
   *
   * @return success codes
   */
  ControllersStatusByControllerIdGETResponse(
    params: StatusService.ControllersStatusByControllerIdGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_3>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    if (params.includeInfo != null)
      __params = __params.set("includeInfo", params.includeInfo.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_3>;
      })
    );
  }
  /**
   * get full status+info of a specific controller
   * @param params The `StatusService.ControllersStatusByControllerIdGETParams` containing the following parameters:
   *
   * - `controller_id`: id of controller
   *
   * - `includeInfo`: if true, status response will contain controllers info
   *
   * @return success codes
   */
  ControllersStatusByControllerIdGET(
    params: StatusService.ControllersStatusByControllerIdGETParams
  ): __Observable<Inline_response_200_3> {
    return this.ControllersStatusByControllerIdGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_3)
    );
  }

  /**
   * get full status for user/admin controllers
   * @param params The `StatusService.ControllersStatusGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `includeInfo`: if true, status response will contain controllers info
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return success codes
   */
  ControllersStatusGETResponse(
    params: StatusService.ControllersStatusGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_4>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSource || []).forEach((val) => {
      if (val != null)
        __params = __params.append("waterSource", val.toString());
    });
    if (params.thresholdExceeded != null)
      __params = __params.set(
        "thresholdExceeded",
        params.thresholdExceeded.toString()
      );
    (params.slaveNumber || []).forEach((val) => {
      if (val != null)
        __params = __params.append("slaveNumber", val.toString());
    });
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.orderBy != null)
      __params = __params.set("orderBy", params.orderBy.toString());
    (params.operationMode || []).forEach((val) => {
      if (val != null)
        __params = __params.append("operationMode", val.toString());
    });
    if (params.onlineMode != null)
      __params = __params.set("onlineMode", params.onlineMode.toString());
    if (params.offset != null)
      __params = __params.set("offset", params.offset.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.isComActive != null)
      __params = __params.set("isComActive", params.isComActive.toString());
    if (params.isAlarmed != null)
      __params = __params.set("isAlarmed", params.isAlarmed.toString());
    if (params.includeInfo != null)
      __params = __params.set("includeInfo", params.includeInfo.toString());

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    (params.filterType || []).forEach((val) => {
      if (val != null) __params = __params.append("filterType", val.toString());
    });
    if (params.creationDateTo != null)
      __params = __params.set(
        "creationDateTo",
        params.creationDateTo.toString()
      );
    if (params.creationDateFrom != null)
      __params = __params.set(
        "creationDateFrom",
        params.creationDateFrom.toString()
      );
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.controllerType || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_4>;
      })
    );
  }
  /**
   * get full status for user/admin controllers
   * @param params The `StatusService.ControllersStatusGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `includeInfo`: if true, status response will contain controllers info
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return success codes
   */
  ControllersStatusGET(
    params: StatusService.ControllersStatusGETParams
  ): __Observable<Inline_response_200_4> {
    return this.ControllersStatusGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_4)
    );
  }

  /**
   * Get controller info.
   * @param controller_id id of controller
   * @return response
   */
  ControllersInfoByControllerIdGETResponse(
    params: StatusService.ControllersInfoByControllerIdGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_5>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_5>;
      })
    );
  }
  /**
   * Get controller info.
   * @param controller_id id of controller
   * @return response
   */
  ControllersInfoByControllerIdGET(
    params: StatusService.ControllersInfoByControllerIdGETParams
  ): __Observable<Inline_response_200_5> {
    return this.ControllersInfoByControllerIdGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_5)
    );
  }

  /**
   * Get info for user/admin controllers
   * @param params The `StatusService.ControllersInfoGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return response
   */
  ControllersInfoGETResponse(
    params: StatusService.ControllersInfoGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_6>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSource || []).forEach((val) => {
      if (val != null)
        __params = __params.append("waterSource", val.toString());
    });
    if (params.thresholdExceeded != null)
      __params = __params.set(
        "thresholdExceeded",
        params.thresholdExceeded.toString()
      );
    (params.slaveNumber || []).forEach((val) => {
      if (val != null)
        __params = __params.append("slaveNumber", val.toString());
    });
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.orderBy != null)
      __params = __params.set("orderBy", params.orderBy.toString());
    (params.operationMode || []).forEach((val) => {
      if (val != null)
        __params = __params.append("operationMode", val.toString());
    });

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    if (params.onlineMode != null)
      __params = __params.set("onlineMode", params.onlineMode.toString());
    if (params.offset != null)
      __params = __params.set("offset", params.offset.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.isComActive != null)
      __params = __params.set("isComActive", params.isComActive.toString());
    if (params.isAlarmed != null)
      __params = __params.set("isAlarmed", params.isAlarmed.toString());
    (params.filterType || []).forEach((val) => {
      if (val != null) __params = __params.append("filterType", val.toString());
    });
    if (params.creationDateTo != null)
      __params = __params.set(
        "creationDateTo",
        params.creationDateTo.toString()
      );
    if (params.creationDateFrom != null)
      __params = __params.set(
        "creationDateFrom",
        params.creationDateFrom.toString()
      );
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.controllerType || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_6>;
      })
    );
  }
  /**
   * Get info for user/admin controllers
   * @param params The `StatusService.ControllersInfoGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return response
   */
  ControllersInfoGET(
    params: StatusService.ControllersInfoGETParams
  ): __Observable<Inline_response_200_6> {
    return this.ControllersInfoGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_6)
    );
  }

  /**
   * Get current alarms status.
   * @param controller_id id of controller
   * @return response
   */
  ControllersActivealarmsByControllerIdGETResponse(
    params: StatusService.ControllersActivealarmsByControllerIdGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_7>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/activealarms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_7>;
      })
    );
  }
  /**
   * Get current alarms status.
   * @param controller_id id of controller
   * @return response
   */
  ControllersActivealarmsByControllerIdGET(
    params: StatusService.ControllersActivealarmsByControllerIdGETParams
  ): __Observable<Inline_response_200_7> {
    return this.ControllersActivealarmsByControllerIdGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_7)
    );
  }

  /**
   * Get current alarms status for user/admin controllers
   * @param params The `StatusService.ControllersActivealarmsGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * - `alarmType`: restricted to staticcontent/alarmtypes options
   *
   * @return response
   */
  ControllersActivealarmsGETResponse(
    params: StatusService.ControllersActivealarmsGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_8>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSource || []).forEach((val) => {
      if (val != null)
        __params = __params.append("waterSource", val.toString());
    });
    if (params.thresholdExceeded != null)
      __params = __params.set(
        "thresholdExceeded",
        params.thresholdExceeded.toString()
      );
    (params.slaveNumber || []).forEach((val) => {
      if (val != null)
        __params = __params.append("slaveNumber", val.toString());
    });
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.orderBy != null)
      __params = __params.set("orderBy", params.orderBy.toString());
    (params.operationMode || []).forEach((val) => {
      if (val != null)
        __params = __params.append("operationMode", val.toString());
    });
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    if (params.onlineMode != null)
      __params = __params.set("onlineMode", params.onlineMode.toString());
    if (params.offset != null)
      __params = __params.set("offset", params.offset.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.isComActive != null)
      __params = __params.set("isComActive", params.isComActive.toString());
    (params.filterType || []).forEach((val) => {
      if (val != null) __params = __params.append("filterType", val.toString());
    });
    if (params.creationDateTo != null)
      __params = __params.set(
        "creationDateTo",
        params.creationDateTo.toString()
      );
    if (params.creationDateFrom != null)
      __params = __params.set(
        "creationDateFrom",
        params.creationDateFrom.toString()
      );
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.controllerType || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    (params.alarmType || []).forEach((val) => {
      if (val != null) __params = __params.append("alarmType", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/activealarms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_8>;
      })
    );
  }
  /**
   * Get current alarms status for user/admin controllers
   * @param params The `StatusService.ControllersActivealarmsGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * - `alarmType`: restricted to staticcontent/alarmtypes options
   *
   * @return response
   */
  ControllersActivealarmsGET(
    params: StatusService.ControllersActivealarmsGETParams
  ): __Observable<Inline_response_200_8> {
    return this.ControllersActivealarmsGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_8)
    );
  }

  /**
   * Get current flushing status.
   * @param controller_id id of controller
   * @return response
   */
  ControllersFlushByControllerIdGETResponse(
    params: StatusService.ControllersFlushByControllerIdGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_10>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/flush`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_10>;
      })
    );
  }
  /**
   * Get current flushing status.
   * @param controller_id id of controller
   * @return response
   */
  ControllersFlushByControllerIdGET(
    params: StatusService.ControllersFlushByControllerIdGETParams
  ): __Observable<Inline_response_200_10> {
    return this.ControllersFlushByControllerIdGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_10)
    );
  }

  /**
   * Get current flushing status.
   * @param params The `StatusService.ControllersFlushGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return response
   */
  ControllersFlushGETResponse(
    params: StatusService.ControllersFlushGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_11>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSource || []).forEach((val) => {
      if (val != null)
        __params = __params.append("waterSource", val.toString());
    });
    if (params.thresholdExceeded != null)
      __params = __params.set(
        "thresholdExceeded",
        params.thresholdExceeded.toString()
      );
    (params.slaveNumber || []).forEach((val) => {
      if (val != null)
        __params = __params.append("slaveNumber", val.toString());
    });
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.orderBy != null)
      __params = __params.set("orderBy", params.orderBy.toString());
    (params.operationMode || []).forEach((val) => {
      if (val != null)
        __params = __params.append("operationMode", val.toString());
    });
    if (params.onlineMode != null)
      __params = __params.set("onlineMode", params.onlineMode.toString());
    if (params.offset != null)
      __params = __params.set("offset", params.offset.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.isComActive != null)
      __params = __params.set("isComActive", params.isComActive.toString());
    if (params.isAlarmed != null)
      __params = __params.set("isAlarmed", params.isAlarmed.toString());

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    (params.filterType || []).forEach((val) => {
      if (val != null) __params = __params.append("filterType", val.toString());
    });
    if (params.creationDateTo != null)
      __params = __params.set(
        "creationDateTo",
        params.creationDateTo.toString()
      );
    if (params.creationDateFrom != null)
      __params = __params.set(
        "creationDateFrom",
        params.creationDateFrom.toString()
      );
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.controllerType || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/flush`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_11>;
      })
    );
  }
  /**
   * Get current flushing status.
   * @param params The `StatusService.ControllersFlushGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return response
   */
  ControllersFlushGET(
    params: StatusService.ControllersFlushGETParams
  ): __Observable<Inline_response_200_11> {
    return this.ControllersFlushGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_11)
    );
  }

  /**
   * Get count of flushing actions.
   * @param controller_id id of controller
   * @return response
   */
  ControllersFlushCountersByControllerIdGETResponse(
    params: StatusService.ControllersFlushCountersByControllerIdGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_12>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/flush/counters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_12>;
      })
    );
  }
  /**
   * Get count of flushing actions.
   * @param controller_id id of controller
   * @return response
   */
  ControllersFlushCountersByControllerIdGET(
    params: StatusService.ControllersFlushCountersByControllerIdGETParams
  ): __Observable<Inline_response_200_12> {
    return this.ControllersFlushCountersByControllerIdGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_12)
    );
  }

  /**
   * Get count of flushing actions.
   * @param params The `StatusService.ControllersFlushCountersGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return response
   */
  ControllersFlushCountersGETResponse(
    params: StatusService.ControllersFlushCountersGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_13>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSource || []).forEach((val) => {
      if (val != null)
        __params = __params.append("waterSource", val.toString());
    });
    if (params.thresholdExceeded != null)
      __params = __params.set(
        "thresholdExceeded",
        params.thresholdExceeded.toString()
      );
    (params.slaveNumber || []).forEach((val) => {
      if (val != null)
        __params = __params.append("slaveNumber", val.toString());
    });
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.orderBy != null)
      __params = __params.set("orderBy", params.orderBy.toString());
    (params.operationMode || []).forEach((val) => {
      if (val != null)
        __params = __params.append("operationMode", val.toString());
    });
    if (params.onlineMode != null)
      __params = __params.set("onlineMode", params.onlineMode.toString());
    if (params.offset != null)
      __params = __params.set("offset", params.offset.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.isComActive != null)
      __params = __params.set("isComActive", params.isComActive.toString());
    if (params.isAlarmed != null)
      __params = __params.set("isAlarmed", params.isAlarmed.toString());
    (params.filterType || []).forEach((val) => {
      if (val != null) __params = __params.append("filterType", val.toString());
    });
    if (params.creationDateTo != null)
      __params = __params.set(
        "creationDateTo",
        params.creationDateTo.toString()
      );
    if (params.creationDateFrom != null)
      __params = __params.set(
        "creationDateFrom",
        params.creationDateFrom.toString()
      );
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.controllerType || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/flush/counters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_13>;
      })
    );
  }
  /**
   * Get count of flushing actions.
   * @param params The `StatusService.ControllersFlushCountersGETParams` containing the following parameters:
   *
   * - `waterSource`: restricted to values by /staticcontent/watersources
   *
   * - `thresholdExceeded`:
   *
   * - `slaveNumber`:
   *
   * - `search`:
   *
   * - `orderBy`:
   *
   * - `operationMode`:
   *
   * - `onlineMode`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `isComActive`:
   *
   * - `isAlarmed`:
   *
   * - `filterType`: possible values returned by /lists/filterTypes
   *
   * - `creationDateTo`: controller's creation date range (from and to should be specified together)
   *
   * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
   *
   * - `country`:
   *
   * - `controllerType`:
   *
   * - `controllerAccess`: If not defined, owned and shared are filtered by default.
   *
   * @return response
   */
  ControllersFlushCountersGET(
    params: StatusService.ControllersFlushCountersGETParams
  ): __Observable<Inline_response_200_13> {
    return this.ControllersFlushCountersGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_13)
    );
  }
}

module StatusService {
  export interface ControllersInfoByControllerIdGETParams {
    /**
     * id of controller
     */
    controllerId?: string;
    tenantId: string;
  }

  export interface ControllersActivealarmsByControllerIdGETParams {
    /**
     * id of controller
     */
    controllerId?: string;
    tenantId?: string;
  }

  export interface ControllersFlushCountersByControllerIdGETParams {
    /**
     * id of controller
     */
    controllerId?: string;
    tenantId?: string;
  }

  export interface ControllersFlushByControllerIdGETParams {
    /**
     * id of controller
     */
    controllerId?: string;
    tenantId?: string;
  }

  /**
   * Parameters for ControllersStatusByControllerIdGET
   */
  export interface ControllersStatusByControllerIdGETParams {
    /**
     * id of controller
     */
    controllerId?: string;

    /**
     * if true, status response will contain controllers info
     */
    includeInfo?: boolean;

    tenantId?: string;
  }

  /**
   * Parameters for ControllersStatusGET
   */
  export interface ControllersStatusGETParams {
    tenantId?: string;
    /**
     * restricted to values by /staticcontent/watersources
     */
    waterSource?: Array<string>;
    thresholdExceeded?: boolean;
    slaveNumber?: Array<number>;
    search?: string;
    orderBy?:
      | "serialNumber"
      | "filterType"
      | "connectionMode"
      | "fwVersion"
      | "country";
    operationMode?: Array<"controller" | "master" | "slave">;
    onlineMode?: "online" | "offline";

    /**
     * in multi-result responses, specifies which result to send from
     */
    offset?: string;

    /**
     * in multi-result responses, specifies how many results to send
     */
    limit?: string;
    isComActive?: "true" | "false";
    isAlarmed?: boolean;

    /**
     * if true, status response will contain controllers info
     */
    includeInfo?: boolean;

    /**
     * possible values returned by /lists/filterTypes
     */
    filterType?: Array<string>;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateTo?: string;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateFrom?: string;
    country?: Array<string>;
    controllerType?: Array<"agriculture" | "industrial">;

    /**
     * If not defined, owned and shared are filtered by default.
     */
    controllerAccess?: Array<"owned" | "shared" | "managed">;
  }

  /**
   * Parameters for ControllersInfoGET
   */
  export interface ControllersInfoGETParams {
    tenantId?: string;
    /**
     * restricted to values by /staticcontent/watersources
     */
    waterSource?: Array<string>;
    thresholdExceeded?: boolean;
    slaveNumber?: Array<number>;
    search?: string;
    orderBy?:
      | "serialNumber"
      | "filterType"
      | "connectionMode"
      | "fwVersion"
      | "country";
    operationMode?: Array<"controller" | "master" | "slave">;
    onlineMode?: "online" | "offline";

    /**
     * in multi-result responses, specifies which result to send from
     */
    offset?: string;

    /**
     * in multi-result responses, specifies how many results to send
     */
    limit?: string;
    isComActive?: "true" | "false";
    isAlarmed?: boolean;

    /**
     * possible values returned by /lists/filterTypes
     */
    filterType?: Array<string>;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateTo?: string;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateFrom?: string;
    country?: Array<string>;
    controllerType?: Array<"agriculture" | "industrial">;

    /**
     * If not defined, owned and shared are filtered by default.
     */
    controllerAccess?: Array<"owned" | "shared" | "managed">;
  }

  /**
   * Parameters for ControllersActivealarmsGET
   */
  export interface ControllersActivealarmsGETParams {
    tenantId?: string;
    /**
     * restricted to values by /staticcontent/watersources
     */
    waterSource?: Array<string>;
    thresholdExceeded?: boolean;
    slaveNumber?: Array<number>;
    search?: string;
    orderBy?:
      | "serialNumber"
      | "filterType"
      | "connectionMode"
      | "fwVersion"
      | "country";
    operationMode?: Array<"controller" | "master" | "slave">;
    onlineMode?: "online" | "offline";

    /**
     * in multi-result responses, specifies which result to send from
     */
    offset?: string;

    /**
     * in multi-result responses, specifies how many results to send
     */
    limit?: string;
    isComActive?: "true" | "false";

    /**
     * possible values returned by /lists/filterTypes
     */
    filterType?: Array<string>;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateTo?: string;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateFrom?: string;
    country?: Array<string>;
    controllerType?: Array<"agriculture" | "industrial">;

    /**
     * If not defined, owned and shared are filtered by default.
     */
    controllerAccess?: Array<"owned" | "shared" | "managed">;

    /**
     * restricted to staticcontent/alarmtypes options
     */
    alarmType?: Array<string>;
  }

  /**
   * Parameters for ControllersFlushGET
   */
  export interface ControllersFlushGETParams {
    tenantId?: string;
    /**
     * restricted to values by /staticcontent/watersources
     */
    waterSource?: Array<string>;
    thresholdExceeded?: boolean;
    slaveNumber?: Array<number>;
    search?: string;
    orderBy?:
      | "serialNumber"
      | "filterType"
      | "connectionMode"
      | "fwVersion"
      | "country";
    operationMode?: Array<"controller" | "master" | "slave">;
    onlineMode?: "online" | "offline";

    /**
     * in multi-result responses, specifies which result to send from
     */
    offset?: string;

    /**
     * in multi-result responses, specifies how many results to send
     */
    limit?: string;
    isComActive?: "true" | "false";
    isAlarmed?: boolean;

    /**
     * possible values returned by /lists/filterTypes
     */
    filterType?: Array<string>;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateTo?: string;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateFrom?: string;
    country?: Array<string>;
    controllerType?: Array<"agriculture" | "industrial">;

    /**
     * If not defined, owned and shared are filtered by default.
     */
    controllerAccess?: Array<"owned" | "shared" | "managed">;
  }

  /**
   * Parameters for ControllersFlushCountersGET
   */
  export interface ControllersFlushCountersGETParams {
    /**
     * restricted to values by /staticcontent/watersources
     */
    waterSource?: Array<string>;
    thresholdExceeded?: boolean;
    slaveNumber?: Array<number>;
    search?: string;
    orderBy?:
      | "serialNumber"
      | "filterType"
      | "connectionMode"
      | "fwVersion"
      | "country";
    operationMode?: Array<"controller" | "master" | "slave">;
    onlineMode?: "online" | "offline";

    /**
     * in multi-result responses, specifies which result to send from
     */
    offset?: string;

    /**
     * in multi-result responses, specifies how many results to send
     */
    limit?: string;
    isComActive?: "true" | "false";
    isAlarmed?: boolean;

    /**
     * possible values returned by /lists/filterTypes
     */
    filterType?: Array<string>;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateTo?: string;

    /**
     * controller's creation date range (from and to should be specified together)
     */
    creationDateFrom?: string;
    country?: Array<string>;
    controllerType?: Array<"agriculture" | "industrial">;

    /**
     * If not defined, owned and shared are filtered by default.
     */
    controllerAccess?: Array<"owned" | "shared" | "managed">;

    tenantId?: string;
  }
}

export { StatusService };
