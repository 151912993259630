

import { Injector } from '@angular/core';

import { ControllerAlert } from 'amiad-shared-lib';

import { BaseResponse } from '../base-response.model';
import { Alert } from './alert.interface';
import { AlertAdapterService } from './alert-adapter.service';
import { ControllerActiveAlertsAdapterService } from './controller-active-alerts-adapter.service';
import { ControllerHistoryAlertsAdapterService } from './controller-history-alerts-adapter.service';
import { ActiveAlertAdapterService } from './active-alert-adapter.service';
import { HistoryAlertAdapterService } from './history-alert-adapter.service';


const injector = Injector.create({
  providers: [
    {
      provide: ActiveAlertAdapterService,
      deps: []
    },
    {
      provide: HistoryAlertAdapterService,
      deps: []
    },
    {
      provide: AlertAdapterService,
      deps: []
    },
    {
      provide: ControllerActiveAlertsAdapterService,
      deps: []
    },
    {
      provide: ControllerHistoryAlertsAdapterService,
      deps: []
    }

  ],
});


// All user controllers alerts
export class AlertsResponse extends BaseResponse {
  alerts: Alert[];

  constructor(type: 'active' | 'history', payload) {
    super(payload);
    if (!this._errors && payload.data) {
      // var adapter = injector.get(AlertAdapterService);
      // this.alerts = adapter.adaptMany(payload.data.data)

      if(type === 'active'){
        // adapt active alerts
        var activeAdapter = injector.get(ActiveAlertAdapterService); 
        var payloadToAdapt = payload.data.data || [];
        this.alerts = activeAdapter.adaptMany(payloadToAdapt);
      } else {
        // adapt history alerts
        var historyAdapter = injector.get(HistoryAlertAdapterService);
        var payloadToAdapt = payload.data && payload.data.events ? payload.data.events : [];
        this.alerts = historyAdapter.adaptMany(payloadToAdapt)
      }
    }    

  }

  isValid(): boolean {
    var isValid = super.isValid() && this.alerts ? true : false;
    return isValid;
  }
}


// A single controller alerts
export class ControllerAlertsResponse extends BaseResponse {
  alerts: ControllerAlert[];

  constructor(type: 'active' | 'history', payload) {
    super(payload);
    if (!this._errors && payload.data) {

      if (type === 'active') {
        // adapt active alerts
        var activeAdapter = injector.get(ControllerActiveAlertsAdapterService);
        this.alerts = activeAdapter.adaptMany(payload.data.data)
      } else {
        // adapt history alerts
        var historyAdapter = injector.get(ControllerHistoryAlertsAdapterService);
        this.alerts = historyAdapter.adaptMany(payload.data.events)
      }
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.alerts ? true : false;
    return isValid;
  }
}