/* tslint:disable */
type Result2 =
  'fail' |
  'success';
module Result2 {
  export const FAIL: Result2 = 'fail';
  export const SUCCESS: Result2 = 'success';
  export function values(): Result2[] {
    return [
      FAIL,
      SUCCESS
    ];
  }
}

export { Result2 }