export class BaseResponse {
    protected _status: ResponseStatus;
    protected _errors?: ResponseError[];

    constructor(payload) {
        if (payload && payload.status &&
            (payload.status === ResponseStatus.Success ||
                payload.status.result.toLowerCase() === 'success'
                )) {
            this._status = ResponseStatus.Success;
        } else {
            this._status = ResponseStatus.Failure;
            if (payload && payload.errors && payload.errors.length) {
                this._errors = [];
                payload.errors.forEach((err) => {
                    this._errors.push({
                        code: ErrorCode.General,
                        // TODO: Parse error with metadata (err.metadata)
                        message: err.description
                    });
                });
            }
        }
    }

    get status(): ResponseStatus {
        return this._status === ResponseStatus.Success ? ResponseStatus.Success : ResponseStatus.Failure;
    }

    get errors(): ResponseError[] {
        if (this._errors) {
            return this._errors.slice(0);
        }
        return null;
    }

    isValid(): boolean {
        return this._status === ResponseStatus.Success && !this._errors;
    }
}


// export interface BaseResponse {
// 	status: ResponseStatus;
// 	errors?: ResponseError[];
// }

export enum ResponseStatus {
    Success = "success",
    Failure = "fail"
}

export interface ResponseError {
    code: ErrorCode;
    message: string;
}

export enum ErrorCode {
    General,
    NotFound
}

// export interface IBaseResponse {
// 	status: string;
// 	errors?: [];
// }