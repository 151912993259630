var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, } from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { map as __map, filter as __filter } from "rxjs/operators";
import { environment as env } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var StaticContentService = /** @class */ (function (_super) {
    __extends(StaticContentService, _super);
    function StaticContentService(config, http) {
        var _this = _super.call(this, config, http) || this;
        _this.rootUrl = env.api.secondaryURL;
        return _this;
    }
    /**
     * Get list of values
     * @param content_key desired content
     * @return list of filter types
     */
    StaticContentService.prototype.StaticcontentByContentKeyGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest("GET", this.rootUrl +
            "/tenants/" +
            params.tenantId +
            "/staticcontent/" +
            params.contentKey, __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get list of values
     * @param content_key desired content
     * @return list of filter types
     */
    StaticContentService.prototype.StaticcontentByContentKeyGET = function (params) {
        return this.StaticcontentByContentKeyGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    StaticContentService.StaticcontentByContentKeyGETPath = "/staticcontent/{content_key}";
    StaticContentService.ngInjectableDef = i0.defineInjectable({ factory: function StaticContentService_Factory() { return new StaticContentService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: StaticContentService, providedIn: "root" });
    return StaticContentService;
}(__BaseService));
export { StaticContentService };
