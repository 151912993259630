/* tslint:disable */

/**
 * AIN2 Sensor Type
 */
type CONFIGIDAIN2SENSOR =
  'CONFIG_AIN_SENSOR_TEMP' |
  'CONFIG_AIN_SENSOR_HUMIDITY';
module CONFIGIDAIN2SENSOR {
  export const CONFIG_AIN_SENSOR_TEMP: CONFIGIDAIN2SENSOR = 'CONFIG_AIN_SENSOR_TEMP';
  export const CONFIG_AIN_SENSOR_HUMIDITY: CONFIGIDAIN2SENSOR = 'CONFIG_AIN_SENSOR_HUMIDITY';
  export function values(): CONFIGIDAIN2SENSOR[] {
    return [
      CONFIG_AIN_SENSOR_TEMP,
      CONFIG_AIN_SENSOR_HUMIDITY
    ];
  }
}

export { CONFIGIDAIN2SENSOR }