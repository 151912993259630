import { Injectable } from '@angular/core';

import { Alert } from './alert.interface';
import { Adapter } from '../controller/iadapter.interface';
import { DateUtilService } from '../../../core/utils/date-util.service';


@Injectable()
export class ActiveAlertAdapterService implements Adapter<Alert> {
    private dateUtil : DateUtilService = new DateUtilService();


    adapt(dto): Alert {
        var alert: Alert;
        if (dto && dto.alarm) {
            alert = {
                controllerId: dto.controllerId,
                title: dto.alarm.type,
                siteName: dto.alarm.siteName,
                description:"",
                timeReceived: this.dateUtil.parseISOString(dto.alarm.time)
            }
        }

        return alert;
    }

    adaptMany(dtoArr: any[]): Alert[] {
        if (dtoArr) {
            return dtoArr.map(x => this.adapt(x));
        }
        return null;
    }
}

