/* tslint:disable */

/**
 * Communication Method - Determine the communication method of the controller
 */
type CONFIGIDCOMMMETHOD =
  'CONFIG_COMM_METHOD_OFFLINE' |
  'CONFIG_COMM_METHOD_ONLINE';
module CONFIGIDCOMMMETHOD {
  export const CONFIG_COMM_METHOD_OFFLINE: CONFIGIDCOMMMETHOD = 'CONFIG_COMM_METHOD_OFFLINE';
  export const CONFIG_COMM_METHOD_ONLINE: CONFIGIDCOMMMETHOD = 'CONFIG_COMM_METHOD_ONLINE';
  export function values(): CONFIGIDCOMMMETHOD[] {
    return [
      CONFIG_COMM_METHOD_OFFLINE,
      CONFIG_COMM_METHOD_ONLINE
    ];
  }
}

export { CONFIGIDCOMMMETHOD }