import { UsersService } from "../../../api/services";
import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "ng-trio-infra";
import { map, share, take } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "ng-trio-infra";
import * as i2 from "../../../api/services/users.service";
import * as i3 from "@angular/common/http";
var MockMode = true;
var ClientService = /** @class */ (function () {
    function ClientService(logger, clientService, http) {
        this.logger = logger;
        this.clientService = clientService;
        this.http = http;
        this.TAG = "ClientService";
        this.params = {};
    }
    ClientService.prototype.getAdminClients = function (limit, offset, search, jobDescription, country, sortBy) {
        if (sortBy === void 0) { sortBy = "name"; }
        if (!MockMode) {
            // return ClientMockUtil.getAdminClients();
        }
        else {
            // if (limit !== 99 || offset !== 99) {
            //   const pageSize = $("select[name=clientsTable_length]").val();
            //   if (pageSize != limit) {
            //     this.params.limit = pageSize.toString();
            //   } else {
            //     this.params.limit = limit.toString();
            //   }
            //   this.params.offset = offset.toString();
            // }
            this.params.limit = limit;
            this.params.offset = offset;
            this.params.search = search;
            this.params.jobDescription = jobDescription;
            this.params.country = country;
            this.params.orderBy = sortBy;
            // sortBy === "Job Description" ? "jobDescription" : sortBy.toLowerCase();
            this.params.role = ["user"];
            return this.clientService.UsersGET(this.params).map(function (apiPagedUsers) {
                return {
                    offset: apiPagedUsers.data.pagination.offset,
                    count: apiPagedUsers.data.pagination.count,
                    totalCount: apiPagedUsers.data.pagination.totalCount,
                    users: apiPagedUsers.data.data.map(function (apiUser) {
                        return {
                            name: apiUser.fullName,
                            email: apiUser.email,
                            company: apiUser.company,
                            country: apiUser.country,
                            jobDescription: apiUser.jobDescription,
                            shouldReceiveUpdates: apiUser.shouldReceiveUpdates,
                        };
                    }),
                };
            });
        }
    };
    ClientService.prototype.getAdminClientsList = function () {
        if (!MockMode) {
        }
        else {
            this.params.role = ["user"]; //["client"]
            return this.clientService.UsersGET(this.params).map(function (apiPagedUsers) {
                return {
                    users: apiPagedUsers.data.data.map(function (apiUser) {
                        return {
                            name: apiUser.fullName,
                            email: apiUser.email,
                        };
                    }),
                };
            });
        }
    };
    ClientService.prototype.getAdminUserData = function () {
        if (!MockMode) {
        }
        else {
            return this.clientService.UsersMeGET().map(function (apiAdminUser) {
                return {
                    countries: apiAdminUser.data.adminInfo.managedCountries,
                };
            });
        }
    };
    ClientService.prototype.getAdminUserInfo = function () {
        if (!MockMode) {
        }
        else {
            return this.clientService.UsersMeGET().map(function (apiAdminUser) {
                return {
                    user: apiAdminUser.data,
                };
            });
        }
    };
    ClientService.prototype.getUser = function () {
        var _this = this;
        this.logger.info(this.TAG, "Trying to get user...");
        if (this.user) {
            return of(this.user).pipe(take(1));
        }
        return this.loadUserFromAPI().pipe(map(function (res) {
            _this.user = res;
            return _this.user;
        }), share());
    };
    ClientService.prototype.patchUser = function (updatedUser) {
        if (this.user && this.user.data && updatedUser) {
            var userData = this.user.data;
            var userProps = Object.keys(userData);
            for (var i = 0; i < userProps.length; i++) {
                var prop = userProps[i]; // user object key
                var newPropValue = updatedUser[prop];
                if (newPropValue !== undefined && userData[prop] !== newPropValue) {
                    userData[prop] = newPropValue;
                }
            }
        }
    };
    ClientService.prototype.loadUserFromAPI = function () {
        // this.logger.info(this.TAG, 'Loading User from API');
        var getUser$ = this.clientService.UsersMeGET();
        return getUser$.pipe(map(function (payload) {
            return payload;
        }));
    };
    ClientService.ngInjectableDef = i0.defineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.inject(i1.LoggerService), i0.inject(i2.UsersService), i0.inject(i3.HttpClient)); }, token: ClientService, providedIn: "root" });
    return ClientService;
}());
export { ClientService };
