import { Injectable } from "@angular/core";
import { ClientService } from "../../../app/shared/api-services/clients/client.service";
import { PagedUsers } from "../../../app/shared/api-models/user/paged-user";
import { UserInfo } from "../../../app/shared/api-models/user/user-info";
import { DatePipe } from "@angular/common";
import { throwError, Subscription } from "rxjs";
import { TranslatorService } from "../translator/translator.service";
import { TranslateService } from "@ngx-translate/core";
const Swal = require("sweetalert2");

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(private clientService: ClientService) {}

  clients: UserInfo[];
  userCurentuserContent: string[];
  subscription: Subscription;

  Error: string;
  AddedFirmwaer: string;
  UpdatedFirmwaer: string;
  Version: string;
  AddedUserA: string;
  AddedUserB: string;
  ErrorDescription: string;

  getParams(__params, arryParams, param) {
    arryParams.forEach((element) => {
      __params = __params.append(param, element);
    });
    return __params;
  }

  getClients() {
    this.clientService
      .getAdminClientsList()
      .subscribe((clients: PagedUsers) => {
        return clients.users;
      });
  }

  getStaticContentNameByKey(userContent, staticContentKey) {
    userContent.forEach((countryMy, index) => {
      const result = staticContentKey.filter(
        (country) => country.key === countryMy
      );
      if (result.length > 0) {
        const resultCountry =
          result[0].description !== undefined
            ? result[0].description
            : result[0].value;
        userContent[index] = resultCountry;
      }
    });
    this.userCurentuserContent = userContent;
    return this.userCurentuserContent;
  }

  setTableExportData(TableData, TableType) {
    const DataArray = [];
    let formatedObject = {};
    TableData.forEach(function (object, i) {
      switch (TableType) {
        case "Clients":
          formatedObject = {
            email: object.email,
            name: object.name,
            jobDescription: object.jobDescription,
            company: object.company,
            countries: object.countries,
          };
          break;
        case "Controllers":
          formatedObject = {
            controllerSN: object.controllerSN,
            filterType: object.filterType,
            info: "",
          };
          break;
        case "Flushes":
          const pipeFlush = new DatePipe("en-US");
          const timeFlush = pipeFlush.transform(object.time, "short");
          formatedObject = {
            controllerSN: object.controllerId,
            description: object.description,
            time: timeFlush,
            dpBefore: object.dpBefore,
            dpAfter: object.dpAfter,
          };
          break;
        case "Alerts":
          const pipe = new DatePipe("en-US");
          const time = pipe.transform(object.time, "short");
          formatedObject = {
            controllerSN: object.controllerId,
            description: object.description,
            time: time,
          };
          break;
        case "Admins":
          formatedObject = {
            email: object.email,
            jobDescription: object.jobDescription,
            company: object.company,
            countries: object.countries,
            edit: "",
          };
          break;
        case "ControllersThresholds":
          formatedObject = {
            id: object.id,
            filterType: object.filterType,
            curentCount: object.curentCount,
            filterThreshold: object.filterThreshold,
            reset: "",
          };
          break;
      }
      const array = [];
      array.push((i + 1).toString());
      $.each(formatedObject, function (key, value) {
        array.push(value);
      });

      DataArray.push(array);
    });
    localStorage.setItem("export", JSON.stringify(DataArray));
  }
}
