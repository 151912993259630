/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { StrictHttpResponse as __StrictHttpResponse } from "../strict-http-response";
import { Observable as __Observable } from "rxjs";
import { map as __map, filter as __filter } from "rxjs/operators";

import { Inline_response_200_14 } from "../models/inline-_response-_200_14";
import { GenericSuccessResponse } from "../models/generic-success-response";
import { DeviceConfigurationsBody } from "../models/device-configurations-body";
import { SystemConfigurations } from "../models/system-configurations";
@Injectable({
  providedIn: "root",
})
class ConfigurationService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Get current configurations of controller
   * @param controller_id id of controller
   * @return response
   */
  ControllersConfigurationsByControllerIdGetResponse(
    params: ConfigurationService.ControllersConfigurationsByControllerIdGetResponseParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_14>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_14>;
      })
    );
  }
  /**
   * Get current configurations of controller
   * @param controller_id id of controller
   * @return response
   */
  ControllersConfigurationsByControllerIdGet(
    props: ConfigurationService.ControllersConfigurationsByControllerIdGetResponseParams
  ): __Observable<Inline_response_200_14> {
    return this.ControllersConfigurationsByControllerIdGetResponse(
      props
    ).pipe(__map((_r) => _r.body as Inline_response_200_14));
  }

  /**
   * Set configurations for controller. Controller parameters will be validated according to firmware specifications (HLD 1.1)
   * @param params The `ConfigurationService.ControllersConfigurationsByControllerIdPostParams` containing the following parameters:
   *
   * - `parameter`:
   *
   * - `controller_id`: id of controller
   *
   * @return Configured successfuly
   */
  ControllersConfigurationsByControllerIdPostResponse(
    params: ConfigurationService.ControllersConfigurationsByControllerIdPostParams
  ): __Observable<__StrictHttpResponse<GenericSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.parameter;

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/controllers/${params.controllerId}/configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericSuccessResponse>;
      })
    );
  }
  /**
   * Set configurations for controller. Controller parameters will be validated according to firmware specifications (HLD 1.1)
   * @param params The `ConfigurationService.ControllersConfigurationsByControllerIdPostParams` containing the following parameters:
   *
   * - `parameter`:
   *
   * - `controller_id`: id of controller
   *
   * @return Configured successfuly
   */
  ControllersConfigurationsByControllerIdPost(
    params: ConfigurationService.ControllersConfigurationsByControllerIdPostParams
  ): __Observable<GenericSuccessResponse> {
    return this.ControllersConfigurationsByControllerIdPostResponse(
      params
    ).pipe(__map((_r) => _r.body as GenericSuccessResponse));
  }

  /**
   * Get global configurations of entire system (super admin only)
   * @return Configured successfuly
   */
  SystemConfigurationsGetResponse(): __Observable<
    __StrictHttpResponse<SystemConfigurations>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/system/configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystemConfigurations>;
      })
    );
  }
  /**
   * Get global configurations of entire system (super admin only)
   * @return Configured successfuly
   */
  SystemConfigurationsGet(): __Observable<SystemConfigurations> {
    return this.SystemConfigurationsGetResponse().pipe(
      __map((_r) => _r.body as SystemConfigurations)
    );
  }

  /**
   * Set global configurations of entire system (super admin only)
   * @param parameter
   * @return Configured successfuly
   */
  SystemConfigurationsPostResponse(
    parameter: SystemConfigurations
  ): __Observable<__StrictHttpResponse<GenericSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameter;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/system/configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericSuccessResponse>;
      })
    );
  }
  /**
   * Set global configurations of entire system (super admin only)
   * @param parameter
   * @return Configured successfuly
   */
  SystemConfigurationsPost(
    parameter: SystemConfigurations
  ): __Observable<GenericSuccessResponse> {
    return this.SystemConfigurationsPostResponse(parameter).pipe(
      __map((_r) => _r.body as GenericSuccessResponse)
    );
  }
}

module ConfigurationService {
  /**
   * Parameters for ControllersConfigurationsByControllerIdPost
   */
  export interface ControllersConfigurationsByControllerIdPostParams {
    parameter: DeviceConfigurationsBody;

    /**
     * id of controller
     */
    controllerId: string;
  }

  export interface ControllersConfigurationsByControllerIdGetResponseParams {
    /**
     * id of controller
     */
    controllerId: string;
    tenantId?: string;
  }
}

export { ConfigurationService };
