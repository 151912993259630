import { Injectable } from "@angular/core";
import { StaticContentService } from "../../../app/api/services";
import { take, map, tap, catchError, timeout, delay } from "rxjs/operators";
import { KeyValue } from "@angular/common";

import { LoggerService } from "ng-trio-infra";
import { Observable, of, forkJoin, throwError } from "rxjs";
import { StaticContentResponse } from "./static-content.reponse.model";
import { environment as env } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class StaticContentProviderService implements IStaticContentProvider {
  constructor(
    // private userService: UserService,
    private logger: LoggerService,
    private apiContentService: StaticContentService
  ) {}
  public content: StaticContent = {
    alarmTypes: null,
    countries: null,
    filterTypes: null,
    filtrationDegrees: null,
    jobDescriptions: null,
    languages: null,
    waterSources: null,
    flushReasons: null,
  };

  get isLoaded(): boolean {
    return this.content.alarmTypes &&
      this.content.countries &&
      this.content.filterTypes &&
      //  this.content.filtrationDegrees &&
      this.content.flushReasons &&
      this.content.jobDescriptions &&
      this.content.languages &&
      this.content.waterSources
      ? true
      : false;
  }

  initialLoad(): Observable<any> {
    this.logger.info(
      "StaticContentProviderService",
      "Loading static content.."
    );

    var loadFilters$ = this.loadFilterTypes();
    var loadCountries$ = this.loadCountries();
    var loadJobs$ = this.loadJobDescriptions();
    var loadLanguages$ = this.loadLanguages();
    var loadAlarms$ = this.loadAlarmTypes();
    //    var loadFiltrationDegrees$ = this.loadFiltrationDegrees();
    var loadWaterSources$ = this.loadWaterSources();
    var loadFlushReasons$ = this.loadFlushReasons();

    return forkJoin(
      loadFilters$,
      loadCountries$,
      loadJobs$,
      loadLanguages$,
      loadAlarms$,
      // loadFiltrationDegrees$,
      loadWaterSources$,
      loadFlushReasons$
    ).pipe(
      catchError((err) => {
        this.logger.error("StaticContentProviderService", "Error", err);
        return of([]);
      })
    );
  }

  private pipeContentObservable(type: ContentType, obs: Observable<any>) {
    return obs.pipe(
      take(1),
      tap((payload) => {
        var resp = new StaticContentResponse(payload);
        if (resp.isValid()) {
          switch (type) {
            case "filtertypes":
              this.content.filterTypes = resp.content;
              break;
            case "countries":
              this.content.countries = resp.content;
              break;
            case "jobs":
              this.content.jobDescriptions = resp.content;
              break;
            case "languages":
              this.content.languages = resp.content;
              break;
            case "flushreasons":
              this.content.flushReasons = resp.content;
              break;
            case "alarmtypes":
              this.content.alarmTypes = resp.content;
              break;
            // case 'filterationdegrees':
            //     this.content.filtrationDegrees = resp.content;
            //     break;
            case "watersources":
              this.content.waterSources = resp.content;
              break;
            default:
              break;
          }
        } else {
          this.logger.error(
            "StaticContentProviderService",
            "Error loading " + type,
            resp.errors
          );
        }
      })
    );
  }

  loadFilterTypes(): Observable<any> {
    var contentType: ContentType = "filtertypes";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }
  loadCountries() {
    var contentType: ContentType = "countries";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }
  loadJobDescriptions() {
    var contentType: ContentType = "jobs";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }
  loadLanguages() {
    var contentType: ContentType = "languages";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }

  loadAlarmTypes() {
    var contentType: ContentType = "alarmtypes";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }
  // loadFiltrationDegrees() {
  //     var contentType: ContentType = 'filterationdegrees';
  //     var getContent$ = this.apiContentService.StaticcontentByContentKeyGET(contentType);

  //     return this.pipeContentObservable(contentType, getContent$);
  // }
  loadWaterSources() {
    var contentType: ContentType = "watersources";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }

  loadFlushReasons() {
    var contentType: ContentType = "flushreasons";
    var getContent$ = this.apiContentService.StaticcontentByContentKeyGET({
      contentKey: contentType,
      tenantId: env.tenant.id,
    });

    return this.pipeContentObservable(contentType, getContent$);
  }

  public isValidContentKey(type: ContentType, key: string): boolean {
    var contentToCheck: KeyValue<string, string>[] = [];

    switch (type) {
      case "filtertypes":
        contentToCheck = this.content.filterTypes;
        break;
      case "countries":
        contentToCheck = this.content.countries;
        break;
      case "jobs":
        contentToCheck = this.content.jobDescriptions;
        break;
      case "languages":
        contentToCheck = this.content.languages;
        break;
      case "flushreasons":
        contentToCheck = this.content.flushReasons;
        break;
      case "alarmtypes":
        contentToCheck = this.content.alarmTypes;
        break;
      // case 'filterationdegrees':
      //     contentToCheck = this.content.filtrationDegrees;
      //     break;
      case "watersources":
        contentToCheck = this.content.waterSources;
        break;
      default:
        break;
    }

    if (!contentToCheck) {
      return false;
    }

    for (var i = 0; i < contentToCheck.length; i++) {
      var contentKey = contentToCheck[i].key;
      if (contentKey === key) {
        return true;
      }
    }

    return false;
  }

  getFilterTypes(): KeyValue<string, string>[] {
    return this.content.filterTypes;
  }
  getCountries(): KeyValue<string, string>[] {
    return this.content.countries;
  }
  getJobDescriptions(): KeyValue<string, string>[] {
    return this.content.jobDescriptions;
  }
  getLanguages(): KeyValue<string, string>[] {
    return this.content.languages;
  }
  getAlarmTypes(): KeyValue<string, string>[] {
    return this.content.alarmTypes;
  }
  // getFiltrationDegrees(): KeyValue<string, string>[] {
  //     return this.content.filtrationDegrees;
  // }
  getWaterSources(): KeyValue<string, string>[] {
    return this.content.waterSources;
  }
  getFlushReasons(): KeyValue<string, string>[] {
    return this.content.flushReasons;
  }
}

interface IStaticContentProvider {
  content: StaticContent;
  initialLoad(): void;
  isValidContentKey(type: ContentType, key: string): boolean;
  getFilterTypes(): KeyValue<string, string>[];
  getCountries(): KeyValue<string, string>[];
  getJobDescriptions(): KeyValue<string, string>[];
  getLanguages(): KeyValue<string, string>[];
  getAlarmTypes(): KeyValue<string, string>[];
  //getFiltrationDegrees(): KeyValue<string, string>[];
  getWaterSources(): KeyValue<string, string>[];
  getFlushReasons(): KeyValue<string, string>[];
}

interface StaticContent {
  filterTypes: KeyValue<string, string>[];
  countries: KeyValue<string, string>[];
  jobDescriptions: KeyValue<string, string>[];
  languages: KeyValue<string, string>[];
  alarmTypes: KeyValue<string, string>[];
  filtrationDegrees: KeyValue<string, string>[];
  waterSources: KeyValue<string, string>[];
  flushReasons: KeyValue<string, string>[];
}

export type ContentType =
  | "filtertypes"
  | "countries"
  | "jobs"
  | "languages"
  | "flushreasons"
  | "alarmtypes"
  | "filterationdegrees"
  | "watersources";
