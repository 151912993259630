import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { from } from "rxjs";
import { AuthGroup } from "./authorization.types";
import { LoggerService } from "ng-trio-infra";
import { environment as env } from "../../environments/environment";
import { Role } from "../api/models";
import { UserClaims } from "./user-claims";
import * as i0 from "@angular/core";
import * as i1 from "ng-trio-infra";
import * as i2 from "@angular/router";
import * as i3 from "@azure/msal-angular/dist/msal.service";
var AuthService = /** @class */ (function () {
    function AuthService(logService, router, msalService) {
        this.logService = logService;
        this.router = router;
        this.msalService = msalService;
    }
    AuthService.prototype.login = function ( /*loginContext: User*/) {
        this.logService.debug(AuthService.TAG, "Creating auth request..");
        this.msalService.loginRedirect(env.aadb2c.scopes);
    };
    AuthService.prototype.getPermissions = function () {
        var user = this.extractUserClaims();
        var userRole = user.role;
        switch (userRole) {
            case Role.PRODUCTION:
                return [AuthGroup.Production];
            case Role.ADMIN:
                return [AuthGroup.Production, AuthGroup.Admin];
            case Role.SUPERADMIN:
                return [AuthGroup.Production, AuthGroup.Admin, AuthGroup.SuperAdmin];
            case Role.SYSTEMADMIN:
                return [AuthGroup.Production, AuthGroup.Admin, AuthGroup.SuperAdmin, AuthGroup.SystemAdmin];
            default:
                return [];
        }
    };
    AuthService.prototype.logout = function () {
        this.logService.debug(AuthService.TAG, "Logging out user!");
        this.msalService.logout();
        return false;
    };
    AuthService.prototype.forgotPassword = function () {
        this.msalService.authority =
            env.aadb2c.baseURL +
                "/" +
                env.aadb2c.tenantName +
                "/" +
                env.aadb2c.resetPasswordPolicy;
        this.logService.debug(AuthService.TAG, "Starting forgot password flow.", this.msalService.authority);
        this.msalService.loginRedirect();
    };
    AuthService.prototype.changePassword = function () {
        this.logService.debug(AuthService.TAG, "Change Password!");
        this.msalService.authority =
            env.aadb2c.baseURL +
                "/" +
                env.aadb2c.tenantName +
                "/" +
                env.aadb2c.changePasswordPolicy;
        this.msalService.loginRedirect(env.aadb2c.scopes);
    };
    AuthService.prototype.getToken = function () {
        var _this = this;
        return from(this.msalService
            .acquireTokenSilent([
            "https://" + env.aadb2c.tenantName + "/api/user_impersonation",
        ])
            // return this.msalService.acquireTokenSilent([this.tenantConfig.clientID])
            .then(function (token) {
            _this.logService.info(AuthService.TAG, "Token aquired silently: ", token);
            return token;
        })
            .catch(function (error) {
            _this.logService.debug(AuthService.TAG, "Error getting token: " + error);
        }));
    };
    // extractUserFromIdToken(): UserIdToken {
    //   const aadUser = this.msalService.getUser();
    //   return new UserIdToken(aadUser);
    // }
    AuthService.prototype.extractUserClaims = function () {
        var aadUser = this.msalService.getUser();
        return aadUser != null && aadUser.hasOwnProperty("idToken")
            ? new UserClaims(aadUser.idToken)
            : null;
    };
    // token: string;
    AuthService.TAG = "[AuthService]";
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.LoggerService), i0.inject(i2.Router), i0.inject(i3.MsalService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
