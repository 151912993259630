import { Observable } from 'rxjs';
import { AlertsResponse, ControllerAlertsResponse } from '../../api-models/alarm/alert-response.model';



export abstract class IAlertsService {
  abstract getAllActiveAlerts(): Observable<AlertsResponse>;
  abstract getControllerActiveAlerts(id:string): Observable<ControllerAlertsResponse>;  
  abstract getAllHistoryAlerts(
    from: string,
    to: string,
    search?: string,
    deviceOwner?: string[],
    country?: string[],
    alarmType?: any): Observable<AlertsResponse>;
 
}