/* tslint:disable */
type ShareStatus =
  'pending' |
  'accepted' |
  'rejected';
module ShareStatus {
  export const PENDING: ShareStatus = 'pending';
  export const ACCEPTED: ShareStatus = 'accepted';
  export const REJECTED: ShareStatus = 'rejected';
  export function values(): ShareStatus[] {
    return [
      PENDING,
      ACCEPTED,
      REJECTED
    ];
  }
}

export { ShareStatus }