export interface ILanguage {
    code: string;
    name: string;
    displayName: string;
    iconPath: string;
    direction : LangDirection;
}

export enum LangDirection {
    RTL = 1,
    LTR = 2
}
