var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, } from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { map as __map, filter as __filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var HistoryService = /** @class */ (function (_super) {
    __extends(HistoryService, _super);
    function HistoryService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * get history of flush activations. Either timeSpan or startDate+endDate are required.
     * @param params The `HistoryService.ControllersHistoryFlushSummaryByControllerIdGetParams` containing the following parameters:
     *
     * - `controller_id`: id of controller
     *
     * - `timeSpan`:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * @return flush history
     */
    HistoryService.prototype.ControllersHistoryFlushSummaryByControllerIdGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.timeSpan != null)
            __params = __params.set("timeSpan", params.timeSpan.toString());
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        var req = new HttpRequest("GET", this.rootUrl +
            ("/controllers/" + params.controllerId + "/history/flush/summary"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get history of flush activations. Either timeSpan or startDate+endDate are required.
     * @param params The `HistoryService.ControllersHistoryFlushSummaryByControllerIdGetParams` containing the following parameters:
     *
     * - `controller_id`: id of controller
     *
     * - `timeSpan`:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * @return flush history
     */
    HistoryService.prototype.ControllersHistoryFlushSummaryByControllerIdGet = function (params) {
        return this.ControllersHistoryFlushSummaryByControllerIdGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get history of flush activations. no offset/limit. If response too large - will fail.
     * @param params The `HistoryService.ControllersHistoryFlushByControllerIdGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * - `search`:
     *
     * - `flushReason`: restricted to staticcontent/flushreasons list
     *
     * - `deviceOwner`:
     *
     * @return flush history
     */
    HistoryService.prototype.ControllersHistoryFlushByControllerIdGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        (params.flushReason || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("flushReason", val.toString());
        });
        (params.deviceOwner || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("deviceOwner", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/history/flush"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get history of flush activations. no offset/limit. If response too large - will fail.
     * @param params The `HistoryService.ControllersHistoryFlushByControllerIdGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * - `search`:
     *
     * - `flushReason`: restricted to staticcontent/flushreasons list
     *
     * - `deviceOwner`:
     *
     * @return flush history
     */
    HistoryService.prototype.ControllersHistoryFlushByControllerIdGet = function (params) {
        return this.ControllersHistoryFlushByControllerIdGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get history for all controllers flush activations. no offset/limit. If response too large - will fail.
     * @param params The `HistoryService.ControllersHistoryFlushGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `search`:
     *
     * - `flushReason`: restricted to staticcontent/flushreasons list
     *
     * - `deviceOwner`:
     *
     * @return flush history
     */
    HistoryService.prototype.ControllersHistoryFlushGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.flushReason || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("flushReason", val.toString());
        });
        (params.deviceOwner || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("deviceOwner", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/history/flush", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get history for all controllers flush activations. no offset/limit. If response too large - will fail.
     * @param params The `HistoryService.ControllersHistoryFlushGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `search`:
     *
     * - `flushReason`: restricted to staticcontent/flushreasons list
     *
     * - `deviceOwner`:
     *
     * @return flush history
     */
    HistoryService.prototype.ControllersHistoryFlushGet = function (params) {
        return this.ControllersHistoryFlushGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get history of alarms
     * @param params The `HistoryService.ControllersHistoryAlarmsByControllerIdGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * - `search`:
     *
     * - `deviceOwner`:
     *
     * - `country`:
     *
     * - `alarmType`: restricted to staticcontent/alarmtypes options
     *
     * @return alarm history
     */
    HistoryService.prototype.ControllersHistoryAlarmsByControllerIdGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.deviceOwner || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("deviceOwner", val.toString());
        });
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.alarmType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("alarmType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/history/alarms"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get history of alarms
     * @param params The `HistoryService.ControllersHistoryAlarmsByControllerIdGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * - `search`:
     *
     * - `deviceOwner`:
     *
     * - `country`:
     *
     * - `alarmType`: restricted to staticcontent/alarmtypes options
     *
     * @return alarm history
     */
    HistoryService.prototype.ControllersHistoryAlarmsByControllerIdGet = function (params) {
        return this.ControllersHistoryAlarmsByControllerIdGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get alarms history for all controllers
     * @param params The `HistoryService.ControllersHistoryAlarmsGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `search`:
     *
     * - `deviceOwner`:
     *
     * - `country`:
     *
     * - `alarmType`: restricted to staticcontent/alarmtypes options
     *
     * @return alarm history
     */
    HistoryService.prototype.ControllersHistoryAlarmsGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.deviceOwner || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("deviceOwner", val.toString());
        });
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.alarmType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("alarmType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/history/alarms", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get alarms history for all controllers
     * @param params The `HistoryService.ControllersHistoryAlarmsGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * - `search`:
     *
     * - `deviceOwner`:
     *
     * - `country`:
     *
     * - `alarmType`: restricted to staticcontent/alarmtypes options
     *
     * @return alarm history
     */
    HistoryService.prototype.ControllersHistoryAlarmsGet = function (params) {
        return this.ControllersHistoryAlarmsGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get alarms history for all controllers
     * @param params The `HistoryService.ControllersHistoryAlarmsSummaryGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * @return alarm history
     */
    HistoryService.prototype.ControllersHistoryAlarmsSummaryGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/history/alarms/summary", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get alarms history for all controllers
     * @param params The `HistoryService.ControllersHistoryAlarmsSummaryGetParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `endDate`:
     *
     * @return alarm history
     */
    HistoryService.prototype.ControllersHistoryAlarmsSummaryGet = function (params) {
        return this.ControllersHistoryAlarmsSummaryGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get summary data of all my controllers
     * @return summary
     */
    HistoryService.prototype.ControllersHistorySummaryGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/history/summary", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get summary data of all my controllers
     * @return summary
     */
    HistoryService.prototype.ControllersHistorySummaryGet = function () {
        return this.ControllersHistorySummaryGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get sequence of aggregated history for analog input. input number can be
     * @param params The `HistoryService.ControllersHistoryAnaloginputGETParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `interval`:
     *
     * - `input_number`: id of analog input
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * @return data
     */
    HistoryService.prototype.ControllersHistoryAnaloginputGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.interval != null)
            __params = __params.set("interval", params.interval.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        var req = new HttpRequest("GET", this.rootUrl +
            ("/controllers/" + params.controllerId + "/history/analoginput/" + params.inputNumber), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get sequence of aggregated history for analog input. input number can be
     * @param params The `HistoryService.ControllersHistoryAnaloginputGETParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `interval`:
     *
     * - `input_number`: id of analog input
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * @return data
     */
    HistoryService.prototype.ControllersHistoryAnaloginputGET = function (params) {
        return this.ControllersHistoryAnaloginputGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get sequence of aggregated history for irrigation water meter.
     * @param params The `HistoryService.ControllersHistoryWatermetersIrrigationGETParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `interval`:
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * @return data
     */
    HistoryService.prototype.ControllersHistoryWatermetersIrrigationGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.interval != null)
            __params = __params.set("interval", params.interval.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        var req = new HttpRequest("GET", this.rootUrl +
            ("/controllers/" + params.controllerId + "/history/watermeters/irrigation"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get sequence of aggregated history for irrigation water meter.
     * @param params The `HistoryService.ControllersHistoryWatermetersIrrigationGETParams` containing the following parameters:
     *
     * - `startDate`:
     *
     * - `interval`:
     *
     * - `endDate`:
     *
     * - `controller_id`: id of controller
     *
     * @return data
     */
    HistoryService.prototype.ControllersHistoryWatermetersIrrigationGET = function (params) {
        return this.ControllersHistoryWatermetersIrrigationGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    HistoryService.prototype.ControllersHistoryDpGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.startDate != null)
            __params = __params.set("startDate", params.startDate.toString());
        if (params.interval != null)
            __params = __params.set("interval", params.interval.toString());
        if (params.endDate != null)
            __params = __params.set("endDate", params.endDate.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/history/dp"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    HistoryService.prototype.ControllersHistoryDpGET = function (params) {
        return this.ControllersHistoryDpGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    HistoryService.ControllersHistoryFlushSummaryByControllerIdGetPath = "/controllers/{controller_id}/history/flush/summary";
    HistoryService.ControllersHistoryFlushByControllerIdGetPath = "/controllers/{controller_id}/history/flush";
    HistoryService.ControllersHistoryFlushGetPath = "/controllers/history/flush";
    HistoryService.ControllersHistoryAlarmsByControllerIdGetPath = "/controllers/{controller_id}/history/alarms";
    HistoryService.ControllersHistoryAlarmsGetPath = "/controllers/history/alarms";
    HistoryService.ControllersHistoryAlarmsSummaryGetPath = "/controllers/history/alarms/summary";
    HistoryService.ControllersHistorySummaryGetPath = "/controllers/history/summary";
    HistoryService.ControllersHistoryAnaloginputByControllerIdAndInputNumberGetPath = "/controllers/{controller_id}/history/analoginput/{input_number}";
    HistoryService.ControllersHistoryWatermetersIrrigationByControllerIdGetPath = "/controllers/{controller_id}/history/watermeters/irrigation";
    HistoryService.ngInjectableDef = i0.defineInjectable({ factory: function HistoryService_Factory() { return new HistoryService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: HistoryService, providedIn: "root" });
    return HistoryService;
}(__BaseService));
export { HistoryService };
