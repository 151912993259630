import { ClientService } from "../../../app/shared/api-services/clients/client.service";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/api-services/clients/client.service";
var Swal = require("sweetalert2");
var UtilService = /** @class */ (function () {
    function UtilService(clientService) {
        this.clientService = clientService;
    }
    UtilService.prototype.getParams = function (__params, arryParams, param) {
        arryParams.forEach(function (element) {
            __params = __params.append(param, element);
        });
        return __params;
    };
    UtilService.prototype.getClients = function () {
        this.clientService
            .getAdminClientsList()
            .subscribe(function (clients) {
            return clients.users;
        });
    };
    UtilService.prototype.getStaticContentNameByKey = function (userContent, staticContentKey) {
        userContent.forEach(function (countryMy, index) {
            var result = staticContentKey.filter(function (country) { return country.key === countryMy; });
            if (result.length > 0) {
                var resultCountry = result[0].description !== undefined
                    ? result[0].description
                    : result[0].value;
                userContent[index] = resultCountry;
            }
        });
        this.userCurentuserContent = userContent;
        return this.userCurentuserContent;
    };
    UtilService.prototype.setTableExportData = function (TableData, TableType) {
        var DataArray = [];
        var formatedObject = {};
        TableData.forEach(function (object, i) {
            switch (TableType) {
                case "Clients":
                    formatedObject = {
                        email: object.email,
                        name: object.name,
                        jobDescription: object.jobDescription,
                        company: object.company,
                        countries: object.countries,
                    };
                    break;
                case "Controllers":
                    formatedObject = {
                        controllerSN: object.controllerSN,
                        filterType: object.filterType,
                        info: "",
                    };
                    break;
                case "Flushes":
                    var pipeFlush = new DatePipe("en-US");
                    var timeFlush = pipeFlush.transform(object.time, "short");
                    formatedObject = {
                        controllerSN: object.controllerId,
                        description: object.description,
                        time: timeFlush,
                        dpBefore: object.dpBefore,
                        dpAfter: object.dpAfter,
                    };
                    break;
                case "Alerts":
                    var pipe = new DatePipe("en-US");
                    var time = pipe.transform(object.time, "short");
                    formatedObject = {
                        controllerSN: object.controllerId,
                        description: object.description,
                        time: time,
                    };
                    break;
                case "Admins":
                    formatedObject = {
                        email: object.email,
                        jobDescription: object.jobDescription,
                        company: object.company,
                        countries: object.countries,
                        edit: "",
                    };
                    break;
                case "ControllersThresholds":
                    formatedObject = {
                        id: object.id,
                        filterType: object.filterType,
                        curentCount: object.curentCount,
                        filterThreshold: object.filterThreshold,
                        reset: "",
                    };
                    break;
            }
            var array = [];
            array.push((i + 1).toString());
            $.each(formatedObject, function (key, value) {
                array.push(value);
            });
            DataArray.push(array);
        });
        localStorage.setItem("export", JSON.stringify(DataArray));
    };
    UtilService.ngInjectableDef = i0.defineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.inject(i1.ClientService)); }, token: UtilService, providedIn: "root" });
    return UtilService;
}());
export { UtilService };
