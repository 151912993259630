import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoggerService } from "ng-trio-infra";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";
import {
  AdminRegistrationInfo,
  UserUpdateInfo
} from "../../../../app/api/models";
import { UtilService } from "../../../../app/core/utils/util.service";
import { User } from "../../../../app/routes/users/users/user.model";
import { environment as env } from "../../../../environments/environment";
import { UsersService } from "../../../api/services";
import { Tenant } from "../../api-models/tenants/tenant.interface";
import { PagedUsers } from "../../api-models/user/paged-user";
import { UserInfo } from "../../api-models/user/user-info";
const Swal = require("sweetalert2");
const MockMode = true;
@Injectable({
  providedIn: "root",
})
export class UsersAdminService {
  currentUser = new Subject<UserInfo>();
  currentUserRole = "";
  usersChanged = new Subject<User>();
  params: UsersService.UsersGETParams = {};
  adminParams: UsersService.UsersAdminsByUserEmailPUTParams = {};
  private currentTenant$ = new BehaviorSubject<Tenant>({
    id: env.tenant.id,
    name: env.tenant.name,
  });

  getCurrentTenant$ = this.currentTenant$.asObservable();
  constructor(
    private usersService: UsersService,
    private httpClient: HttpClient,
    private utilService: UtilService,
    private logger: LoggerService
  ) {
    this.getCurrentUser().subscribe();
  }

  AddUser(userInfo: AdminRegistrationInfo) {
    if (!MockMode) {
      return this.httpClient.post(
        "https://" + env.api.baseURL + "/api/users/admin",
        userInfo,
        {
          observe: "body",
          responseType: "json",
        }
      );
    } else {
      userInfo.isAdded = true;
      const userInfoCopy = { ...userInfo };
      delete userInfo.countries;
      delete userInfo.isAdded;
      return this.usersService.UsersAdminPOST(userInfo).pipe(
        take(1),
        map((payload) => {
          this.usersChanged.next(userInfoCopy);
          const resp = payload;
          return resp;
        })
      );
    }
  }

  UpdateUser(userInfo: UserUpdateInfo) {
    if (!MockMode) {
    } else {
      userInfo.isAdded = false;
      this.adminParams.userEmail = userInfo.email;
      const userInfoCopy = { ...userInfo };
      delete userInfo.email;
      delete userInfo.isAdded;
      delete userInfo.countries;
      this.adminParams.registrationInfo = userInfo;
      return this.usersService.UsersAdminsByUserEmailPUT(this.adminParams).pipe(
        take(1),
        map((payload) => {
          this.usersChanged.next(userInfoCopy);
          const resp = payload;
          return resp;
        })
      );
    }
  }

  DeleteUser(userInfo: User) {
    userInfo.isDeleted = true;
    return this.usersService.UsersByUserEmailDELETE(userInfo.email).pipe(
      take(1),
      map((payload) => {
        this.usersChanged.next(userInfo);
        const resp = payload;
        return resp;
      })
    );
  }

  getCurrentUser(): Observable<UserInfo> {
    return this.usersService.UsersMeGET().pipe(
      take(1),
      map((payload) => {
        if (payload) {
          var user = payload.data;
          if (user) {
            this.currentUserRole = user.role;
            this.currentUser.next(user);
          }
          return user;
        }
        return null;
      })
    );
  }

  getUsers(
    limit?: number,
    offset?: number,
    search?: string,
    jobDescription?: string[],
    country?: string[],
    sortBy?: any
  ): Observable<PagedUsers> {
    if (!MockMode) {
      // return ClientMockUtil.getAdminClients();
    } else {
      if (limit !== 99 || offset !== 99) {
        const pageSize = $("select[name=usersTable_length]").val();
        if (pageSize != limit) {
          this.params.limit = pageSize.toString();
        } else {
          this.params.limit = limit.toString();
        }

        this.params.offset = offset.toString();
      }
      this.params.search = search;
      this.params.company = jobDescription;
      this.params.country = country;
      this.params.orderBy =
        sortBy === "Job Description" ? "jobDescription" : sortBy.toLowerCase();
      this.params.role = ["admin", "superadmin", "systemadmin"];
      return this.usersService.UsersGET(this.params).map((apiPagedUsers) => {
        return {
          offset: apiPagedUsers.data.pagination.offset,
          count: apiPagedUsers.data.pagination.count,
          totalCount: apiPagedUsers.data.pagination.totalCount,
          users: apiPagedUsers.data.data.map((apiUser) => {
            return {
              name: apiUser.fullName,
              email: apiUser.email,
              company: apiUser.company,
              jobDescription: apiUser.jobDescription,
              countries:
                apiUser.adminInfo !== null
                  ? apiUser.adminInfo.managedCountries
                  : "No Data",
              managedCountries: [],
              role: apiUser.role,
              preferredLanguage: apiUser.preferredLanguage,
              country: apiUser.country,
              units: apiUser.units,
              shouldReceiveUpdates: apiUser.shouldReceiveUpdates,
            } as UserInfo;
          }),
        } as PagedUsers;
      });
    }
  }

  setCurrentTenant(tenant: Tenant) {
    if (!tenant) {
      return;
    }
    this.currentTenant$.next(tenant);
  }
}
