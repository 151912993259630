/* tslint:disable */

/**
 * System Units      bar/psi - For application use only?
 */
type CONFIGIDSYSTEMUNITS =
  'CONFIG_SYSTEM_UNIT_BAR' |
  'CONFIG_SYSTEM_UNIT_PSI';
module CONFIGIDSYSTEMUNITS {
  export const CONFIG_SYSTEM_UNIT_BAR: CONFIGIDSYSTEMUNITS = 'CONFIG_SYSTEM_UNIT_BAR';
  export const CONFIG_SYSTEM_UNIT_PSI: CONFIGIDSYSTEMUNITS = 'CONFIG_SYSTEM_UNIT_PSI';
  export function values(): CONFIGIDSYSTEMUNITS[] {
    return [
      CONFIG_SYSTEM_UNIT_BAR,
      CONFIG_SYSTEM_UNIT_PSI
    ];
  }
}

export { CONFIGIDSYSTEMUNITS }