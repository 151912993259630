/* tslint:disable */

/**
 * Action in continuous - what to do when continuous happen
 */
type CONFIGIDACTIONINCONTINUOUS =
  'CONFIG_ACTION_IN_CONTINUOUS_IGNORE' |
  'CONFIG_ACTION_IN_CONTINUOUS_TIME_ONLY';
module CONFIGIDACTIONINCONTINUOUS {
  export const CONFIG_ACTION_IN_CONTINUOUS_IGNORE: CONFIGIDACTIONINCONTINUOUS = 'CONFIG_ACTION_IN_CONTINUOUS_IGNORE';
  export const CONFIG_ACTION_IN_CONTINUOUS_TIME_ONLY: CONFIGIDACTIONINCONTINUOUS = 'CONFIG_ACTION_IN_CONTINUOUS_TIME_ONLY';
  export function values(): CONFIGIDACTIONINCONTINUOUS[] {
    return [
      CONFIG_ACTION_IN_CONTINUOUS_IGNORE,
      CONFIG_ACTION_IN_CONTINUOUS_TIME_ONLY
    ];
  }
}

export { CONFIGIDACTIONINCONTINUOUS }