var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, } from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { map as __map, filter as __filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var ConfigurationService = /** @class */ (function (_super) {
    __extends(ConfigurationService, _super);
    function ConfigurationService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Get current configurations of controller
     * @param controller_id id of controller
     * @return response
     */
    ConfigurationService.prototype.ControllersConfigurationsByControllerIdGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/configurations"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get current configurations of controller
     * @param controller_id id of controller
     * @return response
     */
    ConfigurationService.prototype.ControllersConfigurationsByControllerIdGet = function (props) {
        return this.ControllersConfigurationsByControllerIdGetResponse(props).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Set configurations for controller. Controller parameters will be validated according to firmware specifications (HLD 1.1)
     * @param params The `ConfigurationService.ControllersConfigurationsByControllerIdPostParams` containing the following parameters:
     *
     * - `parameter`:
     *
     * - `controller_id`: id of controller
     *
     * @return Configured successfuly
     */
    ConfigurationService.prototype.ControllersConfigurationsByControllerIdPostResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.parameter;
        var req = new HttpRequest("POST", this.rootUrl + ("/controllers/" + params.controllerId + "/configurations"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Set configurations for controller. Controller parameters will be validated according to firmware specifications (HLD 1.1)
     * @param params The `ConfigurationService.ControllersConfigurationsByControllerIdPostParams` containing the following parameters:
     *
     * - `parameter`:
     *
     * - `controller_id`: id of controller
     *
     * @return Configured successfuly
     */
    ConfigurationService.prototype.ControllersConfigurationsByControllerIdPost = function (params) {
        return this.ControllersConfigurationsByControllerIdPostResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get global configurations of entire system (super admin only)
     * @return Configured successfuly
     */
    ConfigurationService.prototype.SystemConfigurationsGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest("GET", this.rootUrl + "/system/configurations", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get global configurations of entire system (super admin only)
     * @return Configured successfuly
     */
    ConfigurationService.prototype.SystemConfigurationsGet = function () {
        return this.SystemConfigurationsGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Set global configurations of entire system (super admin only)
     * @param parameter
     * @return Configured successfuly
     */
    ConfigurationService.prototype.SystemConfigurationsPostResponse = function (parameter) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = parameter;
        var req = new HttpRequest("POST", this.rootUrl + "/system/configurations", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Set global configurations of entire system (super admin only)
     * @param parameter
     * @return Configured successfuly
     */
    ConfigurationService.prototype.SystemConfigurationsPost = function (parameter) {
        return this.SystemConfigurationsPostResponse(parameter).pipe(__map(function (_r) { return _r.body; }));
    };
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}(__BaseService));
export { ConfigurationService };
