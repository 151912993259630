/* tslint:disable */
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment'

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  rootUrl:  string  =  env.api.baseURL;

}

export interface ApiConfigurationInterface {
  rootUrl?: string;
  // rootUrlFuncint?: string
}
