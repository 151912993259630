import { OnInit, NgModule, OnDestroy } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MsalModule,
  MsalInterceptor,
  BroadcastService,
  MsalService,
  MsalConfig,
} from "@azure/msal-angular";
import { LogLevel, UserAgentApplication } from "msal";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Subscription, config } from "rxjs";
import { environment as env } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { LoggerService } from "ng-trio-infra";
import { Router } from "@angular/router";

// TODO: add mapping
// Mapping of resources to scopes.
// Used internally by the MSAL for automatically attaching tokens in webApi calls.
// This is required only for CORS calls
// export const protectedResourceMap: [string, string[]][] = [
//   ['https://buildtodoservice.azurewebsites.net/api/todolist', ['api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user']],
//   ['https://graph.microsoft.com/v1.0/me', ['user.read']]
// ];

// TODO: add to logger service?

let forgotPass = false;
let seesionEnd = false;
export function loggerCallback(logLevel, message, piiEnabled) {
  console.log(message);
  if (message.indexOf("AADB2C90118") > -1) {
    console.log("AADB2C90118");
    forgotPass = true;
  } else if (message.indexOf("AADB2C90077") > -1) {
    console.log("AADB2C90077");
    seesionEnd = true;
  }
}

export const protectedResourceMap: [string, string[]][] =
  env.aadb2c.protectedResourceToScopesMap;
const aadConfig = <MsalConfig>{
  clientID: env.aadb2c.clientID,
  authority:
    env.aadb2c.baseURL + "/" + env.aadb2c.tenantName + "/B2C_1A_AdminSignIn",
  redirectUri: env.aadb2c.redirectUri,
  validateAuthority: false,
  cacheLocation: "localStorage",
  // Defaults is 'redirectUri
  // postLogoutRedirectUri: env.aadb2c.redirectUri,

  // Ability to turn off default navigation to start page after login. Default is true.
  navigateToLoginRequestUrl: false,
  popUp: false,
  consentScopes: env.aadb2c.scopes,
  unprotectedResources: env.aadb2c.unprotectedResources,
  // Mapping of resources to scopes
  protectedResourceMap: protectedResourceMap,
  // logger: loggerCallback,
  correlationId: "1234",
  level: LogLevel.Error,
  piiLoggingEnabled: false,
};

@NgModule({
  declarations: [],
  imports: [CommonModule, MsalModule.forRoot(aadConfig)],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
})
export class AuthenticationModule implements OnDestroy {
  private subs: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private logService: LoggerService
  ) {
    this.logService.info(
      "AuthenticationModule-redirectUr",
      env.aadb2c.redirectUri
    );
    this.logService.info("AuthenticationModule", "ctor");

    var msalLoginFailSub = this.broadcastService.subscribe(
      "msal:loginFailure",
      (payload) => {
        var dataAsString = JSON.stringify(payload);
        this.logService.info(
          "AuthenticationModule",
          "login failure " + dataAsString
        );

        // Redirect to reset password flow if error contains AADB2C90118
        // https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-reference-policies#linking-user-flows
        if (dataAsString.indexOf("AADB2C90118") > -1) {
          this.authService.forgotPassword();
        }

        // "AADB2C90077": User does not have an existing session and request prompt parameter has a value of 'None'
        // Logout user
        if (dataAsString.indexOf("AADB2C90077") > -1) {
          this.authService.logout();
        }
      }
    );

    var msalLoginSuccessSub = this.broadcastService.subscribe(
      "msal:loginSuccess",
      (payload) => {
        this.logService.info(
          "AuthenticationModule",
          "login success " + JSON.stringify(payload)
        );
      }
    );

    var msalTokenSuccessSub = this.broadcastService.subscribe(
      "msal:acquireTokenSuccess",
      (payload) => {
        this.logService.info(
          "AuthenticationModule",
          "acquire token success " + JSON.stringify(payload)
        );
      }
    );

    var msalTokenFailSub = this.broadcastService.subscribe(
      "msal:acquireTokenFailure",
      (payload) => {
        var dataAsString = JSON.stringify(payload);

        this.logService.info(
          "AuthenticationModule",
          "acquire token failure " + JSON.stringify(payload)
        );

        // "Token renewal operation failed due to timeout" ||
        // "AADB2C90077": User does not have an existing session and request prompt parameter has a value of 'None'
        if (
          dataAsString.indexOf("timeout") > -1 ||
          dataAsString.indexOf("AADB2C90077") > -1
        ) {
          this.authService.logout();
        }
      }
    );

    this.subs.add(msalLoginFailSub);
    this.subs.add(msalLoginSuccessSub);
    this.subs.add(msalTokenSuccessSub);
    this.subs.add(msalTokenFailSub);
  }

  ngOnDestroy(): void {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
