var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, } from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { map as __map, filter as __filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UsersService = /** @class */ (function (_super) {
    __extends(UsersService, _super);
    function UsersService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Add admin/superadmin user. (authorized for superadmins only)
     * @param adminDetails
     * @return response
     */
    UsersService.prototype.UsersAdminPOSTResponse = function (adminDetails) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = adminDetails;
        var req = new HttpRequest("POST", this.rootUrl + "/users/admin", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Add admin/superadmin user. (authorized for superadmins only)
     * @param adminDetails
     * @return response
     */
    UsersService.prototype.UsersAdminPOST = function (adminDetails) {
        return this.UsersAdminPOSTResponse(adminDetails).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get info of multiple admin/superadmin users. (authorized for superadmins only)
     * @param params The `UsersService.UsersGETParams` containing the following parameters:
     *
     * - `search`:
     *
     * - `role`:
     *
     * - `orderBy`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `jobDescription`:
     *
     * - `country`:
     *
     * - `company`:
     *
     * @return response
     */
    UsersService.prototype.UsersGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        (params.role || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("role", val.toString());
        });
        if (params.orderBy != null)
            __params = __params.set("orderBy", params.orderBy.toString());
        if (params.offset != null)
            __params = __params.set("offset", params.offset.toString());
        if (params.limit != null)
            __params = __params.set("limit", params.limit.toString());
        (params.jobDescription || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("jobDescription", val.toString());
        });
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.company || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("company", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/users", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get info of multiple admin/superadmin users. (authorized for superadmins only)
     * @param params The `UsersService.UsersGETParams` containing the following parameters:
     *
     * - `search`:
     *
     * - `role`:
     *
     * - `orderBy`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `jobDescription`:
     *
     * - `country`:
     *
     * - `company`:
     *
     * @return response
     */
    UsersService.prototype.UsersGET = function (params) {
        return this.UsersGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get info about the current client
     * @return client details
     */
    UsersService.prototype.UsersMeGETResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest("GET", this.rootUrl + "/users/me", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get info about the current client
     * @return client details
     */
    UsersService.prototype.UsersMeGET = function () {
        return this.UsersMeGETResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * update details of logged in user/admin. managedCountries parameter for admin only.
     * @param registrationInfo user details
     * @return urls for interacting with request
     */
    UsersService.prototype.UsersMePUTResponse = function (registrationInfo) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = registrationInfo;
        var req = new HttpRequest("PUT", this.rootUrl + "/users/me", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * update details of logged in user/admin. managedCountries parameter for admin only.
     * @param registrationInfo user details
     * @return urls for interacting with request
     */
    UsersService.prototype.UsersMePUT = function (registrationInfo) {
        return this.UsersMePUTResponse(registrationInfo).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Modify admin details - authorized exclussively to superadmins
     * @param params The `UsersService.UsersAdminsByUserEmailPUTParams` containing the following parameters:
     *
     * - `user_email`:
     *
     * - `registrationInfo`: user details
     *
     * @return urls for interacting with request
     */
    UsersService.prototype.UsersAdminsByUserEmailPUTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.registrationInfo;
        var req = new HttpRequest("PUT", this.rootUrl + ("/users/admins/" + params.userEmail), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Modify admin details - authorized exclussively to superadmins
     * @param params The `UsersService.UsersAdminsByUserEmailPUTParams` containing the following parameters:
     *
     * - `user_email`:
     *
     * - `registrationInfo`: user details
     *
     * @return urls for interacting with request
     */
    UsersService.prototype.UsersAdminsByUserEmailPUT = function (params) {
        return this.UsersAdminsByUserEmailPUTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get info about a specific user (authorized by admin/superadmin only)
     * @param user_email
     * @return client details
     */
    UsersService.prototype.UsersByUserEmailGETResponse = function (userEmail) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest("GET", this.rootUrl + ("/users/" + userEmail), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get info about a specific user (authorized by admin/superadmin only)
     * @param user_email
     * @return client details
     */
    UsersService.prototype.UsersByUserEmailGET = function (userEmail) {
        return this.UsersByUserEmailGETResponse(userEmail).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Remove admin/superadmin user. (authorized for superadmins only)
     * @param user_email
     * @return response
     */
    UsersService.prototype.UsersByUserEmailDELETEResponse = function (userEmail) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest("DELETE", this.rootUrl + ("/users/" + userEmail), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Remove admin/superadmin user. (authorized for superadmins only)
     * @param user_email
     * @return response
     */
    UsersService.prototype.UsersByUserEmailDELETE = function (userEmail) {
        return this.UsersByUserEmailDELETEResponse(userEmail).pipe(__map(function (_r) { return _r.body; }));
    };
    UsersService.UsersAdminPOSTPath = "/users/admin";
    UsersService.UsersGETPath = "/users";
    UsersService.UsersMeGETPath = "/users/me";
    UsersService.UsersMePUTPath = "/users/me";
    UsersService.UsersAdminsByUserEmailPUTPath = "/users/admins/{user_email}";
    UsersService.UsersByUserEmailGETPath = "/users/{user_email}";
    UsersService.UsersByUserEmailDELETEPath = "/users/{user_email}";
    UsersService.ngInjectableDef = i0.defineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}(__BaseService));
export { UsersService };
