import { Injectable } from "@angular/core";
import { HistoryService, ConfigurationService } from "../../../api/services";
import {
  Pagination,
  Inline_response_200_8,
  Inline_response_200_21,
  ControllerStatusResponse,
} from "../../../api/models";
import { Observable, combineLatest, ReplaySubject, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { StatusService } from "../../../../app/api/services";
import {
  Controller,
  OfflineControllerLastSyncInfo,
  OnlineControllerLastSyncInfo,
} from "../../api-models/controller/controller";
import { map, take, catchError } from "rxjs/operators";

import { LoggerService } from "ng-trio-infra";
import { PagedControllers } from "../../api-models/controller/paged-controllers";
import { CommunicationMode, ControllerAbout } from "amiad-shared-lib";

import { ControllerSettingsResponse } from "../../api-models/controller/controller-settings-response.model";

import { ControllerUtilService } from "./controller-util.service";
import {
  ControllerResponse,
  ControllersResponse,
} from "../../api-models/controller/controller-response.model";
import { IControllerService } from "./icontroller-service";
import { IControllerFlushService } from "../flushes/icontroller-flush-service";
import { FlushCountersResponse } from "../../api-models/flush/flush-response.model";
import { DateUtilService } from "../../../core/utils/date-util.service";
import { environment as env } from "../../../../environments/environment";
import { UsersAdminService } from "../users/users.service";

const MockMode = true;
@Injectable({
  providedIn: "root",
})
export class ControllerService {
  private dateUtil: DateUtilService = new DateUtilService();
  selectedTenantId: string = env.tenant.id;

  static readonly TAG: string = "[ControllerService]";
  protected _controller: Controller;

  setController(controller: Controller): void {
    this._controller = controller;
  }

  controllers = new ReplaySubject<any>(null);
  dataController: { pagination?: Pagination; data?: Controller[] };
  constructor(
    private controllerStatusService: StatusService,
    private historyService: HistoryService,
    private controllerCongig: ConfigurationService,
    private ctrlrUtilService: ControllerUtilService,
    private logService: LoggerService,
    private usersService: UsersAdminService
  ) {
    this.usersService.getCurrentTenant$.subscribe((tenant) => {
      if (tenant) {
        this.selectedTenantId = tenant.id;
      }
    });
  }
  params: StatusService.ControllersInfoGETParams = {};
  paramsStatus: StatusService.ControllersStatusGETParams = {};

  // paramsHistory: HistoryService.ControllersHistoryAlarmsGetParams = {};
  // paramsHistoryAlarmByController: HistoryService.ControllersHistoryAlarmsByControllerIdGetParams = {};
  paramsStatusByControllerId: StatusService.ControllersStatusByControllerIdGETParams =
    {};
  // paramsHistoryFlushByController: HistoryService.ControllersHistoryFlushByControllerIdGetParams = {};
  paramsStatusAll: StatusService.ControllersStatusGETParams = {};
  paramsActiveAlarms: StatusService.ControllersActivealarmsGETParams = {};
  getAdminControllers(
    limit?: string,
    offset?: string,
    search?: string,
    filterType?: Array<any>,
    country?: string[],
    thresholdExceeded?: string,
    sortBy?: any
  ): Observable<PagedControllers> {
    if (!MockMode) {
      // return ControllerMockUtil.getAdminControllers();
    } else {
      // if (
      //   limit !== "" &&
      //   offset !== "" &&
      //   limit !== undefined &&
      //   offset !== undefined
      // ) {
      //   const pageSize = $("select[name=controllersTable_length]").val();
      //   if (pageSize != limit) {
      //     this.params.limit = pageSize.toString();
      //     this.paramsStatusAll.limit = pageSize.toString();
      //   } else {
      //     this.params.limit = limit.toString();
      //     this.paramsStatusAll.limit = limit.toString();
      //   }

      //   this.params.offset = offset.toString();
      //   this.paramsStatusAll.offset = offset;
      // }

      this.params.limit = limit;
      this.paramsStatusAll.limit = limit;

      this.params.offset = offset;
      this.paramsStatusAll.offset = offset;

      // switch (sortBy) {
      //   case "Serial Number":
      //     sortBy = "serialNumber";
      //     break;
      //   case "Filter Type":
      //     sortBy = "filterType";
      //     break;
      //   case "Connection Mode":
      //     sortBy = "connectionMode";
      //     break;
      //   case "Fw Version":
      //     sortBy = "fwVersion";
      //     break;
      //   default:
      //     sortBy = "serialNumber";
      // }

      this.params.filterType = filterType;
      this.params.country = country;
      this.params.orderBy = sortBy;
      this.params.search = search;
      this.paramsStatusAll.search = search;
      this.paramsStatusAll.filterType = filterType;
      this.paramsStatusAll.country = country;
      this.paramsStatusAll.orderBy = sortBy;
      this.paramsStatusAll.includeInfo = true;
      if (thresholdExceeded === "true") {
        this.paramsStatusAll.thresholdExceeded = true;
      } else if (thresholdExceeded === "false") {
        this.paramsStatusAll.thresholdExceeded = false;
      } else {
        delete this.paramsStatusAll.thresholdExceeded;
      }
      this.paramsStatusAll.controllerAccess = ["managed"];

      this.paramsStatusAll.tenantId = this.selectedTenantId;
      this.params.tenantId = this.selectedTenantId;

      this.controllers.next(
        this.controllerStatusService.ControllersStatusGET(this.paramsStatusAll)
      );

      return this.controllerStatusService
        .ControllersStatusGET(this.paramsStatusAll)
        .map((apiPagedCtrlrs) => {
          return {
            offset: apiPagedCtrlrs.data.pagination.offset,
            count: apiPagedCtrlrs.data.pagination.count,
            totalCount: apiPagedCtrlrs.data.pagination.totalCount,
            controllers: apiPagedCtrlrs.data.data
              // .filter(
              //   (controller) =>
              //     controller.info !== null && controller.status !== null
              // )
              .map((apiCtrlr) => {
                var controllerResp = new ControllerResponse({ data: apiCtrlr });

                return controllerResp.controller;
              }),
          } as PagedControllers;
        });
    }
  }

  getActiveAlerts(
    limit?: string,
    offset?: string,
    filterType?: Array<any>,
    country?: string[],
    alert?: Array<any>
  ): Observable<Inline_response_200_8> {
    if (!MockMode) {
      // return ControllerMockUtil.getAdminControllers();
    } else {
      if (limit !== "" && offset !== "") {
        this.paramsActiveAlarms.limit = limit;
        this.paramsActiveAlarms.offset = offset;
      }
      this.paramsActiveAlarms.filterType = filterType;
      this.paramsActiveAlarms.country = country;
      this.paramsActiveAlarms.alarmType = alert;
      this.paramsActiveAlarms.controllerAccess = ["managed"];
      this.paramsActiveAlarms.tenantId = this.selectedTenantId;
      return this.controllerStatusService.ControllersActivealarmsGET(
        this.paramsActiveAlarms
      );
    }
  }

  getControllerAbout(id: string): Observable<ControllerAbout> {
    this.paramsStatusByControllerId.controllerId = id;
    this.paramsStatusByControllerId.includeInfo = true;
    this.paramsStatusByControllerId.tenantId = this.selectedTenantId;
    var getControllerAbout$ =
      this.controllerStatusService.ControllersStatusByControllerIdGET(
        this.paramsStatusByControllerId
      );
    return getControllerAbout$.pipe(
      map((apiAbout) => {
        return {
          deviceSN: apiAbout.data.info.controllerSN,
          deviceId: apiAbout.data.controllerId,
          fwVersion: {
            number: apiAbout.data.info.version.fwVersion || null,
          },
          hwVersion: apiAbout.data.info.version.hwVersion || null,
          installDate:
            this.dateUtil.parseISOString(
              apiAbout.data.info.version.installationDate
            ) || null,
          totalFlush: apiAbout.data.status.flush.count || null,
        } as ControllerAbout;
      })
    );
  }

  getController(id: string): Observable<Controller> {
    this.paramsStatusByControllerId.controllerId = id;
    this.paramsStatusByControllerId.includeInfo = true;
    this.paramsStatusByControllerId.tenantId = this.selectedTenantId;
    const ctrlrStatusObs =
      this.controllerStatusService.ControllersStatusByControllerIdGET(
        this.paramsStatusByControllerId
      );

    const ctrlrflushCountersObs = this.getFlushCounters(id);

    const ctrlrflushCyclesObs =
      this.historyService.ControllersHistoryFlushSummaryByControllerIdGet({
        controllerId: id,
        timeSpan: "week",
        tenantId: this.selectedTenantId,
      });

    return combineLatest(
      ctrlrStatusObs,
      ctrlrflushCountersObs,
      ctrlrflushCyclesObs
    )
      .pipe(
        map((results) => ({
          status: results[0] as { data?: ControllerStatusResponse },
          flushCounter: results[1],
          flushCycles: results[2],
        }))
      )
      .map((infoStatus) => {
        const info = infoStatus.status.data.info;
        const status = infoStatus.status.data.status;
        const flushCounters = infoStatus.flushCounter.counters;
        const flushCycles = infoStatus.flushCycles.data.aggregations;
        const flushCyclesWithDate = [];
        flushCycles.forEach((element) => {
          flushCyclesWithDate.push({
            date: this.dateUtil.parseISOString(element.aggregatedFrom),
            dp: element.dp,
            manual: element.manual,
            preset: element.preset,
            interval: element.interval,
          });
        });
        const ctrlr: Controller = {
          // TODO: Extract id from ApiControllerInfo
          id: id,
          name: info.siteName,
          owner: info.owner,
          filterSN: info.filterSN,
          filterationDegree: info.filterationDegree,
          workingPressure: info.workingPressure.toString(),
          waterSource: info.waterSource,
          flowRate: info.flowRate,
          configSynced: status.isConfigurationSynced,
          batteryOrPowerSource: status.batteryLevel,
          isConnected: status.isConnected,
          controllerSN: info.controllerSN,
          communicationMode:
            status.communicationMode.toLowerCase() ===
            CommunicationMode.Online.toLowerCase()
              ? CommunicationMode.Online
              : CommunicationMode.Offline,
          activeAlarms: status.activeAlarms,
          description: info.siteName,
          filterType: info.filterType,
          operationMode: this.ctrlrUtilService.parseOperationMode(
            status.rtuAddress
          ),
          connectionStrength: status.connectionSignal,
          imgUrl: "/assets/img/controller/small-farm.jpg",
          isFlushing: status.flush.isFlushing ? true : false,
          lastSyncInfo: null,
          batteryLevel: status.batteryLevel,
          location: {
            country: info.location.country,
            longitude: info.location.longitude,
            latitude: info.location.latitude,
          },
          version: {
            installationDate: this.dateUtil.parseISOString(
              info.version.installationDate
            ),
            hwVersion: info.version.hwVersion,
            fwVersion: info.version.fwVersion,
          },
          pressure: {
            inlet: status.pressure.inlet,
            dp: status.pressure.dp,
            outlet: status.pressure.outlet,
          },
          flushCounters: {
            DP: flushCounters.DP,
            preset: flushCounters.preset,
            interval: flushCounters.interval,
            manual: flushCounters.manual,
            total: flushCounters.total,
            antiFreeze: flushCounters.antiFreeze,
            lastResetTime: flushCounters.lastResetTime,
          },
          flushCycles: flushCyclesWithDate,
          flush: {
            isFlushing: status.flush.isFlushing,
            lastFlushDate: this.dateUtil.parseISOString(status.flush.lastFlush),
            lastFlushReason: status.flush.lastFlushReason,
            dpBefore: status.flush.dpBefore,
            inletBefore: status.flush.inletBefore,
            outletBefore: status.flush.outletBefore,
            dpAfter: status.flush.dpAfter,
            total: status.flush.count,
          },
          lastCommunication: this.dateUtil.parseISOString(status.lastSeen),
          nextCommunication: this.dateUtil.parseISOString(
            status.nextConnection
          ),
          isActive: status.isActive,
          isBatteryPaused: null,
          extPaused: status.extPaused,
        };

        if (
          status.communicationMode.toLowerCase() ===
          CommunicationMode.Online.toLowerCase()
        ) {
          const lastSyncOnline: OnlineControllerLastSyncInfo = {
            inlet: status.pressure.inlet,
            dp: status.pressure.dp,
            timeFromLastFlush: status.flush.lastFlush,
            lastFlushCause: status.flush.lastFlushReason,
          };
          ctrlr.lastSyncInfo = lastSyncOnline;
        } else {
          const lastSyncOffline: OfflineControllerLastSyncInfo = {
            lastCommunication: this.dateUtil.parseISOString(status.lastSeen),
            nextCommunication: this.dateUtil.parseISOString(
              status.nextConnection
            ),
            lastReceivedDp: status.pressure.dp.toString(),
            configParamsSynced: status.isConfigurationSynced,
          };
          ctrlr.lastSyncInfo = lastSyncOffline;
        }
        return ctrlr;
      });
  }

  getControllers(withInfo: boolean = true): Observable<ControllersResponse> {
    this.logService.info(ControllerService.TAG, "Getting controllers");
    return this.controllerStatusService
      .ControllersStatusGET({
        includeInfo: withInfo,
        controllerAccess: ["managed"],
        tenantId: this.selectedTenantId,
      })
      .pipe(
        take(1),
        map((payload) => {
          return new ControllersResponse(payload);
        }),
        catchError((err) => {
          // Server Error
          return of(new ControllersResponse(err.error || null));
        })
      );
  }

  getControllerSettings(id: string): Observable<ControllerSettingsResponse> {
    const getConfigs$ = this.controllerCongig
      .ControllersConfigurationsByControllerIdGet({
        controllerId: id,
        tenantId: this.selectedTenantId,
      })
      .pipe(take(1));
    return getConfigs$.pipe(
      map((apiResponse) => {
        const getSettingsResponse = new ControllerSettingsResponse(apiResponse);
        return getSettingsResponse;
      })
    );
  }
  getControllersSummary(): Observable<Inline_response_200_21> {
    return this.historyService.ControllersHistorySummaryGet();
  }

  getControllerStatic(): Controller {
    return this._controller;
  }

  getFlushCounters(id): Observable<FlushCountersResponse> {
    this.logService.info("ControllerFlushService", "Getting flush counters..");
    var getFlushCounter$ = this.controllerStatusService
      .ControllersFlushCountersByControllerIdGET({
        tenantId: this.selectedTenantId,
        controllerId: id,
      })
      .pipe(take(1));
    return getFlushCounter$.pipe(
      map((apiResponse) => {
        var flushCountersResp = new FlushCountersResponse(apiResponse);
        return flushCountersResp;
      })
    );
  }
}
