import { Injectable, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { AuthService } from "../../../app/authentication/auth.service";
import { ILanguage } from "./ilanguage";
import { environment } from "../../../environments/environment";
import { UsersAdminService } from "../../shared/api-services/users/users.service";
import { take } from "rxjs/operators";

@Injectable()
export class TranslatorService {
  private langSubject: BehaviorSubject<ILanguage>;
  private availableLangs = environment.languages;
  private defaultLanguage = this.availableLangs[0];
  langChanged = new Subject<string>();
  // selectedTheme: string;

  private availablelangs = [
    { code: "en", text: "English" },
    { code: "he", text: "עברית" },
  ];

  private _curDirection : 'ltr' | 'rtl' = "ltr";

  constructor(
    public translateService: TranslateService,
    public usersService: UsersAdminService
  ) {
    // Set lang change subject
    this.langSubject = new BehaviorSubject<ILanguage>(this.defaultLanguage);

    // Set app default lang
    if (!translateService.getDefaultLang()) {
      translateService.setDefaultLang(this.defaultLanguage.code);
    }

    // Set app used lang
    this.usersService.getCurrentUser().subscribe((user) => {
      var selectedLangCode: string;
      if (user) {
        var lang = user.preferredLanguage;
        var selectedLang = this.getLanguage(lang);
        if (selectedLang) {
          selectedLangCode = selectedLang.code;
          this.langSubject.next(selectedLang);
        }
      }
      this.useLanguage(selectedLangCode);
    });

    // const currentLang = localStorage.getItem('lang');
    // // if (currentLang === 'en') {
    // //     this.selectedTheme = 'ltr';
    // // } else {
    // //     this.selectedTheme = 'rtl';
    // // }
    // if (!translate.getDefaultLang() && !currentLang) {
    //     translate.setDefaultLang(currentLang);
    //     localStorage.setItem('lang', currentLang);
    // }
    // // Set lang change subject
    // this.langSubject = new BehaviorSubject<ILanguage>(this.defaultLanguage);
    // this.useLanguage(currentLang);
  }

  getCurrentLanguage(): Observable<ILanguage> {
    return this.langSubject.asObservable();
  }
  useLanguage(lang: string = null) {
    this.translateService.use(lang || this.translateService.getDefaultLang());
    if (lang === "he") {
      console.log(lang);
      localStorage.setItem("lang", "he");
      const langCode = lang || this.translateService.getDefaultLang();
      const language = this.getLanguage(langCode);
      this.langSubject.next(language);
      this.translateService.use(langCode);
      this.langChanged.next("rtl");
      this._curDirection = "rtl";
    } else {
      console.log(lang);
      localStorage.setItem("lang", "en");
      const langCode = lang || this.translateService.getDefaultLang();
      const language = this.getLanguage(langCode);
      this.langSubject.next(language);
      this.translateService.use(langCode);
      this.langChanged.next("ltr");
      this._curDirection = "ltr";
    }
  }

  getCurentLang() {
    if (this.translateService.currentLang === "he") {
      return "rtl";
    } else {
      return "ltr";
    }
  }
  getAvailableLanguages() {
    return this.availablelangs;
  }

  getLanguage(langCode: string): ILanguage {
    if (langCode) {
      for (var idx = 0; idx < this.availableLangs.length; idx++) {
        var lang = this.availableLangs[idx];
        if (lang.code === langCode) {
          return this.availableLangs[idx];
        }
      }
    }
    return null;
  }

  getCurrentDirection() : 'ltr' | 'rtl' {
    return this._curDirection === 'ltr' ? 'ltr' : 'rtl';
  }

  translateAsync(
    val: string | string[],
    translateParams?: Object
  ): Observable<any> {
    return this.translateService.get(val, translateParams).pipe(take(1));
  }

  translate(val: string | string[], translateParams?: Object): string {
    return this.translateService.instant(val, translateParams);
  }
}
