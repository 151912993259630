import { Injectable } from "@angular/core";
import { Alert } from "./alert.interface";
import { Adapter } from "../controller/iadapter.interface";
import { HistoryAlertAdapterHelper } from "./history-alert-adapter-helper";

import { DateUtilService } from "../../../core/utils/date-util.service";

@Injectable()
export class HistoryAlertAdapterService implements Adapter<Alert> {
  private dateUtil: DateUtilService = new DateUtilService();

  adapt(item): Alert {
    if (item) {

      var ctrlrAlert: Alert = {
        controllerId: item.controllerId,
        title: item.type,
        description: "",
        siteName: item.siteName,
        timeReceived: this.dateUtil.parseISOString(item.time),
        isOff: item.status === "OFF",
      };

      if (item.payloads && item.payloads.length) {
        ctrlrAlert.payload = HistoryAlertAdapterHelper.GetAlertPayload(
          item.type,
          item.payloads
        );
      }

      return ctrlrAlert;
    }
    return null;
  }

  adaptMany(dtoArr: any[]): Alert[] {
    if (dtoArr) {
      return dtoArr.map((x) => this.adapt(x));
    }
    return null;
  }
}
