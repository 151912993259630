import { Injectable } from "@angular/core";
import { FlushHistoryResponse } from "../../../../../app/api/models";
import { ControllerFlush } from "amiad-shared-lib";
import { Adapter } from "../../controller/iadapter.interface";
import { DateUtilService } from "../../../../core/utils/date-util.service";

@Injectable()
export class FlushHistoryAdapterService implements Adapter<ControllerFlush[]> {
  private dateUtil: DateUtilService = new DateUtilService();

  adapt(item: FlushHistoryResponse): ControllerFlush[] {
    if (item && item.events) {
      var flushHistory: ControllerFlush[] = [];
      var evnets = item.events;
      for (var i = 0; i < evnets.length; i++) {
        var flush = evnets[i];
        flushHistory.push({
          controllerId: flush.controllerId,
          date: this.dateUtil.parseISOString(flush.time),
          dpAfter: flush.dpAfter,
          dpBefore: flush.dpBefore,
          inletBefore: flush.inletBefore,
          outletBefore: flush.outletBefore,
          reason: flush.reason,
          averageFlowRate: flush.averageFlowRate,
          lastFlushIrrigationWaterCounter:
            flush.lastFlushIrrigationWaterCounter,
        });
      }
      return flushHistory;
    }
    return null;
  }
}
