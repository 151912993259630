import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { SettingsService } from './core/settings/settings.service';
import { TranslatorService } from './core/translator/translator.service';
import { PageHeadingService } from './shared/services/page-heading.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(settings, pageHeading, translateService, httpClient) {
        this.settings = settings;
        this.pageHeading = pageHeading;
        this.translateService = translateService;
        this.httpClient = httpClient;
        //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
        this.isIframe = window !== window.parent && !window.opener;
    }
    Object.defineProperty(AppComponent.prototype, "isFixed", {
        get: function () { return this.settings.getLayoutSetting('isFixed'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isCollapsed", {
        get: function () { return this.settings.getLayoutSetting('isCollapsed'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isBoxed", {
        get: function () { return this.settings.getLayoutSetting('isBoxed'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "useFullLayout", {
        get: function () { return this.settings.getLayoutSetting('useFullLayout'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "hiddenFooter", {
        get: function () { return this.settings.getLayoutSetting('hiddenFooter'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "horizontal", {
        get: function () { return this.settings.getLayoutSetting('horizontal'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isFloat", {
        get: function () { return this.settings.getLayoutSetting('isFloat'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "offsidebarOpen", {
        get: function () { return this.settings.getLayoutSetting('offsidebarOpen'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "asideToggled", {
        get: function () { return this.settings.getLayoutSetting('asideToggled'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isCollapsedText", {
        get: function () { return this.settings.getLayoutSetting('isCollapsedText'); },
        enumerable: true,
        configurable: true
    });
    // subscription: Subscription
    // selectedTheme: string = 'ltr';
    //selectedTheme = this.translateService.useLanguage();
    AppComponent.prototype.ngOnInit = function () {
        document.addEventListener('click', function (e) {
            var target = e.target;
            if (target.tagName === 'A') {
                e.preventDefault();
            }
        });
        // this.subscription = this.translateService.langChanged
        //   .subscribe(
        //     (lang: string) => {
        //       this.selectedTheme = lang;
        //     }
        //   );
        // this.pageHeading.getPageName();
    };
    return AppComponent;
}());
export { AppComponent };
