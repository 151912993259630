import { Injector } from "@angular/core";
import { ApiControllerAdapterService } from "./api-controller-adapter.service";
import { ControllerUtilService } from "../../api-services/controllers/controller-util.service";
import { BaseResponse } from "../base-response.model";
import { Controller } from "./controller";




const injector = Injector.create({
  providers: [
    {
      provide: ApiControllerAdapterService,
      deps: [ControllerUtilService]
    },
    {
      provide: ControllerUtilService,
      deps: []
    }
  ],
});


export class ControllerResponse extends BaseResponse {
  controller: Controller;
  private adapter: ApiControllerAdapterService;
  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.adapter = injector.get(ApiControllerAdapterService);
      this.controller = this.adapter.adapt(payload.data);
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.controller ? true : false;
    return isValid;
  }
}

export class ControllersResponse extends BaseResponse {
  controllers: Controller[];
  private adapter: ApiControllerAdapterService;
  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.adapter = injector.get(ApiControllerAdapterService);
      this.controllers = this.adapter.adaptMany(payload.data.data);
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.controllers ? true : false;
    return isValid;
  }
}

export class IsControllerOwnedResponse extends BaseResponse {
  isOwned: boolean;
  filterType?: string;
  serialNumber: string;

  constructor(serial, payload) {
    super(payload);

    if (serial) {
      this.serialNumber = serial;
    }

    if (!this._errors && payload.data) {

      this.isOwned = payload.data.isOwned;

      if (payload.data.filterType) {
        this.filterType = payload.data.filterType;
      }
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.serialNumber && (typeof this.isOwned !== 'undefined') ? true : false;
    return isValid;
  }
}

export class AddControllerResponse extends BaseResponse {
  controllerId: string;
  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.controllerId = payload.data.controllerId;
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.controllerId ? true : false;
    return isValid;
  }
}

export class UpdateControllerResponse extends BaseResponse {
  controllerId: string;
  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.controllerId = payload.data.controllerId;
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.controllerId ? true : false;
    return isValid;
  }
}

export class ControllerImageResponse extends BaseResponse {
  imageId: string;
  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.imageId = payload.data.imageId;
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.imageId ? true : false;
    return isValid;
  }
}
