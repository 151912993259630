/* tslint:disable */
type Units =
  'metric' |
  'imperial';
module Units {
  export const METRIC: Units = 'metric';
  export const IMPERIAL: Units = 'imperial';
  export function values(): Units[] {
    return [
      METRIC,
      IMPERIAL
    ];
  }
}

export { Units }