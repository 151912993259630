<div class="wrapper" [ngClass]="selectedTheme">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
    <!-- sidebar-->
    <app-sidebar  id="margin-dir-aside-container" class="aside-container"></app-sidebar>
    <!-- offsidebar-->
    <app-offsidebar class="offsidebar"></app-offsidebar>
    <!-- Main section-->
    <section id="margin-dir" class="section-container">
        <!-- Page content-->
        <div class="content-wrapper pb-4">
            <router-outlet></router-outlet>
        </div>
    </section>
    <!-- Page footer-->
    <footer id="margin-dir-footer" class="footer-container" app-footer></footer>
</div>