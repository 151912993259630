import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { AuthGroup } from '../authentication/authorization.types';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(protected router: Router,
        protected authorizationService: AuthorizationService,
        protected authorizationDataService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
        return this.hasRequiredPermission(route.data['auth']);
    }


    permissions: Array<AuthGroup> ;
    protected hasRequiredPermission(authGroup: AuthGroup): Promise<boolean> | boolean {
        // If user’s permissions already retrieved from the API
        if (this.authorizationService.permissions) {
            if (this.authorizationService.permissions.includes(authGroup)) {
                return this.authorizationService.hasPermission(authGroup);
            } else {
                return this.authorizationService.hasPermission(null);
            }
        } else {
            // Otherwise, must request permissions from the API first
          //  const promise = new Promise<boolean>((resolve, reject) => {
             this.permissions = this.authorizationDataService.getPermissions();
                // this.authorizationService.initializePermissions()
                //     .then(() => {
                         if (this.permissions && this.permissions.includes(authGroup)) {
                             return (this.authorizationService.hasPermission(authGroup));
                        } else {
                            return (this.authorizationService.hasPermission(null));
                        }
                //     }).catch(() => {
                //         resolve(false);
                //     });
            // });
            // return promise;
        }
    }
}