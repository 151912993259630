/* tslint:disable */
type AggregatedOver =
  'hour' |
  'day' |
  'week';
module AggregatedOver {
  export const HOUR: AggregatedOver = 'hour';
  export const DAY: AggregatedOver = 'day';
  export const WEEK: AggregatedOver = 'week';
  export function values(): AggregatedOver[] {
    return [
      HOUR,
      DAY,
      WEEK
    ];
  }
}

export { AggregatedOver }