import { environment as env } from '../../environments/environment';
import * as i0 from "@angular/core";
/**
 * Global configuration for Api services
 */
var ApiConfiguration = /** @class */ (function () {
    function ApiConfiguration() {
        this.rootUrl = env.api.baseURL;
    }
    ApiConfiguration.ngInjectableDef = i0.defineInjectable({ factory: function ApiConfiguration_Factory() { return new ApiConfiguration(); }, token: ApiConfiguration, providedIn: "root" });
    return ApiConfiguration;
}());
export { ApiConfiguration };
