import { HistoryService, StatusService, } from "../../../api/services";
import { AlarmResponsePaged, } from "../../api-models/alarm/paged-alarms";
import { AlertsResponse, ControllerAlertsResponse, } from "../../api-models/alarm/alert-response.model";
import { take, map } from "rxjs/operators";
import { LoggerService } from "ng-trio-infra";
import { DateUtilService } from "../../../core/utils/date-util.service";
import { environment as env } from "../../../../environments/environment";
import { UsersAdminService } from "../users/users.service";
import * as i0 from "@angular/core";
import * as i1 from "ng-trio-infra";
import * as i2 from "../../../api/services/status.service";
import * as i3 from "../users/users.service";
import * as i4 from "../../../api/services/history.service";
var MockMode = true;
var AlarmService = /** @class */ (function () {
    function AlarmService(logger, apiStatusService, usersService, alarmService) {
        var _this = this;
        this.logger = logger;
        this.apiStatusService = apiStatusService;
        this.usersService = usersService;
        this.alarmService = alarmService;
        this.dateUtil = new DateUtilService();
        this.selectedTenantId = env.tenant.id;
        this.paramsActiveAlarms = {};
        this.params = {};
        this.paramsAlertReport = {};
        this.usersService.getCurrentTenant$.subscribe(function (tenant) {
            if (tenant) {
                _this.selectedTenantId = tenant.id;
            }
        });
    }
    AlarmService.prototype.getControllersAlarmsHistory = function (limit, offset, search, client, country, alarmType, from, to, sortBy) {
        var _this = this;
        if (!MockMode) {
            // return ClientMockUtil.getAdminClients();
        }
        else {
            // this.params.limit = limit.toString();
            // this.params.offset = offset.toString();
            this.params.search = search;
            this.params.deviceOwner = client;
            this.params.country = country;
            this.params.alarmType = alarmType;
            this.params.startDate = from;
            this.params.endDate = to;
            this.params.controllerAccess = ["managed"];
            this.params.tenantId = this.selectedTenantId;
            // this.params.orderBy = sortBy;
            //  this.params.role = ["user"];
            return this.alarmService
                .ControllersHistoryAlarmsGet(this.params)
                .map(function (apiPagedAlrams) {
                return {
                    alarms: apiPagedAlrams.data.events.map(function (apiAlrm) {
                        return {
                            controllerId: apiAlrm.controllerId,
                            siteName: apiAlrm.siteName,
                            type: apiAlrm.type,
                            time: _this.dateUtil.parseISOString(apiAlrm.time),
                        };
                    }),
                    status: apiPagedAlrams.status,
                    errors: apiPagedAlrams.errors,
                };
            });
        }
    };
    AlarmService.prototype.getAlarmsReport = function (from, to) {
        if (!MockMode) {
            // return ClientMockUtil.getAdminClients();
        }
        else {
            this.paramsAlertReport.startDate = from;
            this.paramsAlertReport.endDate = to;
            this.paramsAlertReport.controllerAccess = ["managed"];
            this.paramsAlertReport.tenantId = this.selectedTenantId;
            return this.alarmService
                .ControllersHistoryAlarmsSummaryGet(this.paramsAlertReport)
                .map(function (apiPagedAlrams) {
                return {
                    alarmsReports: apiPagedAlrams.data.map(function (apiAlrm) {
                        return {
                            type: apiAlrm.type,
                            percentage: apiAlrm.percentage,
                        };
                    }),
                };
            });
        }
    };
    AlarmService.prototype.getAllActiveAlerts = function () {
        var _this = this;
        this.paramsActiveAlarms.controllerAccess = ["managed"];
        this.paramsActiveAlarms.tenantId = this.selectedTenantId;
        return this.apiStatusService
            .ControllersActivealarmsGET(this.paramsActiveAlarms)
            .pipe(take(1), map(function (payload) {
            var resp = new AlertsResponse("active", payload);
            _this.logger.info("[AlertsService]", "Received all active alerts", resp);
            return resp;
        }));
    };
    AlarmService.prototype.getAllHistoryAlerts = function (from, to, search, deviceOwner, country, alarmType) {
        // from.setHours(to.getHours() - 1);
        var requestParams = {
            startDate: from,
            endDate: to,
            alarmType: alarmType,
            controllerAccess: ["managed"],
            country: country,
            deviceOwner: deviceOwner,
            search: search,
            tenantId: this.selectedTenantId,
        };
        var getHistoryAlerts$ = this.alarmService.ControllersHistoryAlarmsGet(requestParams);
        return getHistoryAlerts$.pipe(take(1), map(function (apiResponse) {
            var historyAlertsResp = new AlertsResponse("history", apiResponse);
            return historyAlertsResp;
        }));
    };
    AlarmService.prototype.getControllerActiveAlerts = function (id) {
        var _this = this;
        return this.apiStatusService
            .ControllersActivealarmsByControllerIdGET({
            controllerId: id,
            tenantId: this.selectedTenantId,
        })
            .pipe(take(1), map(function (payload) {
            var resp = new ControllerAlertsResponse("active", payload);
            _this.logger.info("[AlertsService]", "Received controller " + id + " active alerts", resp);
            return resp;
        }));
    };
    AlarmService.ngInjectableDef = i0.defineInjectable({ factory: function AlarmService_Factory() { return new AlarmService(i0.inject(i1.LoggerService), i0.inject(i2.StatusService), i0.inject(i3.UsersAdminService), i0.inject(i4.HistoryService)); }, token: AlarmService, providedIn: "root" });
    return AlarmService;
}());
export { AlarmService };
