
import { ControllerSettings } from 'amiad-shared-lib';
import { Injector } from '@angular/core';
import { ApiControllerSettingsAdapterService } from '../../api-services/controllers/settings/api-controller-settings-adapter.service';
import { BaseResponse } from '../base-response.model';


const injector = Injector.create({
  providers: [
    {
      provide: ApiControllerSettingsAdapterService,
      deps: []
    },
  ],
});

export class ControllerSettingsResponse extends BaseResponse {
  settings : ControllerSettings;

  private adapter: ApiControllerSettingsAdapterService;

  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.adapter = injector.get(ApiControllerSettingsAdapterService);
      this.settings = this.adapter.adapt(payload.data);
    }
  }

  isValid() : boolean {
    var isValid = super.isValid() && this.settings ? true : false;
    return isValid;	
	}
}