import { Injectable } from "@angular/core";
import { FlushSummaryResponse } from "../../../../../app/api/models";

import { FlushCycle } from "amiad-shared-lib";
import { Adapter } from "../../controller/iadapter.interface";
import { DateUtilService } from "../../../../core/utils/date-util.service";

@Injectable()
export class FlushCyclesAdapterService implements Adapter<FlushCycle[]> {
  private dateUtil: DateUtilService = new DateUtilService();
  adapt(item: FlushSummaryResponse): FlushCycle[] {
    if (item && item.aggregations) {
      var cycles: FlushCycle[] = [];
      var apiCycles = item.aggregations;
      for (var i = 0; i < apiCycles.length; i++) {
        var cycle = apiCycles[i];
        cycles.push({
          date: this.dateUtil.parseISOString(cycle.aggregatedFrom),
          dp: cycle.dp,
          interval: cycle.interval,
          manual: cycle.manual,
          preset: cycle.preset,
          antifreeze: cycle.antiFreeze,
        });
      }
      return cycles;
    }
    return null;
  }
}
