import { environment as env } from "../../environments/environment";

const headingMain = {
  id: "headingMain",
  text: "Monitoring",
  heading: true,
  translate: "sidebar.heading.HEADER",
};

const Dashboard = {
  id: "dashboard",
  text: "Dashboard",
  link: "/dashboard",
  icon: "icon-location-pin",
  translate: "sidebar.Dashboard",
};

const Clients = {
  id: "clients",
  text: "Clients",
  link: "/clients",
  icon: "icon-people",
  translate: "sidebar.Clients",
};

const Controllers = {
  id: "controllers",
  text: "Controllers",
  link: "/controllers",
  icon: "icon-list",
  translate: "sidebar.Controllers",
};

const Flushes = {
  id: "flushes",
  text: "Flushes",
  link: "/flushes",
  icon: "icon-refresh",
  translate: "sidebar.Flushes",
};

const Alerts = {
  id: "alerts",
  text: "Alerts",
  link: "/alerts",
  icon: "icon-bell",
  translate: "sidebar.Alerts",
};

const headingSub = {
  id: "headingSub",
  text: "Information",
  heading: true,
  translate: "sidebar.heading.Information",
};

const Reports = {
  id: "reports",
  text: "Reports",
  link: "/reports",
  icon: "icon-graph",
  translate: "sidebar.Reports",
};

const Admins = {
  id: "admins",
  text: "Admins",
  link: "/admins",
  icon: "icon-user",
  translate: "sidebar.Admins",
};

const Firmware = {
  id: "firmwareVersions",
  text: "FirmwareVersions",
  link: "/firmware",
  icon: "icon-cloud-upload",
  translate: "sidebar.FirmwareVersions",
};

const Filter = {
  id: "filterThresholds",
  text: "FilterThresholds",
  link: "/filter-threshold",
  icon: "icon-vector",
  translate: "sidebar.FilterThresholds",
};

const SystemSettings = {
  id: "systemSettings",
  text: "SystemSettings",
  link: "/system-settings",
  icon: "icon-settings",
  translate: "sidebar.SystemSettings",
};

const headingBilling = {
  id: "headingBilling",
  text: "Billing",
  heading: true,
  translate: "sidebar.Billing",
};

const Subscriptions = {
  id: "subscriptions",
  text: "Subscriptions",
  link: "/billing/subscriptions",
  icon: "icon-credit-card",
  translate: "sidebar.Subscriptions",
};

const Activations = {
  id: "activations",
  text: "Activations",
  link: "/billing/activations",
  icon: "icon-credit-card",
  translate: "sidebar.Activations",
};

const Products = {
  id: "products",
  text: "Products",
  link: "/billing/products",
  icon: "icon-credit-card",
  translate: "sidebar.Products",
};

const Tenants = {
  id: "tenants",
  text: "Tenants",
  link: "/tenants",
  icon: "fas fa-table",
  translate: "sidebar.Tenants",
};

const Transactions = {
  id: "transactions",
  text: "Transactions",
  link: "/billing/transactions",
  icon: "icon-credit-card",
  translate: "sidebar.Transactions",
};

const baseMenu = [
  headingMain,
  Dashboard,
  Clients,
  Controllers,
  Flushes,
  Alerts,
  headingSub,
  Tenants,
  Reports,
  Admins,
  Firmware,
  Filter,
  SystemSettings,
];

const billingMenu = [
  headingBilling,
  Subscriptions,
  Activations,
  Products,
  Transactions,
];

export const menu =
  env.tenant.name === "Netafim" ? baseMenu : [...baseMenu, ...billingMenu];
