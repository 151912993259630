
import { KeyValue } from '@angular/common';

import { Injector } from '@angular/core';
import { BaseResponse } from '../api-models/base-response.model';
import { StaticContentAdapterService } from './static-content-adapter.service';


const injector = Injector.create({
  providers: [
    {
      provide: StaticContentAdapterService,
      deps: []
    },
  ],
});

export class StaticContentResponse extends BaseResponse {
  content : KeyValue<string,string>[];

  private adapter: StaticContentAdapterService;

  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      this.adapter = injector.get(StaticContentAdapterService);
      this.content = this.adapter.adaptMany(payload.data);
    }
  }

  isValid() : boolean {
    var isValid = super.isValid() && this.content ? true : false;
    return isValid;	
	}
}