import {
  NgModule,
  Optional,
  SkipSelf,
  ErrorHandler,
  APP_INITIALIZER,
} from "@angular/core";

import { SettingsService } from "./settings/settings.service";
import { ThemesService } from "./themes/themes.service";
import { TranslatorService } from "./translator/translator.service";
import { MenuService } from "./menu/menu.service";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import { GlobalErrorHandler } from "./error-handler/global-error-handler";

import { AuthenticationModule } from "../authentication/authentication.module";
import { PageHeadingService } from "../shared/services/page-heading.service";
import { AuthorizationService } from "../authentication/authorization.service";
import {
  LoggerModule,
  LoggerConfig,
  LogLevel,
  LoggerService,
} from "ng-trio-infra";
import { environment as env } from "../../environments/environment";

import { StaticContentProviderService } from "../shared/services/static-content-provider-service";
import {
  StatusService,
  HistoryService,
  ControllerManagementService,
  ConfigurationService,
  UsersService,
} from "../api/services";
import { AlarmService } from "../shared/api-services/alarms/alarm.service";
import { IAlertsService } from "../shared/api-services/alarms/ialerts.service";
import { IControllerService } from "../shared/api-services/controllers/icontroller-service";
import { ApiControllerAdapterService } from "../shared/api-models/controller/api-controller-adapter.service";
import { ControllerUtilService } from "../shared/api-services/controllers/controller-util.service";
import { ControllerService } from "../shared/api-services/controllers/controller.service";
import { UsersAdminService } from "../shared/api-services/users/users.service";

export function staticContentProviderFactory(
  provider: StaticContentProviderService
) {
  return () => provider.initialLoad().toPromise();
}

export function controllerServiceFactory(
  controllerStatusService: StatusService,
  historyService: HistoryService,
  controllerCongig: ConfigurationService,
  ctrlrUtilService: ControllerUtilService,
  logService: LoggerService,
  usersService: UsersAdminService
) {
  return new ControllerService(
    controllerStatusService,
    historyService,
    controllerCongig,
    ctrlrUtilService,
    logService,
    usersService
  );
}

export function alertServiceFactory(
  logService: LoggerService,
  apiStatusService: StatusService,
  apiHistoryService: HistoryService,
  usersService: UsersAdminService
) {
  return new AlarmService(
    logService,
    apiStatusService,
    usersService,
    apiHistoryService
  );
}

const logConfig: LoggerConfig = {
  disableConsoleLogging: env.logging.disableLog,
  minAppLogLevel: env.logging.appLogLevel,
  minServerLogLevel: env.logging.serverLogLevel,
  serverLogUrl: env.logging.endpoint,
};

@NgModule({
  imports: [
    AuthenticationModule,
    // Initial Logger settings
    LoggerModule.forRoot(logConfig),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: staticContentProviderFactory,
      deps: [StaticContentProviderService],
      multi: true,
    },
    SettingsService,
    ThemesService,
    TranslatorService,
    MenuService,
    PageHeadingService,
    AuthorizationService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: IControllerService,
      useFactory: controllerServiceFactory,
      deps: [
        StatusService,
        HistoryService,
        ConfigurationService,
        ControllerUtilService,
        LoggerService,
        UsersAdminService,
      ],
    },
    {
      provide: IAlertsService,
      useFactory: alertServiceFactory,
      deps: [LoggerService, StatusService, HistoryService, UsersAdminService],
    },
  ],
  declarations: [],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
