import { HistoryAlertPayloadData, HistoryAlertPayload } from "./alert.interface";

export abstract class HistoryAlertAdapterHelper {
  public static GetAlertPayload(type: AlertPayloadType, payloads: any[]): HistoryAlertPayload {
    var val1: HistoryAlertPayloadData = null;
    var val2: HistoryAlertPayloadData = null;

    switch (type) {
      case "ALERT_ID_LOW_BATTERY":
      case "ALERT_ID_LOW_BATTERY_PAUSE":
        val1 = {
          info: 'battery_voltage',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: "mV"
        }
        break;
      case "ALERT_ID_CAPACITOR_CHARGE_FAILED":
        val1 = {
          info: 'capacitor_voltage',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: "mV"
        }
        break;
      case "ALERT_ID_HIGH_DP_ALARM_STATE":
      case "ALERT_ID_HIGH_DP_FAULT_STATE":
        val1 = {
          info: 'static_content_flush_cause_dp',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: "BAR"
        }
        break;
      case "ALERT_ID_CONTINUOUS_MODE":
      case "ALERT_ID_EXIT_CONTINUOUS_MODE":
        val1 = {
          info: 'consecutive_flushes',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
        }
        break;
      case "ALERT_ID_DFU_SUCCESS":
        val1 = {
          info: 'csv_header_systemid_fwVersion',
          value: this.isValidValue(payloads[0]) ? payloads.join('.') : null,
        }
        break;
      case 'ALERT_ID_NEGATIVE_PRESSURE_OUTLET':
        val1 = {
          info: 'current_status_outlet',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: 'BAR'
        }
        break;
      case 'ALERT_ID_NEGATIVE_PRESSURE_INLET':
        val1 = {
          info: 'current_status_inlet',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: 'BAR'
        }
        break;
      case 'ALERT_ID_NEGATIVE_PRESSURE_PISTON':
        val1 = {
          info: 'piston',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: 'BAR'
        }
        break;
      case "ALERT_ID_OUT_OF_RANGE_APP_CONNECTION_PARAMETERS":
        val1 = {
          info: 'connection_interval',
          value: this.isValidValue(payloads[0]) ? payloads[0] : null,
          unit: "ms"
        }
        val2 = {
          info: 'connection_timeout',
          value: this.isValidValue(payloads[1]) ? payloads[1] : null,
          unit: "ms"
        }
        break;
      case "ALERT_ID_LOW_DOWNSTREAM_PRESSURE":
      case "ALERT_ID_HIGH_UPSTREAM_PRESSURE":
        val1 = {
          info: 'current_status_inlet',
          value: this.isValidValue(payloads[0]) ? this.round(payloads[0]) : null,
          unit: "BAR"
        }
        val2 = {
          info: 'current_status_outlet',
          value: this.isValidValue(payloads[1]) ? this.round(payloads[1]) : null,
          unit: "BAR"
        }
        break;
      default:
        return null;
    }

    return {
      val1,
      val2
    };
  }

  private static round(value) {
    var multiplier = Math.pow(10, 2);
    return Math.round(value * multiplier) / multiplier;
  }

  private static isValidValue(value) {
    return value !== null && value !== undefined;
  }
}

export type AlertPayloadType =
  'ALERT_ID_LOW_BATTERY' |
  'ALERT_ID_LOW_BATTERY_PAUSE' |
  'ALERT_ID_HIGH_DP_ALARM_STATE' |
  'ALERT_ID_HIGH_DP_FAULT_STATE' |
  'ALERT_ID_CONTINUOUS_MODE' |
  'ALERT_ID_EXIT_CONTINUOUS_MODE' |
  // 'ALERT_ID_DFU_FAILED' |
  'ALERT_ID_DFU_SUCCESS' |
  'ALERT_ID_OUT_OF_RANGE_APP_CONNECTION_PARAMETERS' |
  // 'ALERT_ID_INLET_SENSOR_PRESSURE_READ_FAILED' |
  // 'ALERT_ID_OUTLET_SENSOR_PRESSURE_READ_FAILED' |
  'ALERT_ID_CAPACITOR_CHARGE_FAILED' |
  'ALERT_ID_LOW_DOWNSTREAM_PRESSURE' |
  'ALERT_ID_HIGH_UPSTREAM_PRESSURE' |
  'ALERT_ID_NEGATIVE_PRESSURE_OUTLET' |
  'ALERT_ID_NEGATIVE_PRESSURE_INLET' |
  'ALERT_ID_NEGATIVE_PRESSURE_PISTON';