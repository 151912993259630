import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { NavigateData } from './navigate-data';
import { environment as env } from "../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class PageHeadingService {


  /* Event for when navigation occured */
  navigated = new Subject<NavigateData>();
  private curPageData: NavigateData = { pageTitle: '' };

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) {
    // Subscribe to navigation events, in order to update the page title / header
    // received from routes data definitions
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      // this allows us to dive into the children property of the routes
      .map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
      .filter((route) => route.outlet === 'primary')
      .mergeMap((route) => route.data)
      .subscribe((event) => {
        this.curPageData = {
          pageTitle: event['title'],
          pageHeader: event['header']
        };
        // console.log("Page Data:");
        // console.dir(this.curPageData);
        this.titleService.setTitle(this.curPageData.pageTitle + ' | ' + env.tenant.name);
        this.navigated.next(this.curPageData);
      });
  }

  getRouteData() {
    return <NavigateData>{
      pageTitle: 'sidebar.' + this.curPageData.pageTitle,
      pageHeader: 'sidebar.' + this.curPageData.pageHeader
    }
  }

  getRouteTitle(): string {
    return this.curPageData.pageTitle;
  }
  getRouteHeader() {
    if (typeof (this.curPageData.pageHeader) !== "undefined") {
      return 'sidebar.' + this.curPageData.pageHeader;
    }
    return 'sidebar.' + this.curPageData.pageTitle;
  }


}
