/* tslint:disable */
type Units2 =
  'imperial' |
  'numeric';
module Units2 {
  export const IMPERIAL: Units2 = 'imperial';
  export const NUMERIC: Units2 = 'numeric';
  export function values(): Units2[] {
    return [
      IMPERIAL,
      NUMERIC
    ];
  }
}

export { Units2 }