/* tslint:disable */
type Code =
  'GENERAL,' |
  'RESOURCE_NOT_FOUND,' |
  'VALIDATION_ERROR,' |
  'UNAUTHORIZED';
module Code {
  export const GENERAL_: Code = 'GENERAL,';
  export const RESOURCE_NOT_FOUND_: Code = 'RESOURCE_NOT_FOUND,';
  export const VALIDATION_ERROR_: Code = 'VALIDATION_ERROR,';
  export const UNAUTHORIZED: Code = 'UNAUTHORIZED';
  export function values(): Code[] {
    return [
      GENERAL_,
      RESOURCE_NOT_FOUND_,
      VALIDATION_ERROR_,
      UNAUTHORIZED
    ];
  }
}

export { Code }