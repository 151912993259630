import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
var PageHeadingService = /** @class */ (function () {
    function PageHeadingService(router, activatedRoute, titleService) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.titleService = titleService;
        /* Event for when navigation occured */
        this.navigated = new Subject();
        this.curPageData = { pageTitle: '' };
        // Subscribe to navigation events, in order to update the page title / header
        // received from routes data definitions
        this.router.events
            .filter(function (event) { return event instanceof NavigationEnd; })
            .map(function () { return _this.activatedRoute; })
            // this allows us to dive into the children property of the routes
            .map(function (route) {
            while (route.firstChild)
                route = route.firstChild;
            return route;
        })
            .filter(function (route) { return route.outlet === 'primary'; })
            .mergeMap(function (route) { return route.data; })
            .subscribe(function (event) {
            _this.curPageData = {
                pageTitle: event['title'],
                pageHeader: event['header']
            };
            // console.log("Page Data:");
            // console.dir(this.curPageData);
            _this.titleService.setTitle(_this.curPageData.pageTitle + ' | ' + env.tenant.name);
            _this.navigated.next(_this.curPageData);
        });
    }
    PageHeadingService.prototype.getRouteData = function () {
        return {
            pageTitle: 'sidebar.' + this.curPageData.pageTitle,
            pageHeader: 'sidebar.' + this.curPageData.pageHeader
        };
    };
    PageHeadingService.prototype.getRouteTitle = function () {
        return this.curPageData.pageTitle;
    };
    PageHeadingService.prototype.getRouteHeader = function () {
        if (typeof (this.curPageData.pageHeader) !== "undefined") {
            return 'sidebar.' + this.curPageData.pageHeader;
        }
        return 'sidebar.' + this.curPageData.pageTitle;
    };
    PageHeadingService.ngInjectableDef = i0.defineInjectable({ factory: function PageHeadingService_Factory() { return new PageHeadingService(i0.inject(i1.Router), i0.inject(i1.ActivatedRoute), i0.inject(i2.Title)); }, token: PageHeadingService, providedIn: "root" });
    return PageHeadingService;
}());
export { PageHeadingService };
