import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { SettingsService } from '../core/settings/settings.service';
import { LangDirection } from '../core/translator/ilanguage';
import { TranslatorService } from '../core/translator/translator.service';
import { ControllersFacadeService } from '../shared/api-services/facade/controllers-facade.service';
import { UsersAdminService } from '../shared/api-services/users/users.service';
declare const collapsedSidebar: any;
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  subscription: Subscription;

  public innerWidth: any;

  selectedTheme: string = 'ltr';
  loadControllersSub: Subscription;
  constructor(
    private translateService: TranslatorService,
    private settings: SettingsService,
    private usersService: UsersAdminService,

    private controllerFacade: ControllersFacadeService,
    public getUserService: AuthService
  ) {
    this.initControllers();
  }


  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (innerWidth < 1366) {
      setTimeout(() => {
        this.settings.toggleLayoutSetting('isCollapsed');
      }, 200);
    }

    // Setup layout direction
    this.usersService.getCurrentUser()
      .subscribe(
        (user) => {
          if (user) {
            var langCode = user.preferredLanguage;
            var lang = this.translateService.getLanguage(langCode);            
            this.selectedTheme = lang.direction === LangDirection.RTL? 'rtl': 'ltr';
          } else {
            this.selectedTheme = 'ltr';
          }
          collapsedSidebar(this.selectedTheme);            
        },
      );
 
    this.subscription = this.translateService.langChanged
      .subscribe(
        (lang: string) => {
          this.selectedTheme = lang;
          collapsedSidebar(this.selectedTheme);
        }
      );
  }
  // TODO:Use resize
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.innerWidth = window.innerWidth;
  //   if (innerWidth > 1367) {
  //     setTimeout(() => {
  //       this.settings.toggleLayoutSetting('isCollapsed');
  //       }, 200);
  //   }
  // }

  private initControllers() {
    this.loadControllersSub = this.controllerFacade.getControllers(true).subscribe();
  }



}
