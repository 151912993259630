import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ControllersStateService = /** @class */ (function () {
    function ControllersStateService() {
        this.updating$ = new BehaviorSubject({ update: false, id: null });
        this.removing$ = new BehaviorSubject({ remove: false, id: null });
        this.controllers$ = new BehaviorSubject(null);
        this.controllersAll$ = new BehaviorSubject(null);
    }
    ControllersStateService.prototype.isUpdating$ = function () {
        return this.updating$.asObservable();
    };
    ControllersStateService.prototype.setUpdating = function (isUpdating, id) {
        if (id === void 0) { id = null; }
        this.updating$.next({ update: isUpdating, id: id });
    };
    ControllersStateService.prototype.getController = function (id) {
        var controllers = this.controllers$.getValue();
        if (!controllers) {
            return;
        }
        var indexOfController = controllers.findIndex(function (ctrlr) { return ctrlr.id === id; });
        if (indexOfController >= 0) {
            return controllers[indexOfController];
        }
        return null;
    };
    ControllersStateService.prototype.getControllers$ = function () {
        return this.controllers$.asObservable();
    };
    ControllersStateService.prototype.getControllersAll$ = function () {
        return this.controllersAll$.asObservable();
    };
    ControllersStateService.prototype.setControllers = function (controllers) {
        if (controllers) {
            this.controllers$.next(controllers);
        }
    };
    ControllersStateService.prototype.setControllersAll = function (controllers) {
        if (controllers) {
            this.controllersAll$.next(controllers);
        }
    };
    ControllersStateService.prototype.updateController = function (updatedController) {
        var controllers = this.controllers$.getValue();
        var indexOfUpdated = controllers.findIndex(function (ctrlr) { return ctrlr.id === updatedController.id; });
        controllers[indexOfUpdated] = updatedController;
        this.controllers$.next(controllers.slice());
    };
    ControllersStateService.prototype.resetState = function () {
        this.controllers$.next(null);
        this.controllersAll$.next(null);
    };
    ControllersStateService.ngInjectableDef = i0.defineInjectable({ factory: function ControllersStateService_Factory() { return new ControllersStateService(); }, token: ControllersStateService, providedIn: "root" });
    return ControllersStateService;
}());
export { ControllersStateService };
