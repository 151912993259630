import { Injectable } from '@angular/core';

import { CommunicationMode, OperationMode } from 'amiad-shared-lib';

@Injectable({
  providedIn: 'root'
})

export class ControllerUtilService {

  constructor() { }
  parseCommunicationMode(str: string): CommunicationMode {
    if (!str) {
      return;
    } else {
      switch (str.toLowerCase()) {
        case CommunicationMode.Online.toLowerCase():
          return CommunicationMode.Online;
        case CommunicationMode.Offline.toLowerCase():
          return CommunicationMode.Offline;
        default:
          return null;
      }
    }
  }



  parseOperationMode(rtuAddress: number): OperationMode {
    if (rtuAddress === 0) {
      return OperationMode.Master;
    } else if (rtuAddress > 0) {
      return OperationMode.Slave;
    }
    return null;
  }
}
