import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Controller } from '../../api-models/controller/controller';


@Injectable({
  providedIn: 'root'
})

export class ControllersStateService {
  private updating$ = new BehaviorSubject<{ update: boolean, id: string }>({ update: false, id: null });

  private removing$ = new BehaviorSubject<{ remove: boolean, id: string }>({ remove: false, id: null });
  private controllers$ = new BehaviorSubject<Controller[]>(null);
  private controllersAll$ = new BehaviorSubject<Controller[]>(null);
  isUpdating$() {
    return this.updating$.asObservable();
  }

  setUpdating(isUpdating: boolean, id: string = null) {
    this.updating$.next({ update: isUpdating, id: id });
  }

  getController(id: string): Controller {
    const controllers = this.controllers$.getValue();
    if (!controllers) {
      return;
    }
    const indexOfController = controllers.findIndex(ctrlr => ctrlr.id === id);
    if (indexOfController >= 0) {
      return controllers[indexOfController];
    }
    return null;
  }
  getControllers$(): Observable<Controller[]> {
    return this.controllers$.asObservable();
  }

  getControllersAll$(): Observable<Controller[]> {
    return this.controllersAll$.asObservable();
  }

  setControllers(controllers: Controller[]) {
    if (controllers) {
      this.controllers$.next(controllers);
    }
  }

  setControllersAll(controllers: Controller[]) {
    if (controllers) {
      this.controllersAll$.next(controllers);
    }
  }

  updateController(updatedController: Controller) {
    const controllers = this.controllers$.getValue();
    const indexOfUpdated = controllers.findIndex(ctrlr => ctrlr.id === updatedController.id);
    controllers[indexOfUpdated] = updatedController;
    this.controllers$.next([...controllers]);
  }

  resetState() {
    this.controllers$.next(null);
    this.controllersAll$.next(null);
  }

}
