import { Injectable } from "@angular/core";
import {
  ControllerAlert,
  HistoryAlertPayloadData,
  HistoryAlertPayload,
} from "amiad-shared-lib";
import { DateUtilService } from "../../../core/utils/date-util.service";
import {
  ControllerActiveAlarms,
  AlarmHistoryResponse,
  AlarmEntry,
} from "../../../../app/api/models";
import { Adapter } from "../controller/iadapter.interface";
import { HistoryAlertAdapterHelper } from "./history-alert-adapter-helper";

@Injectable()
export class ControllerHistoryAlertsAdapterService
  implements Adapter<ControllerAlert>
{
  private dateUtil: DateUtilService = new DateUtilService();

  adapt(item): ControllerAlert {
    if (item) {
      var ctrlrAlert: ControllerAlert = {
        controllerId: item.controllerId,
        alertKey: item.type,
        date: this.dateUtil.parseISOString(item.time),
        isOff: item.status === "OFF",
      };

      if (item.payloads && item.payloads.length) {
        ctrlrAlert.payload = HistoryAlertAdapterHelper.GetAlertPayload(
          item.type,
          item.payloads
        );
      }

      return ctrlrAlert;
    }
    return null;
  }

  adaptMany(items: AlarmEntry[]): ControllerAlert[] {
    return items.map((item) => this.adapt(item));
  }
}
