var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { BaseResponse } from '../api-models/base-response.model';
import { StaticContentAdapterService } from './static-content-adapter.service';
var injector = Injector.create({
    providers: [
        {
            provide: StaticContentAdapterService,
            deps: []
        },
    ],
});
var StaticContentResponse = /** @class */ (function (_super) {
    __extends(StaticContentResponse, _super);
    function StaticContentResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            _this.adapter = injector.get(StaticContentAdapterService);
            _this.content = _this.adapter.adaptMany(payload.data);
        }
        return _this;
    }
    StaticContentResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.content ? true : false;
        return isValid;
    };
    return StaticContentResponse;
}(BaseResponse));
export { StaticContentResponse };
