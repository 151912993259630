import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ControllersStateService } from "../state-management/controllers-state.service";

import { LoggerService } from "ng-trio-infra";
import { ControllersResponse } from "../../api-models/controller/controller-response.model";
import { concatMap, tap } from "rxjs/operators";
import { ResponseStatus } from "../../api-models/base-response.model";
import { IControllerService } from "../controllers/icontroller-service";
import { Controller } from "../../api-models/controller/controller";

@Injectable({
  providedIn: "root",
})
export class ControllersFacadeService {
  constructor(
    private logger: LoggerService,
    private state: ControllersStateService,
    private controllersService: IControllerService
  ) {}

  isUpdating$(): Observable<{ update: boolean; id: string }> {
    return this.state.isUpdating$();
  }

  getController(id: string): Controller {
    // return this.controllersService.get(id);
    return this.state.getController(id);
  }

  getControllers(withInfo: boolean = true): Observable<ControllersResponse> {
    this.logger.info("[ControllersFacadeService]", "Getting controllers..");
    return this.state.getControllersAll$().pipe(
      // tap(() => this.state.setUpdating(true)),
      concatMap((ctrlrs) => {
        if (!ctrlrs) {
          return this.controllersService.getControllers(withInfo).pipe(
            tap((resp) => {
              if (resp.isValid()) {
                var controllers = resp.controllers;
                this.state.setControllers(controllers);
                this.state.setControllersAll(controllers);
              }
            })
          );
        }
        this.logger.info(
          "[ControllersFacadeService]",
          "Controllers Exist!",
          ctrlrs
        );

        var resp: ControllersResponse = new ControllersResponse({
          status: ResponseStatus.Success,
        });
        resp.controllers = ctrlrs;
        return of(resp);
      })
      // tap(() => this.state.setUpdating(false)),
    );
  }
}
