import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { AuthService } from '../../../../app/authentication/auth.service';
import { ClientService } from '../../../../app/shared/api-services/clients/client.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    userName = "";
    constructor(public userblockService: UserblockService, private getUserService: AuthService,
        private clientService: ClientService, ) {

        this.user = {
            picture: 'assets/img/user/01.jpg'
        };
    }

    ngOnInit() {
        this.clientService.getUser().pipe(take(1)).subscribe((user) => {
            if (user) {
                this.userName = user.data.fullName;
                this.user = {
                    name: this.userName
                };
            } else {
                this.userName = 'N/A';
                this.user = {
                    name: this.userName
                };
            }
        });
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
