/* tslint:disable */
type CommunicationMode =
  'online' |
  'offline';
module CommunicationMode {
  export const ONLINE: CommunicationMode = 'online';
  export const OFFLINE: CommunicationMode = 'offline';
  export function values(): CommunicationMode[] {
    return [
      ONLINE,
      OFFLINE
    ];
  }
}

export { CommunicationMode }