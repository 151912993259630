import { Injector } from '@angular/core';
import { BaseResponse } from '../../api-models/base-response.model';
import { FlushCycle, ControllerFlush } from 'amiad-shared-lib';

import { FlushCounters } from '../../api-models/flush/flush-counter';
import { FlushCountersAdapterService } from './adaptor/flush-counters-adapter.service';
import { FlushCyclesAdapterService } from './adaptor/flush-cycles-adapter.service';
import { FlushHistoryAdapterService } from './adaptor/flush-history-adapter.service';


const injector = Injector.create({
  providers: [
    {
      provide: FlushCountersAdapterService,
      deps: []
    },
    {
      provide: FlushCyclesAdapterService,
      deps: []
    },
    {
      provide: FlushHistoryAdapterService,
      deps: []
    }

  ],
});

export class FlushCountersResponse extends BaseResponse {
  counters: FlushCounters;

  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      var adapter = injector.get(FlushCountersAdapterService);
      this.counters = adapter.adapt(payload.data);
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.counters ? true : false;
    return isValid;
  }
}


export class FlushCyclesResponse extends BaseResponse {
  cycles: FlushCycle[];

  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      var adapter = injector.get(FlushCyclesAdapterService);
      this.cycles = adapter.adapt(payload.data);
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.cycles ? true : false;
    return isValid;
  }
}

export class FlushHistoryResponse extends BaseResponse {
  flushes: ControllerFlush[];

  constructor(payload) {
    super(payload);
    if (!this._errors && payload.data) {
      var adapter = injector.get(FlushHistoryAdapterService);
      this.flushes = adapter.adapt(payload.data);
    }
  }

  isValid(): boolean {
    var isValid = super.isValid() && this.flushes ? true : false;
    return isValid;
  }
}