var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, } from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { map as __map, filter as __filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var StatusService = /** @class */ (function (_super) {
    __extends(StatusService, _super);
    function StatusService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * get full status+info of a specific controller
     * @param params The `StatusService.ControllersStatusByControllerIdGETParams` containing the following parameters:
     *
     * - `controller_id`: id of controller
     *
     * - `includeInfo`: if true, status response will contain controllers info
     *
     * @return success codes
     */
    StatusService.prototype.ControllersStatusByControllerIdGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        if (params.includeInfo != null)
            __params = __params.set("includeInfo", params.includeInfo.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/status"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get full status+info of a specific controller
     * @param params The `StatusService.ControllersStatusByControllerIdGETParams` containing the following parameters:
     *
     * - `controller_id`: id of controller
     *
     * - `includeInfo`: if true, status response will contain controllers info
     *
     * @return success codes
     */
    StatusService.prototype.ControllersStatusByControllerIdGET = function (params) {
        return this.ControllersStatusByControllerIdGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get full status for user/admin controllers
     * @param params The `StatusService.ControllersStatusGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `includeInfo`: if true, status response will contain controllers info
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return success codes
     */
    StatusService.prototype.ControllersStatusGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (params.waterSource || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("waterSource", val.toString());
        });
        if (params.thresholdExceeded != null)
            __params = __params.set("thresholdExceeded", params.thresholdExceeded.toString());
        (params.slaveNumber || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("slaveNumber", val.toString());
        });
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.orderBy != null)
            __params = __params.set("orderBy", params.orderBy.toString());
        (params.operationMode || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("operationMode", val.toString());
        });
        if (params.onlineMode != null)
            __params = __params.set("onlineMode", params.onlineMode.toString());
        if (params.offset != null)
            __params = __params.set("offset", params.offset.toString());
        if (params.limit != null)
            __params = __params.set("limit", params.limit.toString());
        if (params.isComActive != null)
            __params = __params.set("isComActive", params.isComActive.toString());
        if (params.isAlarmed != null)
            __params = __params.set("isAlarmed", params.isAlarmed.toString());
        if (params.includeInfo != null)
            __params = __params.set("includeInfo", params.includeInfo.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.filterType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("filterType", val.toString());
        });
        if (params.creationDateTo != null)
            __params = __params.set("creationDateTo", params.creationDateTo.toString());
        if (params.creationDateFrom != null)
            __params = __params.set("creationDateFrom", params.creationDateFrom.toString());
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.controllerType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/status", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get full status for user/admin controllers
     * @param params The `StatusService.ControllersStatusGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `includeInfo`: if true, status response will contain controllers info
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return success codes
     */
    StatusService.prototype.ControllersStatusGET = function (params) {
        return this.ControllersStatusGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get controller info.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersInfoByControllerIdGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/info"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get controller info.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersInfoByControllerIdGET = function (params) {
        return this.ControllersInfoByControllerIdGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get info for user/admin controllers
     * @param params The `StatusService.ControllersInfoGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return response
     */
    StatusService.prototype.ControllersInfoGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (params.waterSource || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("waterSource", val.toString());
        });
        if (params.thresholdExceeded != null)
            __params = __params.set("thresholdExceeded", params.thresholdExceeded.toString());
        (params.slaveNumber || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("slaveNumber", val.toString());
        });
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.orderBy != null)
            __params = __params.set("orderBy", params.orderBy.toString());
        (params.operationMode || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("operationMode", val.toString());
        });
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        if (params.onlineMode != null)
            __params = __params.set("onlineMode", params.onlineMode.toString());
        if (params.offset != null)
            __params = __params.set("offset", params.offset.toString());
        if (params.limit != null)
            __params = __params.set("limit", params.limit.toString());
        if (params.isComActive != null)
            __params = __params.set("isComActive", params.isComActive.toString());
        if (params.isAlarmed != null)
            __params = __params.set("isAlarmed", params.isAlarmed.toString());
        (params.filterType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("filterType", val.toString());
        });
        if (params.creationDateTo != null)
            __params = __params.set("creationDateTo", params.creationDateTo.toString());
        if (params.creationDateFrom != null)
            __params = __params.set("creationDateFrom", params.creationDateFrom.toString());
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.controllerType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/info", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get info for user/admin controllers
     * @param params The `StatusService.ControllersInfoGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return response
     */
    StatusService.prototype.ControllersInfoGET = function (params) {
        return this.ControllersInfoGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get current alarms status.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersActivealarmsByControllerIdGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/activealarms"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get current alarms status.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersActivealarmsByControllerIdGET = function (params) {
        return this.ControllersActivealarmsByControllerIdGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get current alarms status for user/admin controllers
     * @param params The `StatusService.ControllersActivealarmsGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * - `alarmType`: restricted to staticcontent/alarmtypes options
     *
     * @return response
     */
    StatusService.prototype.ControllersActivealarmsGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (params.waterSource || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("waterSource", val.toString());
        });
        if (params.thresholdExceeded != null)
            __params = __params.set("thresholdExceeded", params.thresholdExceeded.toString());
        (params.slaveNumber || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("slaveNumber", val.toString());
        });
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.orderBy != null)
            __params = __params.set("orderBy", params.orderBy.toString());
        (params.operationMode || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("operationMode", val.toString());
        });
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        if (params.onlineMode != null)
            __params = __params.set("onlineMode", params.onlineMode.toString());
        if (params.offset != null)
            __params = __params.set("offset", params.offset.toString());
        if (params.limit != null)
            __params = __params.set("limit", params.limit.toString());
        if (params.isComActive != null)
            __params = __params.set("isComActive", params.isComActive.toString());
        (params.filterType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("filterType", val.toString());
        });
        if (params.creationDateTo != null)
            __params = __params.set("creationDateTo", params.creationDateTo.toString());
        if (params.creationDateFrom != null)
            __params = __params.set("creationDateFrom", params.creationDateFrom.toString());
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.controllerType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        (params.alarmType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("alarmType", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/activealarms", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get current alarms status for user/admin controllers
     * @param params The `StatusService.ControllersActivealarmsGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * - `alarmType`: restricted to staticcontent/alarmtypes options
     *
     * @return response
     */
    StatusService.prototype.ControllersActivealarmsGET = function (params) {
        return this.ControllersActivealarmsGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get current flushing status.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersFlushByControllerIdGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/flush"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get current flushing status.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersFlushByControllerIdGET = function (params) {
        return this.ControllersFlushByControllerIdGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get current flushing status.
     * @param params The `StatusService.ControllersFlushGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return response
     */
    StatusService.prototype.ControllersFlushGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (params.waterSource || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("waterSource", val.toString());
        });
        if (params.thresholdExceeded != null)
            __params = __params.set("thresholdExceeded", params.thresholdExceeded.toString());
        (params.slaveNumber || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("slaveNumber", val.toString());
        });
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.orderBy != null)
            __params = __params.set("orderBy", params.orderBy.toString());
        (params.operationMode || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("operationMode", val.toString());
        });
        if (params.onlineMode != null)
            __params = __params.set("onlineMode", params.onlineMode.toString());
        if (params.offset != null)
            __params = __params.set("offset", params.offset.toString());
        if (params.limit != null)
            __params = __params.set("limit", params.limit.toString());
        if (params.isComActive != null)
            __params = __params.set("isComActive", params.isComActive.toString());
        if (params.isAlarmed != null)
            __params = __params.set("isAlarmed", params.isAlarmed.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.filterType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("filterType", val.toString());
        });
        if (params.creationDateTo != null)
            __params = __params.set("creationDateTo", params.creationDateTo.toString());
        if (params.creationDateFrom != null)
            __params = __params.set("creationDateFrom", params.creationDateFrom.toString());
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.controllerType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/flush", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get current flushing status.
     * @param params The `StatusService.ControllersFlushGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return response
     */
    StatusService.prototype.ControllersFlushGET = function (params) {
        return this.ControllersFlushGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get count of flushing actions.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersFlushCountersByControllerIdGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        var req = new HttpRequest("GET", this.rootUrl + ("/controllers/" + params.controllerId + "/flush/counters"), __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get count of flushing actions.
     * @param controller_id id of controller
     * @return response
     */
    StatusService.prototype.ControllersFlushCountersByControllerIdGET = function (params) {
        return this.ControllersFlushCountersByControllerIdGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Get count of flushing actions.
     * @param params The `StatusService.ControllersFlushCountersGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return response
     */
    StatusService.prototype.ControllersFlushCountersGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (params.waterSource || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("waterSource", val.toString());
        });
        if (params.thresholdExceeded != null)
            __params = __params.set("thresholdExceeded", params.thresholdExceeded.toString());
        (params.slaveNumber || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("slaveNumber", val.toString());
        });
        if (params.search != null)
            __params = __params.set("search", params.search.toString());
        if (params.orderBy != null)
            __params = __params.set("orderBy", params.orderBy.toString());
        (params.operationMode || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("operationMode", val.toString());
        });
        if (params.onlineMode != null)
            __params = __params.set("onlineMode", params.onlineMode.toString());
        if (params.offset != null)
            __params = __params.set("offset", params.offset.toString());
        if (params.limit != null)
            __params = __params.set("limit", params.limit.toString());
        if (params.isComActive != null)
            __params = __params.set("isComActive", params.isComActive.toString());
        if (params.isAlarmed != null)
            __params = __params.set("isAlarmed", params.isAlarmed.toString());
        (params.filterType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("filterType", val.toString());
        });
        if (params.creationDateTo != null)
            __params = __params.set("creationDateTo", params.creationDateTo.toString());
        if (params.creationDateFrom != null)
            __params = __params.set("creationDateFrom", params.creationDateFrom.toString());
        if (params.tenantId != null)
            __params = __params.set("tenantId", params.tenantId.toString());
        (params.country || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("country", val.toString());
        });
        (params.controllerType || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerType", val.toString());
        });
        (params.controllerAccess || []).forEach(function (val) {
            if (val != null)
                __params = __params.append("controllerAccess", val.toString());
        });
        var req = new HttpRequest("GET", this.rootUrl + "/controllers/flush/counters", __body, {
            headers: __headers,
            params: __params,
            responseType: "json",
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Get count of flushing actions.
     * @param params The `StatusService.ControllersFlushCountersGETParams` containing the following parameters:
     *
     * - `waterSource`: restricted to values by /staticcontent/watersources
     *
     * - `thresholdExceeded`:
     *
     * - `slaveNumber`:
     *
     * - `search`:
     *
     * - `orderBy`:
     *
     * - `operationMode`:
     *
     * - `onlineMode`:
     *
     * - `offset`: in multi-result responses, specifies which result to send from
     *
     * - `limit`: in multi-result responses, specifies how many results to send
     *
     * - `isComActive`:
     *
     * - `isAlarmed`:
     *
     * - `filterType`: possible values returned by /lists/filterTypes
     *
     * - `creationDateTo`: controller's creation date range (from and to should be specified together)
     *
     * - `creationDateFrom`: controller's creation date range (from and to should be specified together)
     *
     * - `country`:
     *
     * - `controllerType`:
     *
     * - `controllerAccess`: If not defined, owned and shared are filtered by default.
     *
     * @return response
     */
    StatusService.prototype.ControllersFlushCountersGET = function (params) {
        return this.ControllersFlushCountersGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    StatusService.ngInjectableDef = i0.defineInjectable({ factory: function StatusService_Factory() { return new StatusService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: StatusService, providedIn: "root" });
    return StatusService;
}(__BaseService));
export { StatusService };
