var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { BaseResponse } from '../../api-models/base-response.model';
import { FlushCountersAdapterService } from './adaptor/flush-counters-adapter.service';
import { FlushCyclesAdapterService } from './adaptor/flush-cycles-adapter.service';
import { FlushHistoryAdapterService } from './adaptor/flush-history-adapter.service';
var injector = Injector.create({
    providers: [
        {
            provide: FlushCountersAdapterService,
            deps: []
        },
        {
            provide: FlushCyclesAdapterService,
            deps: []
        },
        {
            provide: FlushHistoryAdapterService,
            deps: []
        }
    ],
});
var FlushCountersResponse = /** @class */ (function (_super) {
    __extends(FlushCountersResponse, _super);
    function FlushCountersResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            var adapter = injector.get(FlushCountersAdapterService);
            _this.counters = adapter.adapt(payload.data);
        }
        return _this;
    }
    FlushCountersResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.counters ? true : false;
        return isValid;
    };
    return FlushCountersResponse;
}(BaseResponse));
export { FlushCountersResponse };
var FlushCyclesResponse = /** @class */ (function (_super) {
    __extends(FlushCyclesResponse, _super);
    function FlushCyclesResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            var adapter = injector.get(FlushCyclesAdapterService);
            _this.cycles = adapter.adapt(payload.data);
        }
        return _this;
    }
    FlushCyclesResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.cycles ? true : false;
        return isValid;
    };
    return FlushCyclesResponse;
}(BaseResponse));
export { FlushCyclesResponse };
var FlushHistoryResponse = /** @class */ (function (_super) {
    __extends(FlushHistoryResponse, _super);
    function FlushHistoryResponse(payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            var adapter = injector.get(FlushHistoryAdapterService);
            _this.flushes = adapter.adapt(payload.data);
        }
        return _this;
    }
    FlushHistoryResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.flushes ? true : false;
        return isValid;
    };
    return FlushHistoryResponse;
}(BaseResponse));
export { FlushHistoryResponse };
