/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./userblock.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./userblock.component";
import * as i5 from "./userblock.service";
import * as i6 from "../../../authentication/auth.service";
import * as i7 from "../../../shared/api-services/clients/client.service";
var styles_UserblockComponent = [i0.styles];
var RenderType_UserblockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserblockComponent, data: {} });
export { RenderType_UserblockComponent as RenderType_UserblockComponent };
function View_UserblockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "item user-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "user-block-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "user-block-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "user-block-role"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("sidebar.WELCOME")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.user.name; _ck(_v, 6, 0, currVal_1); }); }
export function View_UserblockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserblockComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userBlockIsVisible(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserblockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-userblock", [], null, null, null, View_UserblockComponent_0, RenderType_UserblockComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserblockComponent, [i5.UserblockService, i6.AuthService, i7.ClientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserblockComponentNgFactory = i1.ɵccf("app-userblock", i4.UserblockComponent, View_UserblockComponent_Host_0, {}, {}, []);
export { UserblockComponentNgFactory as UserblockComponentNgFactory };
