/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { StrictHttpResponse as __StrictHttpResponse } from "../strict-http-response";
import { Observable as __Observable } from "rxjs";
import { map as __map, filter as __filter } from "rxjs/operators";

import { GenericSuccessResponse } from "../models/generic-success-response";
import { AdminRegistrationInfo } from "../models/admin-registration-info";
import { Inline_response_200_22 } from "../models/inline-_response-_200_22";
import { Inline_response_200_24 } from "../models/inline-_response-_200_24";
import { UserUpdateInfo } from "../models/user-update-info";
import { AdminUserUpdateInfo } from "../models/admin-user-update-info";
import { Inline_response_200_27 } from "../models/inline-_response-_200_27";
@Injectable({
  providedIn: "root",
})
class UsersService extends __BaseService {
  static readonly UsersAdminPOSTPath = "/users/admin";
  static readonly UsersGETPath = "/users";
  static readonly UsersMeGETPath = "/users/me";
  static readonly UsersMePUTPath = "/users/me";
  static readonly UsersAdminsByUserEmailPUTPath = "/users/admins/{user_email}";
  static readonly UsersByUserEmailGETPath = "/users/{user_email}";
  static readonly UsersByUserEmailDELETEPath = "/users/{user_email}";

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Add admin/superadmin user. (authorized for superadmins only)
   * @param adminDetails
   * @return response
   */
  UsersAdminPOSTResponse(
    adminDetails: AdminRegistrationInfo
  ): __Observable<__StrictHttpResponse<GenericSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = adminDetails;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/users/admin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericSuccessResponse>;
      })
    );
  }
  /**
   * Add admin/superadmin user. (authorized for superadmins only)
   * @param adminDetails
   * @return response
   */
  UsersAdminPOST(
    adminDetails: AdminRegistrationInfo
  ): __Observable<GenericSuccessResponse> {
    return this.UsersAdminPOSTResponse(adminDetails).pipe(
      __map((_r) => _r.body as GenericSuccessResponse)
    );
  }

  /**
   * Get info of multiple admin/superadmin users. (authorized for superadmins only)
   * @param params The `UsersService.UsersGETParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `role`:
   *
   * - `orderBy`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `jobDescription`:
   *
   * - `country`:
   *
   * - `company`:
   *
   * @return response
   */
  UsersGETResponse(
    params: UsersService.UsersGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_22>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    (params.role || []).forEach((val) => {
      if (val != null) __params = __params.append("role", val.toString());
    });
    if (params.orderBy != null)
      __params = __params.set("orderBy", params.orderBy.toString());
    if (params.offset != null)
      __params = __params.set("offset", params.offset.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    (params.jobDescription || []).forEach((val) => {
      if (val != null)
        __params = __params.append("jobDescription", val.toString());
    });
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.company || []).forEach((val) => {
      if (val != null) __params = __params.append("company", val.toString());
    });
    let req = new HttpRequest<any>("GET", this.rootUrl + `/users`, __body, {
      headers: __headers,
      params: __params,
      responseType: "json",
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_22>;
      })
    );
  }
  /**
   * Get info of multiple admin/superadmin users. (authorized for superadmins only)
   * @param params The `UsersService.UsersGETParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `role`:
   *
   * - `orderBy`:
   *
   * - `offset`: in multi-result responses, specifies which result to send from
   *
   * - `limit`: in multi-result responses, specifies how many results to send
   *
   * - `jobDescription`:
   *
   * - `country`:
   *
   * - `company`:
   *
   * @return response
   */
  UsersGET(
    params: UsersService.UsersGETParams
  ): __Observable<Inline_response_200_22> {
    return this.UsersGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_22)
    );
  }

  /**
   * Get info about the current client
   * @return client details
   */
  UsersMeGETResponse(): __Observable<
    __StrictHttpResponse<Inline_response_200_27>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>("GET", this.rootUrl + `/users/me`, __body, {
      headers: __headers,
      params: __params,
      responseType: "json",
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_27>;
      })
    );
  }
  /**
   * Get info about the current client
   * @return client details
   */
  UsersMeGET(): __Observable<Inline_response_200_27> {
    return this.UsersMeGETResponse().pipe(
      __map((_r) => _r.body as Inline_response_200_27)
    );
  }

  /**
   * update details of logged in user/admin. managedCountries parameter for admin only.
   * @param registrationInfo user details
   * @return urls for interacting with request
   */
  UsersMePUTResponse(
    registrationInfo: UserUpdateInfo
  ): __Observable<__StrictHttpResponse<GenericSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = registrationInfo;
    let req = new HttpRequest<any>("PUT", this.rootUrl + `/users/me`, __body, {
      headers: __headers,
      params: __params,
      responseType: "json",
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericSuccessResponse>;
      })
    );
  }
  /**
   * update details of logged in user/admin. managedCountries parameter for admin only.
   * @param registrationInfo user details
   * @return urls for interacting with request
   */
  UsersMePUT(
    registrationInfo: UserUpdateInfo
  ): __Observable<GenericSuccessResponse> {
    return this.UsersMePUTResponse(registrationInfo).pipe(
      __map((_r) => _r.body as GenericSuccessResponse)
    );
  }

  /**
   * Modify admin details - authorized exclussively to superadmins
   * @param params The `UsersService.UsersAdminsByUserEmailPUTParams` containing the following parameters:
   *
   * - `user_email`:
   *
   * - `registrationInfo`: user details
   *
   * @return urls for interacting with request
   */
  UsersAdminsByUserEmailPUTResponse(
    params: UsersService.UsersAdminsByUserEmailPUTParams
  ): __Observable<__StrictHttpResponse<GenericSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.registrationInfo;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/users/admins/${params.userEmail}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericSuccessResponse>;
      })
    );
  }
  /**
   * Modify admin details - authorized exclussively to superadmins
   * @param params The `UsersService.UsersAdminsByUserEmailPUTParams` containing the following parameters:
   *
   * - `user_email`:
   *
   * - `registrationInfo`: user details
   *
   * @return urls for interacting with request
   */
  UsersAdminsByUserEmailPUT(
    params: UsersService.UsersAdminsByUserEmailPUTParams
  ): __Observable<GenericSuccessResponse> {
    return this.UsersAdminsByUserEmailPUTResponse(params).pipe(
      __map((_r) => _r.body as GenericSuccessResponse)
    );
  }

  /**
   * Get info about a specific user (authorized by admin/superadmin only)
   * @param user_email
   * @return client details
   */
  UsersByUserEmailGETResponse(
    userEmail: string
  ): __Observable<__StrictHttpResponse<Inline_response_200_24>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/users/${userEmail}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_24>;
      })
    );
  }
  /**
   * Get info about a specific user (authorized by admin/superadmin only)
   * @param user_email
   * @return client details
   */
  UsersByUserEmailGET(userEmail: string): __Observable<Inline_response_200_24> {
    return this.UsersByUserEmailGETResponse(userEmail).pipe(
      __map((_r) => _r.body as Inline_response_200_24)
    );
  }

  /**
   * Remove admin/superadmin user. (authorized for superadmins only)
   * @param user_email
   * @return response
   */
  UsersByUserEmailDELETEResponse(
    userEmail: string
  ): __Observable<__StrictHttpResponse<GenericSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/users/${userEmail}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericSuccessResponse>;
      })
    );
  }
  
  /**
   * Remove admin/superadmin user. (authorized for superadmins only)
   * @param user_email
   * @return response
   */
  UsersByUserEmailDELETE(
    userEmail: string
  ): __Observable<GenericSuccessResponse> {
    return this.UsersByUserEmailDELETEResponse(userEmail).pipe(
      __map((_r) => _r.body as GenericSuccessResponse)
    );
  }
}

module UsersService {
  /**
   * Parameters for UsersGET
   */
  export interface UsersGETParams {
    search?: string;
    role?: Array<"user" | "admin" | "superadmin" | "systemadmin">;
    orderBy?: "name" | "jobDescription" | "company" | "country";

    /**
     * in multi-result responses, specifies which result to send from
     */
    offset?: string;

    /**
     * in multi-result responses, specifies how many results to send
     */
    limit?: string;
    jobDescription?: Array<string>;
    country?: Array<string>;
    company?: Array<string>;
  }

  /**
   * Parameters for UsersAdminsByUserEmailPUT
   */
  export interface UsersAdminsByUserEmailPUTParams {
    userEmail?: string;

    /**
     * user details
     */
    registrationInfo?: AdminUserUpdateInfo;
  }
}

export { UsersService };
