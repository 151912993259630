import { ControllerUtilService } from "../../api-services/controllers/controller-util.service";
import { DateUtilService } from "../../../core/utils/date-util.service";
import * as i0 from "@angular/core";
import * as i1 from "../../api-services/controllers/controller-util.service";
var ApiControllerAdapterService = /** @class */ (function () {
    function ApiControllerAdapterService(ctrlrUtilService) {
        this.ctrlrUtilService = ctrlrUtilService;
        this.dateUtil = new DateUtilService();
    }
    ApiControllerAdapterService.prototype.adapt = function (item) {
        if (!item.status && !item.info) {
            return item;
        }
        var controller = {
            id: item.controllerId || null,
            communicationMode: null,
            name: null,
            owner: null,
            imgUrl: null,
            connectionStrength: null,
            isConnected: null,
            filterType: null,
            operationMode: null,
            configSynced: null,
            flush: null,
            lastCommunication: null,
            nextCommunication: null,
            pressure: {
                dp: null,
                inlet: null,
                outlet: null,
            },
            version: null,
            activeAlarms: null,
            controllerSN: null,
            filterSN: null,
            filterationDegree: null,
            flowRate: null,
            location: null,
            waterSource: null,
            workingPressure: null,
            batteryOrPowerSource: null,
            isFlushing: null,
            lastSyncInfo: null,
            flushCounters: null,
            batteryLevel: null,
            isBatteryPaused: null,
            isActive: null,
            description: null,
            extPaused: null,
            masterKey: null,
        };
        if (item.info) {
            var info = item.info;
            controller.name = info.siteName;
            controller.owner = info.owner;
            controller.imgUrl = info.image;
            controller.filterType = info.filterType;
            controller.masterKey = info.masterKey;
            // controller.operationMode = this.ctrlrUtilService.parseOperationMode(info.operationMode);
            controller.version = {
                fwVersion: info.version.fwVersion,
                hwVersion: info.version.hwVersion,
                installationDate: this.dateUtil.parseISOString(info.version.installationDate),
            };
            controller.controllerSN = info.controllerSN;
            controller.filterSN = info.filterSN;
            controller.filterationDegree = info.filterationDegree;
            controller.flowRate = info.flowRate;
            if (info.location) {
                controller.location = {
                    country: info.location.country,
                    latitude: info.location.latitude,
                    longitude: info.location.longitude,
                };
            }
            (controller.waterSource = info.waterSource),
                (controller.workingPressure = info.workingPressure.toString());
        }
        if (item.status) {
            var status = item.status;
            controller.communicationMode =
                this.ctrlrUtilService.parseCommunicationMode(status.communicationMode);
            controller.operationMode = this.ctrlrUtilService.parseOperationMode(status.rtuAddress);
            controller.connectionStrength = status.connectionSignal;
            controller.isConnected = status.isConnected;
            controller.configSynced = status.isConfigurationSynced;
            controller.isActive = status.isActive;
            controller.extPaused = status.extPaused;
            if (status.flush) {
                controller.flush = {
                    isFlushing: status.flush.isFlushing,
                    lastFlushDate: this.dateUtil.parseISOString(status.flush.lastFlush),
                    lastFlushReason: status.flush
                        .lastFlushReason /*this.ctrlrUtilService.parseFlushReason(status.flush.lastFlushReason)*/,
                    dpAfter: status.flush.dpAfter,
                    dpBefore: status.flush.dpBefore,
                    inletBefore: status.flush.inletBefore,
                    outletBefore: status.flush.outletBefore,
                    total: status.flush.count,
                    lastIrrigationCounter: status.flush.lastIrrigationCounter,
                    irrigationTotal: status.flush.irrigationTotal,
                    lastIrrigationRate: status.flush.lastIrrigationRate,
                    totalFlushWaterCounter: status.flush.totalFlushWaterCounter
                };
                controller.lastCommunication = this.dateUtil.parseISOString(status.lastSeen);
                controller.nextCommunication = this.dateUtil.parseISOString(status.nextConnection);
                controller.batteryOrPowerSource = status.batteryLevel;
            }
            var dp = null;
            var inlet = null;
            var outlet = null;
            if (status.pressure) {
                dp = status.pressure.dp ? status.pressure.dp : status.pressure.dp;
                inlet = status.pressure.inlet
                    ? status.pressure.inlet
                    : status.pressure.inlet;
                outlet = status.pressure.outlet
                    ? status.pressure.outlet
                    : status.pressure.outlet;
            }
            controller.pressure = {
                dp: dp,
                inlet: inlet,
                outlet: outlet,
            };
            controller.activeAlarms = status.activeAlarms;
        }
        // console.dir(controller);
        return controller;
        // return item as Controller;
    };
    ApiControllerAdapterService.prototype.adaptMany = function (items) {
        var _this = this;
        return items.map(function (item) { return _this.adapt(item); });
    };
    ApiControllerAdapterService.ngInjectableDef = i0.defineInjectable({ factory: function ApiControllerAdapterService_Factory() { return new ApiControllerAdapterService(i0.inject(i1.ControllerUtilService)); }, token: ApiControllerAdapterService, providedIn: "root" });
    return ApiControllerAdapterService;
}());
export { ApiControllerAdapterService };
