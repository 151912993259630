var BaseResponse = /** @class */ (function () {
    function BaseResponse(payload) {
        var _this = this;
        if (payload && payload.status &&
            (payload.status === ResponseStatus.Success ||
                payload.status.result.toLowerCase() === 'success')) {
            this._status = ResponseStatus.Success;
        }
        else {
            this._status = ResponseStatus.Failure;
            if (payload && payload.errors && payload.errors.length) {
                this._errors = [];
                payload.errors.forEach(function (err) {
                    _this._errors.push({
                        code: ErrorCode.General,
                        // TODO: Parse error with metadata (err.metadata)
                        message: err.description
                    });
                });
            }
        }
    }
    Object.defineProperty(BaseResponse.prototype, "status", {
        get: function () {
            return this._status === ResponseStatus.Success ? ResponseStatus.Success : ResponseStatus.Failure;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseResponse.prototype, "errors", {
        get: function () {
            if (this._errors) {
                return this._errors.slice(0);
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    BaseResponse.prototype.isValid = function () {
        return this._status === ResponseStatus.Success && !this._errors;
    };
    return BaseResponse;
}());
export { BaseResponse };
// export interface BaseResponse {
// 	status: ResponseStatus;
// 	errors?: ResponseError[];
// }
export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus["Success"] = "success";
    ResponseStatus["Failure"] = "fail";
})(ResponseStatus || (ResponseStatus = {}));
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["General"] = 0] = "General";
    ErrorCode[ErrorCode["NotFound"] = 1] = "NotFound";
})(ErrorCode || (ErrorCode = {}));
// export interface IBaseResponse {
// 	status: string;
// 	errors?: [];
// }
