/* tslint:disable */
type Role = "admin" | "superadmin" | "systemadmin" | "production";
module Role {
  export const ADMIN: Role = "admin";
  export const SUPERADMIN: Role = "superadmin";
  export const PRODUCTION: Role = "production";
  export const SYSTEMADMIN: Role = "systemadmin";

  export function values(): Role[] {
    return [ADMIN, SUPERADMIN, SYSTEMADMIN, PRODUCTION];
  }
}

export { Role };
