import { Injectable } from "@angular/core";
import { ClientMockUtil } from "./client-mock-util";
import { UsersService } from "../../../api/services";

import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { User } from "../../../../app/routes/users/users/user.model";
import { PagedUsers } from "../../api-models/user/paged-user";
import { UserInfo } from "../../api-models/user/user-info";
import { LoggerService } from "ng-trio-infra";
import { map, share, take } from "rxjs/operators";

const MockMode = true;
@Injectable({
  providedIn: "root",
})
export class ClientService {
  private readonly TAG = "ClientService";
  constructor(
    private logger: LoggerService,
    private clientService: UsersService,
    private http: HttpClient
  ) {}

  params: UsersService.UsersGETParams = {};

  user: UserInfo;
  getAdminClients(
    limit?: string,
    offset?: string,
    search?: string,
    jobDescription?: string[],
    country?: string[],
    sortBy: UsersService.UsersGETParams["orderBy"] = "name"
  ): Observable<PagedUsers> {
    if (!MockMode) {
      // return ClientMockUtil.getAdminClients();
    } else {
      // if (limit !== 99 || offset !== 99) {
      //   const pageSize = $("select[name=clientsTable_length]").val();
      //   if (pageSize != limit) {
      //     this.params.limit = pageSize.toString();
      //   } else {
      //     this.params.limit = limit.toString();
      //   }

      //   this.params.offset = offset.toString();
      // }

      this.params.limit = limit;
      this.params.offset = offset;

      this.params.search = search;
      this.params.jobDescription = jobDescription;
      this.params.country = country;
      this.params.orderBy = sortBy;
      // sortBy === "Job Description" ? "jobDescription" : sortBy.toLowerCase();

      this.params.role = ["user"];
      return this.clientService.UsersGET(this.params).map((apiPagedUsers) => {
        return {
          offset: apiPagedUsers.data.pagination.offset,
          count: apiPagedUsers.data.pagination.count,
          totalCount: apiPagedUsers.data.pagination.totalCount,
          users: apiPagedUsers.data.data.map((apiUser) => {
            return {
              name: apiUser.fullName,
              email: apiUser.email,
              company: apiUser.company,
              country: apiUser.country,
              jobDescription: apiUser.jobDescription,
              shouldReceiveUpdates: apiUser.shouldReceiveUpdates,
            } as UserInfo;
          }),
        } as PagedUsers;
      });
    }
  }

  getAdminClientsList(): Observable<PagedUsers> {
    if (!MockMode) {
    } else {
      this.params.role = ["user"]; //["client"]
      return this.clientService.UsersGET(this.params).map((apiPagedUsers) => {
        return {
          users: apiPagedUsers.data.data.map((apiUser) => {
            return {
              name: apiUser.fullName,
              email: apiUser.email,
            } as UserInfo;
          }),
        } as PagedUsers;
      });
    }
  }

  getAdminUserData(): Observable<UserInfo> {
    if (!MockMode) {
    } else {
      return this.clientService.UsersMeGET().map((apiAdminUser) => {
        return {
          countries: apiAdminUser.data.adminInfo.managedCountries,
        } as UserInfo;
      });
    }
  }

  getAdminUserInfo(): Observable<UserInfo> {
    if (!MockMode) {
    } else {
      return this.clientService.UsersMeGET().map((apiAdminUser) => {
        return {
          user: apiAdminUser.data,
        } as UserInfo;
      });
    }
  }

  getUser(): Observable<UserInfo> {
    this.logger.info(this.TAG, "Trying to get user...");
    if (this.user) {
      return of(this.user).pipe(take(1));
    }

    return this.loadUserFromAPI().pipe(
      map((res) => {
        this.user = res;
        return this.user;
      }),
      share()
    );
  }

  patchUser(updatedUser: UserInfo) {
    if (this.user && this.user.data && updatedUser) {
      const userData = this.user.data;
      const userProps = Object.keys(userData);
      for (let i = 0; i < userProps.length; i++) {
        const prop = userProps[i]; // user object key
        const newPropValue = updatedUser[prop];
        if (newPropValue !== undefined && userData[prop] !== newPropValue) {
          userData[prop] = newPropValue;
        }
      }
    }
  }

  private loadUserFromAPI(): Observable<UserInfo> {
    // this.logger.info(this.TAG, 'Loading User from API');
    const getUser$ = this.clientService.UsersMeGET();
    return getUser$.pipe(
      map((payload) => {
        return payload as UserInfo;
      })
    );
  }
}
