import * as i0 from "@angular/core";
var ErrorService = /** @class */ (function () {
    function ErrorService() {
    }
    ErrorService.prototype.getClientMessage = function (error) {
        if (!navigator.onLine) {
            return 'No Internet Connection';
        }
        return error.message ? error.message : error.toString();
    };
    ErrorService.prototype.getClientStack = function (error) {
        return error.stack;
    };
    ErrorService.prototype.getServerMessage = function (error) {
        return error.message;
    };
    ErrorService.prototype.getServerStack = function (error) {
        // handle stack trace
        return 'stack';
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
