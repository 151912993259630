import { TenantName } from "../app/shared/api-models/tenants/tenant.interface";
import { ILanguage, LangDirection } from "../app/core/translator/ilanguage";

export const netafimConfigs: IEnvConfigs = {
  prod: {
    tenantId: "netafim",
    tenantName: "Netafim",
    b2c: {
      name: "alphadiscnetafim",
      clientId: "1699d330-bba3-45a6-976e-e8d82263a56f",
      redirectUri: "https://backoffice.alphadisc.netafim.com/",
      scopes: [
        "https://alphadiscnetafim.onmicrosoft.com/api/user_impersonation",
      ],
    },
  },
  staging: {
    tenantId: "stagetenant",
    tenantName: "Netafim",
    b2c: {
      name: "stagetenant",
      clientId: "99b94519-8408-4766-bc80-4ed5e48173a7",
      redirectUri: "https://adixnetafimbostaging.z6.web.core.windows.net/",
      scopes: ["https://stagetenant.onmicrosoft.com/api/user_impersonation"],
    },
  },
};

export const amiadConfigs: IEnvConfigs = {
  prod: {
    tenantId: "amiad",
    tenantName: "Amiad",
    b2c: {
      name: "amiadadix",
      clientId: "55c4d8f5-9fdb-4730-a312-856cdbe75560",
      redirectUri: "https://adixadmin.amiad.com/",
      scopes: ["https://amiadadix.onmicrosoft.com/api/user_impersonation"],
    },
  },
  staging: {
    tenantId: "amiad",
    tenantName: "Amiad",
    b2c: {
      name: "adixstageb2c",
      clientId: "fabc0f13-0216-4a01-aef8-fc7596df0eff",
      redirectUri: "https://adixbackofficestaging.z22.web.core.windows.net",
      scopes: ["https://adixstageb2c.onmicrosoft.com/api/user_impersonation"],
    },
  },
};

export const sharedConfigs: IEnvSharedConfigs = {
  b2c: {
    signInSignUpPolicy: "b2c_1a_adminsignin",
    resetPasswordPolicy: "b2c_1a_adminpasswordreset",
    changePasswordPolicy: "b2c_1a_adminpasswordedit",
  },
  // Production APIs
  prodApi: {
    baseURL: "https://adixapiprod.azure-api.net/adix113func",
    secondaryURL: "https://adix113funcint.azurewebsites.net/api",
    billingURL: "https://adixapiprod.azure-api.net/adix-billing-prod-func",
  },

  // Staging APIs
  stageApi: {
    baseURL: "https://adixapistage.azure-api.net/adixstagefunc",
    secondaryURL: "https://adixstagefuncna.azurewebsites.net/api",
    billingURL: "https://adixapistage.azure-api.net/adixbillingfunc",
  },

  languages: [
    {
      code: "en",
      name: "English",
      displayName: "English",
      iconPath: "./assets/img/flags/US.png",
      direction: LangDirection.LTR,
    },
    {
      code: "he",
      name: "Hebrew",
      displayName: "עברית",
      iconPath: "./assets/img/flags/IL.png",
      direction: LangDirection.RTL,
    },
  ],

  googleMapApi: {
    apikey: "AIzaSyDhyrvdzDkwz2mQWxWTISxV2YqLeQP9rls",
  },
};

interface IEnvConfigs {
  prod: {
    tenantId: "amiad" | "stagetenant" | "netafim";
    tenantName: TenantName;
    b2c: IEnvB2cConfigs;
  };
  staging: {
    tenantId: "amiad" | "stagetenant" | "netafim";
    tenantName: TenantName;
    b2c: IEnvB2cConfigs;
  };
}

interface IEnvB2cConfigs {
  name: string;
  clientId: string;
  redirectUri: string;
  scopes: string[];
}

interface IEnvSharedConfigs {
  b2c: {
    signInSignUpPolicy: string;
    resetPasswordPolicy: string;
    changePasswordPolicy: string;
  };
  prodApi: {
    baseURL: string;
    secondaryURL: string;
    billingURL: string;
  };

  stageApi: {
    baseURL: string;
    secondaryURL: string;
    billingURL: string;
  };
  languages: ILanguage[];
  googleMapApi: {
    apikey: string;
  };
}
