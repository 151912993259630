/* tslint:disable */

/**
 * AIN1 Sensor Type - What kind of sensors -- > units
 */
type CONFIGIDAIN1SENSOR =
  'CONFIG_AIN_SENSOR_TEMP' |
  'CONFIG_AIN_SENSOR_HUMIDITY';
module CONFIGIDAIN1SENSOR {
  export const CONFIG_AIN_SENSOR_TEMP: CONFIGIDAIN1SENSOR = 'CONFIG_AIN_SENSOR_TEMP';
  export const CONFIG_AIN_SENSOR_HUMIDITY: CONFIGIDAIN1SENSOR = 'CONFIG_AIN_SENSOR_HUMIDITY';
  export function values(): CONFIGIDAIN1SENSOR[] {
    return [
      CONFIG_AIN_SENSOR_TEMP,
      CONFIG_AIN_SENSOR_HUMIDITY
    ];
  }
}

export { CONFIGIDAIN1SENSOR }