/* tslint:disable */

/**
 * AIN2 Input Type
 */
type CONFIGIDAIN2TYPE =
  'CONFIG_AIN_TYPE_0_10' |
  'CONFIG_AIN_TYPE_4_20';
module CONFIGIDAIN2TYPE {
  export const CONFIG_AIN_TYPE_0_10: CONFIGIDAIN2TYPE = 'CONFIG_AIN_TYPE_0_10';
  export const CONFIG_AIN_TYPE_4_20: CONFIGIDAIN2TYPE = 'CONFIG_AIN_TYPE_4_20';
  export function values(): CONFIGIDAIN2TYPE[] {
    return [
      CONFIG_AIN_TYPE_0_10,
      CONFIG_AIN_TYPE_4_20
    ];
  }
}

export { CONFIGIDAIN2TYPE }