import { LogLevel } from "ng-trio-infra";
import { netafimConfigs, sharedConfigs } from "./env.config";
import { Ienvironment } from "./ienvironment";

const env = netafimConfigs.prod;
const shared = sharedConfigs;
const api = shared.prodApi;

/* Prod Env */
export const environment: Ienvironment = {
  production: true,
  tenant: {
    id: env.tenantId,
    name: env.tenantName,
  },

  aadb2c: {
    baseURL: "https://" + env.b2c.name + ".b2clogin.com/tfp",
    tenantName: env.b2c.name + ".onmicrosoft.com",
    clientID: env.b2c.clientId,
    signInSignUpPolicy: shared.b2c.signInSignUpPolicy,
    resetPasswordPolicy: shared.b2c.resetPasswordPolicy,
    changePasswordPolicy: shared.b2c.changePasswordPolicy,
    redirectUri: env.b2c.redirectUri,
    scopes: env.b2c.scopes,
    protectedResourceToScopesMap: [
      [api.baseURL, env.b2c.scopes],
      [api.billingURL, env.b2c.scopes],
    ],
    unprotectedResources: ["/assets/i18n/he.json", "/assets/i18n/en.json"],
  },

  api: api,
  
  languages: shared.languages,

  googleMapApi: shared.googleMapApi,
  logging: {
    appLogLevel: LogLevel.DEBUG,
    serverLogLevel: LogLevel.ERROR,
    disableLog: false,
    endpoint: "",
  },
};
