var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { BaseResponse } from '../base-response.model';
import { AlertAdapterService } from './alert-adapter.service';
import { ControllerActiveAlertsAdapterService } from './controller-active-alerts-adapter.service';
import { ControllerHistoryAlertsAdapterService } from './controller-history-alerts-adapter.service';
import { ActiveAlertAdapterService } from './active-alert-adapter.service';
import { HistoryAlertAdapterService } from './history-alert-adapter.service';
var injector = Injector.create({
    providers: [
        {
            provide: ActiveAlertAdapterService,
            deps: []
        },
        {
            provide: HistoryAlertAdapterService,
            deps: []
        },
        {
            provide: AlertAdapterService,
            deps: []
        },
        {
            provide: ControllerActiveAlertsAdapterService,
            deps: []
        },
        {
            provide: ControllerHistoryAlertsAdapterService,
            deps: []
        }
    ],
});
// All user controllers alerts
var AlertsResponse = /** @class */ (function (_super) {
    __extends(AlertsResponse, _super);
    function AlertsResponse(type, payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            // var adapter = injector.get(AlertAdapterService);
            // this.alerts = adapter.adaptMany(payload.data.data)
            if (type === 'active') {
                // adapt active alerts
                var activeAdapter = injector.get(ActiveAlertAdapterService);
                var payloadToAdapt = payload.data.data || [];
                _this.alerts = activeAdapter.adaptMany(payloadToAdapt);
            }
            else {
                // adapt history alerts
                var historyAdapter = injector.get(HistoryAlertAdapterService);
                var payloadToAdapt = payload.data && payload.data.events ? payload.data.events : [];
                _this.alerts = historyAdapter.adaptMany(payloadToAdapt);
            }
        }
        return _this;
    }
    AlertsResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.alerts ? true : false;
        return isValid;
    };
    return AlertsResponse;
}(BaseResponse));
export { AlertsResponse };
// A single controller alerts
var ControllerAlertsResponse = /** @class */ (function (_super) {
    __extends(ControllerAlertsResponse, _super);
    function ControllerAlertsResponse(type, payload) {
        var _this = _super.call(this, payload) || this;
        if (!_this._errors && payload.data) {
            if (type === 'active') {
                // adapt active alerts
                var activeAdapter = injector.get(ControllerActiveAlertsAdapterService);
                _this.alerts = activeAdapter.adaptMany(payload.data.data);
            }
            else {
                // adapt history alerts
                var historyAdapter = injector.get(ControllerHistoryAlertsAdapterService);
                _this.alerts = historyAdapter.adaptMany(payload.data.events);
            }
        }
        return _this;
    }
    ControllerAlertsResponse.prototype.isValid = function () {
        var isValid = _super.prototype.isValid.call(this) && this.alerts ? true : false;
        return isValid;
    };
    return ControllerAlertsResponse;
}(BaseResponse));
export { ControllerAlertsResponse };
