import { of } from "rxjs";
import { ControllersStateService } from "../state-management/controllers-state.service";
import { LoggerService } from "ng-trio-infra";
import { ControllersResponse } from "../../api-models/controller/controller-response.model";
import { concatMap, tap } from "rxjs/operators";
import { ResponseStatus } from "../../api-models/base-response.model";
import { IControllerService } from "../controllers/icontroller-service";
import * as i0 from "@angular/core";
import * as i1 from "ng-trio-infra";
import * as i2 from "../state-management/controllers-state.service";
import * as i3 from "../controllers/icontroller-service";
var ControllersFacadeService = /** @class */ (function () {
    function ControllersFacadeService(logger, state, controllersService) {
        this.logger = logger;
        this.state = state;
        this.controllersService = controllersService;
    }
    ControllersFacadeService.prototype.isUpdating$ = function () {
        return this.state.isUpdating$();
    };
    ControllersFacadeService.prototype.getController = function (id) {
        // return this.controllersService.get(id);
        return this.state.getController(id);
    };
    ControllersFacadeService.prototype.getControllers = function (withInfo) {
        var _this = this;
        if (withInfo === void 0) { withInfo = true; }
        this.logger.info("[ControllersFacadeService]", "Getting controllers..");
        return this.state.getControllersAll$().pipe(
        // tap(() => this.state.setUpdating(true)),
        concatMap(function (ctrlrs) {
            if (!ctrlrs) {
                return _this.controllersService.getControllers(withInfo).pipe(tap(function (resp) {
                    if (resp.isValid()) {
                        var controllers = resp.controllers;
                        _this.state.setControllers(controllers);
                        _this.state.setControllersAll(controllers);
                    }
                }));
            }
            _this.logger.info("[ControllersFacadeService]", "Controllers Exist!", ctrlrs);
            var resp = new ControllersResponse({
                status: ResponseStatus.Success,
            });
            resp.controllers = ctrlrs;
            return of(resp);
        })
        // tap(() => this.state.setUpdating(false)),
        );
    };
    ControllersFacadeService.ngInjectableDef = i0.defineInjectable({ factory: function ControllersFacadeService_Factory() { return new ControllersFacadeService(i0.inject(i1.LoggerService), i0.inject(i2.ControllersStateService), i0.inject(i3.IControllerService)); }, token: ControllersFacadeService, providedIn: "root" });
    return ControllersFacadeService;
}());
export { ControllersFacadeService };
