/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { StrictHttpResponse as __StrictHttpResponse } from "../strict-http-response";
import { Observable as __Observable } from "rxjs";
import { map as __map, filter as __filter } from "rxjs/operators";
import { environment as env } from "../../../environments/environment";
import { Inline_response_200_25 } from "../models/inline-_response-_200_25";
@Injectable({
  providedIn: "root",
})
class StaticContentService extends __BaseService {
  static readonly StaticcontentByContentKeyGETPath =
    "/staticcontent/{content_key}";

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
    this.rootUrl = env.api.secondaryURL;
  }

  /**
   * Get list of values
   * @param content_key desired content
   * @return list of filter types
   */
  StaticcontentByContentKeyGETResponse(
    params: StaticContentService.StaticcontentByContentKeyGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_25>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        "/tenants/" +
        params.tenantId +
        "/staticcontent/" +
        params.contentKey,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_25>;
      })
    );
  }
  /**
   * Get list of values
   * @param content_key desired content
   * @return list of filter types
   */
  StaticcontentByContentKeyGET(
    params: StaticContentService.StaticcontentByContentKeyGETParams
  ): __Observable<Inline_response_200_25> {
    return this.StaticcontentByContentKeyGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_25)
    );
  }
}

module StaticContentService {
  export interface StaticcontentByContentKeyGETParams {
    contentKey:
      | "filtertypes"
      | "filtertypes/agriculture"
      | "filtertypes/industry"
      | "countries"
      | "jobs"
      | "languages"
      | "flushreasons"
      | "alarmtypes"
      | "filterationdegrees"
      | "watersources";
    /**
     * tenant Id
     */
    tenantId: string;
  }
}

export { StaticContentService };
