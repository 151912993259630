<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <a id="navbar-brand" class="navbar-brand" href="/dashboard">
      <div class="brand-logo">
        <img
          class="img-fluid"
          src="assets/img/components/topNavbar/headerLogoTopNav.svg"
          alt="App Logo"
        />
      </div>
      <div class="brand-logo-collapsed">
        <img
          class="img-fluid"
          src="assets/img/components/topNavbar/headerLogoTopNavSmall.svg"
          alt="App Logo"
        />
      </div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul id="custom-navbar-nav" class="navbar-nav mr-auto-custome flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block lang"
        trigger-resize=""
        id="Collaps"
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none lang"
        (click)="
          settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()
        "
      >
        <em class="fas fa-bars"></em>
      </a>
    </li>
    <!-- START User avatar toggle-->
    <!-- <li class="nav-item d-none d-md-block"> -->
    <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
    <!-- <a  class="nav-link" (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a> -->
    <!-- </li> -->
    <!-- END User avatar toggle-->
  </ul>
  <!-- END Left navbar-->
  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row">
    <!-- Search icon-->
    <!-- <li class="nav-item">
            <a class="nav-link" (click)="openNavSearch($event)">
                <em class="icon-magnifier"></em>
            </a>
        </li> -->
    <!-- Fullscreen (only desktops)-->
    <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li> -->

    <li *ngIf="isSystemAdmin && selectedTenant" class="nav-item">
      <div
        class="d-flex align-items-center justify-content-center h-100 text-white"
      >
        <span> Current Tenant: <strong>{{ selectedTenant.name }}</strong> </span>
        <span class="mx-2"> | </span>
      </div>
    </li>
    <li class="nav-item">
      <select
        class="form-control lang-dropdown lang"
        [ngModel]="selectedLanguage"
        (ngModelChange)="setLang($event)"
      >
        <option [value]="lang.code" *ngFor="let lang of getLangs()">
          {{ lang.text }}
        </option>
      </select>
    </li>

    <!-- START Offsidebar button-->
    <!-- <li class="nav-item">
            <a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">
                <em class="icon-notebook"></em>
            </a>
        </li> -->
    <!-- END Offsidebar menu-->
    <li class="nav-item">
      <a class="nav-link lang" (click)="logout()">
        <em class="icon-logout"></em>
      </a>
    </li>
  </ul>
  <!-- END Right Navbar-->

  <app-navsearch
    [visible]="getNavSearchVisible()"
    (onclose)="setNavSearchVisible(false)"
  ></app-navsearch>
</nav>
<!-- END Top Navbar-->
