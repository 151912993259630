/* tslint:disable */
type Result =
  'success';
module Result {
  export const SUCCESS: Result = 'success';
  export function values(): Result[] {
    return [
      SUCCESS
    ];
  }
}

export { Result }