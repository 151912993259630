/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./app.component";
import * as i5 from "./core/settings/settings.service";
import * as i6 from "./shared/services/page-heading.service";
import * as i7 from "./core/translator/translator.service";
import * as i8 from "@angular/common/http";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isIframe; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], [[2, "layout-fixed", null], [2, "aside-collapsed", null], [2, "layout-boxed", null], [2, "layout-fs", null], [2, "hidden-footer", null], [2, "layout-h", null], [2, "aside-float", null], [2, "offsidebar-open", null], [2, "aside-toggled", null], [2, "aside-collapsed-text", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i4.AppComponent, [i5.SettingsService, i6.PageHeadingService, i7.TranslatorService, i8.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isFixed; var currVal_1 = i1.ɵnov(_v, 1).isCollapsed; var currVal_2 = i1.ɵnov(_v, 1).isBoxed; var currVal_3 = i1.ɵnov(_v, 1).useFullLayout; var currVal_4 = i1.ɵnov(_v, 1).hiddenFooter; var currVal_5 = i1.ɵnov(_v, 1).horizontal; var currVal_6 = i1.ɵnov(_v, 1).isFloat; var currVal_7 = i1.ɵnov(_v, 1).offsidebarOpen; var currVal_8 = i1.ɵnov(_v, 1).asideToggled; var currVal_9 = i1.ɵnov(_v, 1).isCollapsedText; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
var AppComponentNgFactory = i1.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
